import React, { useEffect, useState, useRef, useLayoutEffect, Suspense } from 'react';
import Spline from '@splinetool/react-spline';

import Login from './login'
//import Navigation from './navigation'
import './App.css'

import tabelle from './Tabelle_Entstehung_Konsentierung.svg';
import logo_fimb from './Fimb_Logo_real.svg';
import Icon_Werteänderung from './Icon_Werteänderung.svg';

const finger1 = require('./Finger2.png');
const finger2 = require('./Versteifung_Finger.png');
const finger1_big = require('./Versteifung_Finger_Zeichnung_big.jpg');
const finger2_big = require('./Grafik__Versteifung_Finger_picswitch.webp');
const daumen1 = require('./Daumenversteifung.png');
const daumen2 = require('./Versteifung_Daumen_picswitch_neu.webp');
const daumen1_big = require('./Versteifung_Daumen_Zeichnung_big.jpg');
const daumen2_big = require('./Versteifung_Daumen_picswitch_neu.webp');
const hand_norm_img1 = require('./__Handgelenk_Funktionstörung_Normbewegl_1.webp');
const hand_norm_img2 = require('./__Handgelenk_Funktionstörung_Normbewegl_2.webp');
const hand_norm_img_mobil = require('./Winkelgrafik_Handzusammen.webp');
const elle_norm_img = require('./Winkelgrafik_Ellenbogen_weiß.png');
const schult_norm_img = require('./Winkelgrafik_Schulter_weiß.png');
const erklaerung_img = require('./neue-grafik.png');
const versteifung_fußgelenk_img = require('./versteifung-fußgelenk.png');
const GrafikNormbewegl_Hüfte_img = require('./Normbeweglichkeit_Hüfte_neu_GrafikNormbewegl_Hüfte.webp');
const GrafikNormbewegl_Sprunggelenk = require('./Normbeweglichkeit_Sprunggelenk_Sprunggelenk_Normbewegung.webp');
const funkst_bein_knieinst1 = require('./funkst_bein/KnieInst_Vorne_picswitch.webp');
const funkst_bein_knieinst2 = require('./funkst_bein/KnieInst_Hinten_picswitch.webp');
const funkst_bein_knieinst3 = require('./funkst_bein/KnieInst_Innen_picswitch.webp');
const funkst_bein_knieinst4 = require('./funkst_bein/KnieInst_Außen_picswitch.webp');

/* Versteifung Imgs*/
const Schulter_Frontal_verst = require('./verst_hand_arm/Schulter_Frontal_picswitch.webp');

const logo = require('./logo.png');

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
}

var go = false;

function App() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [email, setEmail] = useState('');

    const [width, height] = useWindowSize();

    const spline = useRef();

    var screen_small = window.matchMedia("(max-width: 1660px)");

    var hand_bones_1 = ['4ba4c979-f1e4-4ca2-997e-609695ffdcff', '7dfd43d7-72ba-4911-baec-63bb92ee9be3', '793d98e5-429d-48a7-aa4e-a577db5c54be', '594dd964-fd7f-4324-b439-3bba8244b5df'];
    var hand_bones_2 = ['b817058a-e13c-444f-8722-eaa1e810729a', 'd38d69d2-d893-4ca4-b735-57aa616a9d3e', '09f53062-bb05-49a1-a57e-3a1ac4b2cec7', '7f4fdb54-f59d-4d91-b932-b8f8efc0301e'];
    var hand_bones_3 = ['daf588f2-6704-4419-af6f-a31c0f5fc682', '4543a740-bd0b-48d8-be7c-fec78e15d7d8', '5e9f1bae-73ac-4062-93f4-66a500717347', '7c2624e3-288d-4ab0-8ea9-ecf4627763f5'];

    var versteifungs_ids = ['5b6fc9ed-a859-4687-831b-cdcb76fdd7f7', 'bc59bfbb-1cd4-4dce-b392-3b0c1d5da34c', '2b952eb0-dc21-47d3-9852-760f50c2d6f4'];
    var funktions_ids = ['131a0085-f4d6-48a5-98ca-9a6c36c8e1d2', '4ed0b330-9975-4cc0-beee-6baf2ef9c3f8', 'a7704ea1-721f-4a7b-a6a0-2f89c3bf3c13'];
    var uebersicht_ids = ['174c3d41-8638-46ac-8fe3-d62a76dfe83d', 'bc063765-110e-498e-8543-76090447fe05', 'bddc9a69-ffec-45a8-8c7d-25274ba5d32a', 'e067628d-e2d4-4b92-91f3-02d6b281329d', '995ad621-bb59-4354-bb47-3cc85f521aac', 'ba5b4e5b-aa1f-426c-9550-213d83789413', '341089c4-dcc6-4c2a-a8f4-206d452de7e4', 'f8fdbff1-a874-47ef-aee9-cea9fcb2af38', 'a99ebc46-26a5-4ca0-a32b-bc1782a8b3f6', '85966f2f-282d-47d2-a502-bc8fff558a5d', '7d2e7b71-dd0a-4941-9add-5e9d0b6bd059', '80a5285c-98a5-44e0-874d-0a3f0578bfdc', '500b489d-332b-453c-a0bb-8a11b69d78f6', '7f396309-f8d2-4224-ae52-4d5a7330b947', '01bf34a6-e59c-49ef-a822-257dd7a871f9', '4c4cf6be-02bd-4973-b56e-b497bb21ee39', 'deb4ea88-8e73-4dd2-8882-b27e775e2e90', '33d7e16b-6d09-46fa-9bb4-fb9ffdae731b'];
    var invatar_ids = ['d16dd982-afc1-4962-8a11-c8655feec428', 'e59c877f-5232-4453-a40c-ce3e7b8e6250', '85a91acf-9468-4baf-8673-a3a3a73e655f', 'aa6b609e-aa3b-42d6-9782-86648c60a0f4', 'a659aeb7-21fd-418b-9e76-ab33e15d1248', '2f5399b6-a927-4171-b80f-eb3b12fc6543', '110a3581-8bf8-4a20-8b48-a2849b8d7569', '7fcd812e-f3f8-406f-899f-59a4a94aeb9e', 'dfaed98e-cefd-449f-aab2-6964ec52dc8d', 'bfddc80d-2168-4940-a09e-3b7d6f7c3b0a', '5a4b60ff-d692-44f4-b756-d7107677165f', 'd16dd982-afc1-4962-8a11-c8655feec428', 'f3b95c9e-6146-42a6-9405-bef23d429f4b', '5b360e47-d4a4-4b2f-b5c5-af93d1d51555', 'aeb25376-e7d4-48ac-be19-1e2514644583', '32bb13b0-e8d9-4dca-b14f-2de30da912ae', 'b32e1299-2f4a-4cd1-a96c-1d3cfb96b893', 'cf89ce55-0de2-4b30-ba05-e198e812d1dc', 'f089b6eb-1e3d-48cf-b22c-fc1d44245df0', '28010de6-5c34-446b-a6a2-4afec09472f5'];

    var arm_ids = ['2405c351-e63b-44e6-9e08-3df88564ec5c', '2a1ddd21-ac4c-415c-8ee5-949a725c80ff', '555eeba7-062a-4567-8e4b-c36798714ccc', '22bdcd43-4f63-46c5-891b-004285c7cc35', 'e62c4f2e-3fef-4f8f-8d3b-2226c922a3cd', '5eb8dc88-c1f2-4adf-a81c-6e039e9dab13', '3c6b6d39-b605-49ab-a900-3da705738b8e', 'b932d0e8-7589-4936-822d-411f0f3a312e', '74de5b1f-4383-473e-8297-6ac012d225f7', '4c3aaafd-2c11-4dea-91ac-8d5fea750e40'];
    var hand_ids = ['4f57dc90-71f9-4190-a0c5-6bf05aa26b4d', '980e2e8e-46a4-4d95-ac95-05cd39979cc5', '2b1992d6-2eee-48ab-b390-3c6468f6c2fb', '18757df4-2b56-44d3-9423-7bcd55de7634', '04b2584d-8466-4db3-89cd-933a4b885eac', '8c32714c-7077-4457-95dc-f631794dc1db', '464db81c-fbee-4393-85d3-836c6a4f944a', 'e7e2e1d6-6b70-4710-83d9-9d07494a6e32'];

    var athrose_ids = ['bee5b19b-c469-44a5-a72d-63eae5a0913c', '2bab3741-4676-4f96-a370-bbc5ce660734', 'a64124b7-6bd6-44d3-8cd4-e422bd7c97ae'];
    var prothese_ids = ['e14e183c-67cd-42c2-bfdc-297e9ac0b3f1', '21004c51-067e-48a7-b1d2-b08faa10fa7d', 'b34a3cc5-0425-4e06-84d8-a9c1d1873c2f'];

    var wirbelsaeule_ids = ['a145901c-da9e-4fa0-b6fc-8387c6367044', '7d27431e-c4b7-4213-a4b5-c29f4fcd602b', 'f1207c00-87e1-41ba-a729-fc4e6eeec9c3', '3fd28953-f323-41d0-8829-1167846b6b75', '44267008-fe96-46d7-bbc7-7c029d6f3f56', 'd2296eea-bb01-42d0-8c44-365c1b231b01'];
    var bein_ids = ['2159a54b-6fb4-49fa-9b69-e4836b72cdf1', '0f59f87f-07e7-47e8-9818-fedb6e1749aa', 'b3bf9d3e-464d-42bc-a588-8191dbf48343', '0461c35b-9af7-4cb9-8e19-9f25281c3988', '20c27cad-5d8b-474e-8881-cb67f8ba5955', '8c560a4e-8d4f-444d-b02b-b1fbea605cc3', 'a1a7554f-927a-4afd-b776-e7c90539bbb7', 'faef8d04-93aa-427a-acd7-a2e88ceccfd4', 'e87d5f62-59c7-4962-971a-a32cc6a5e1ad', 'a046332d-9b5f-476b-b9a8-c51abc98042f', '2207563d-97dd-46cd-8c61-f336e988568e'];
    var fuß_ids = ['d11e8acd-bbfc-4bcf-87f9-2d81ee27d3b9', '0cde4d63-91b4-49d5-9792-90fa2e9c0038', '3226e8b2-fc40-4a00-b7a1-c12986a6a36a', '57a691ba-edfa-48f8-9664-2b490135bd31', '5f8f3644-b62a-41d2-8ad4-96f33921a4bc', 'b4b8f359-edb2-47d9-abd5-07159f11f303', '9ccbed35-d7dd-4e42-bf7f-f3b7c102c694'];
    var brustkorb_ids = ['62f1bae1-222e-447f-8b95-3f5b25878db6', 'f350ca77-5615-46ff-b433-9632a50ce39e', 'dd06d456-0d1f-43b3-95c6-d3ce7d2e0443', '1c267f84-06d9-4719-bf35-2e02b8f54ec7', '74a10933-ee30-4b02-829b-38f0a8d1aa26'];
    var becken_ids = ['0ba9956b-b6b9-4206-809d-2fed6aa2b0de', '44055c0e-7bce-45ae-a85a-36b4aad0e84f', 'c36dfe24-6d95-4055-b470-c80b703708e6', 'd03c0cbc-0fef-4513-ad88-b099c1e7306a', 'b46355d1-dc1c-4c29-8008-f498dd00e4de', '1316074b-b3cf-4f7a-9c96-875f6e3ee412'];
    var bauchdecke_ids = ['421eca12-6996-45f9-ad05-7c1d69b85cd1', 'c9935a20-ba88-45a0-8323-f701b0430879', '91ad2125-f0b0-4bbb-903b-e156f186c395', 'dc1e329d-8ce2-41b1-9633-71e763b791e4', 'e65d920e-fea4-4261-8da0-0b09de944907', 'c9935a20-ba88-45a0-8323-f701b0430879'];
    var integument_ids = ['af2d1fd1-78a0-4c46-b371-542d90684c23', 'a8d0e188-9d27-4298-9594-295b41f9f762', '597411db-0770-421a-aa18-d2942151da1e', '98734752-8721-4e4f-91b9-79f46b60538d', '170b1543-ce39-4a47-a7a5-7ab032853b6e', 'dab8095f-d4ac-468b-96c4-88df6ab01561'];

    var laengen_achsen_ids = ['925db792-bb44-4ac0-a684-fa21e523f012', '1654543e-e546-4ec2-be7d-82031da1c24f', '', '', '708ef84f-d193-4dd4-b703-38605b3b8419', '2c1c5ce6-8fb5-4170-81cf-b637dce7dfa9', '0a1d9440-1d2d-4e45-9fc7-b4f54d2b2d16', '4e8d3aff-39bc-40b8-be83-c8f805222742'];
    var thrombose_ids = ['9963da67-4a32-4829-8364-ee0f9e5e1669', 'adf3296c-6527-4e9c-aa63-b5651bc23ef7', '', '', '3939a759-7f73-4341-b3e9-e9c199a872cd', '4c7dba95-c188-44e6-969b-77f87f934dde', 'fa8db11e-afa5-44df-8529-4623888d6438'];

    var funktions_bein_ids = ['339b2213-b5a0-4354-834b-0cc5710a07a8', 'aea8252e-70e8-4407-84eb-a4eb50d07bc4', '02a306d3-c50b-4678-94e0-f8e131fa9480', 'e7ccc904-d473-40e4-b69b-173bba8db102', '1feab3d4-a57d-445e-a7a5-3b7947ccc904'];
    var versteifung_bein_ids = ['79b19441-190c-461b-8f4f-b5b2017b2829', '8c11fdc0-160d-4793-b634-6d68d5313f11', '35da6516-55a8-4f4d-8d6d-da1a3f147048', 'bc9bbdfd-fb8c-4414-9ad5-1404f200e769', 'c302cf84-25ff-4f8b-bac7-31d2375dbbc5'];

    //var versteifungs_ids = ['', '', '', '', '', '', ''];

    function startTrigger(e) {
        go = true;
        document.querySelector('.start-button').classList.add('active');
    }

    function onLoad(splineApp) {
        spline.current = splineApp;
        go = true;
        document.querySelector('.start-button').classList.add('active');
    }

    const startScene = (event) => {
        let value = event.target.getAttribute('value');
        if (go) {
            if (value === 'start') {
                document.querySelector('#overlay-info-loading').style.display = 'none';
                spline.current.emitEvent('mouseDown', '78fd244f-d93d-4803-8126-e66d7f9714ae');
            }
        }
    };

    function onMouseDown(e) {
        var szenen = document.querySelectorAll('.szene');
        var armwert = document.getElementById('armwert');
        var armwert_bez = document.getElementById('armwert-bez');
        var armwert_anzeige = document.getElementById('wert-container');
        //var //site_number = document.getElementById('site-number');

        var sub_menu_buttons = document.querySelectorAll('.nav-container .nav .sub-menu ul li');
        document.getElementById('verlustwertChange').innerText = '';

        if (e.target.id !== 'e9e2ea3f-50b8-4ba5-b465-cb60cc1208a3' && e.target.id !== '0fb76044-fd4e-4005-b936-0f95c3751a71') {
           document.querySelectorAll('.button-table').forEach((element) => {
                element.style.display = 'none'
            });
        }


        document.querySelector('#overlay-img-verst-container').style.display = 'none';

        //sub_menu_buttons.forEach((element) => element.classList.remove('active'));

        if (document.querySelectorAll('.szene-funktionsstoerung .side-menu>ul>li') !== null) {
            var all_buttons_side_menu = document.querySelectorAll('.side-menu>ul>li');
        }

        if (athrose_ids.includes(e.target.id)) {
            szenen.forEach(elem => {
                if (elem.classList.contains('szene-athrose')) {
                    elem.style.display = 'block';
                } else {
                    elem.style.display = 'none'
                }
            });
            all_buttons_side_menu.forEach((element) => {
                element.style.display = 'none'
            });
            //site_number.innerText = '3';
        } else if (prothese_ids.includes(e.target.id)) {
            szenen.forEach(elem => {
                if (elem.classList.contains('szene-prothese')) {
                    elem.style.display = 'block';
                } else {
                    elem.style.display = 'none'
                }
            });
            all_buttons_side_menu.forEach((element) => {
                element.style.display = 'none'
            });
            //site_number.innerText = '4';
        } else if (invatar_ids.includes(e.target.id)) {
            szenen.forEach(elem => {
                if (elem.classList.contains('szene-invatar')) {
                    elem.style.display = 'block';
                } else {
                    elem.style.display = 'none'
                }
            });
            if (document.getElementById('hint-hand-funk')) {
                document.getElementById('hint-hand-funk').style.display = 'none';
            }
            all_buttons_side_menu.forEach((element) => {
                element.style.display = 'none'
            });
            document.querySelectorAll('.nav .sub-menu ul li').forEach(elem => { elem.classList.remove('active') });
            document.querySelector('#overlay').style.display = 'none';
            document.getElementById('show-finger').style.display = 'none';
            document.getElementById('show-daumen').style.display = 'none';
            document.querySelector('.overview').classList.remove('mobile-small-size');
            document.querySelector('.side-menu').classList.remove('mobile-small-hight');
            document.querySelector('#overlay-img-verst-container').style.display = 'none';
           document.querySelectorAll('.button-table').forEach((element) => {
                element.style.display = 'none'
            });
            //site_number.innerText = '2';
        } else if (arm_ids.includes(e.target.id)) {
            szenen.forEach(elem => {
                if (elem.classList.contains('szene-arm')) {
                    elem.style.display = 'block';
                } else {
                    elem.style.display = 'none'
                }
            });
            if (document.getElementById('hint-hand-funk')) {
                document.getElementById('hint-hand-funk').style.display = 'none';
            }
            all_buttons_side_menu.forEach((element) => {
                element.style.display = 'none'
            });
            document.querySelectorAll('.nav .sub-menu ul li').forEach(elem => { elem.classList.remove('active') });
            document.querySelector('#overlay').style.display = 'none';
            document.getElementById('show-finger').style.display = 'none';
            document.getElementById('show-daumen').style.display = 'none';
            document.querySelector('#overlay-img-verst-container').style.display = 'none';
            document.querySelector('.overview').classList.remove('mobile-small-size');
            //site_number.innerText = '5';
        } else if (hand_ids.includes(e.target.id)) {
            szenen.forEach(elem => {
                if (elem.classList.contains('szene-hand')) {
                    elem.style.display = 'block';
                } else {
                    elem.style.display = 'none'
                }
            });
            if (document.getElementById('hint-hand-funk')) {
                document.getElementById('hint-hand-funk').style.display = 'none';
            }
            all_buttons_side_menu.forEach((element) => {
                element.style.display = 'none'
            });
            document.querySelectorAll('.nav .sub-menu ul li').forEach(elem => { elem.classList.remove('active') });
            document.querySelector('#overlay').style.display = 'none';
            document.querySelector('#overlay-img-verst-container').style.display = 'none';
            //site_number.innerText = '6';
        } else if (bein_ids.includes(e.target.id)) {
            szenen.forEach(elem => {
                if (elem.classList.contains('szene-bein')) {
                    elem.style.display = 'block';
                } else {
                    elem.style.display = 'none'
                }
            });
            all_buttons_side_menu.forEach((element) => {
                element.style.display = 'none'
            });
            document.querySelectorAll('.nav .sub-menu ul li').forEach(elem => { elem.classList.remove('active') });
            document.querySelector('.side-menu').classList.remove('mobile-small-hight');
            document.querySelector('.overview').classList.remove('mobile-small-size');
            document.querySelector('#overlay-img-verst-container').style.display = 'none';
           document.querySelectorAll('.button-table').forEach((element) => {
                element.style.display = 'none'
            });
            //site_number.innerText = '7';
        } else if (fuß_ids.includes(e.target.id)) {
            szenen.forEach(elem => {
                if (elem.classList.contains('szene-fuß')) {
                    elem.style.display = 'block';
                } else {
                    elem.style.display = 'none'
                }
            });
            all_buttons_side_menu.forEach((element) => {
                element.style.display = 'none'
            });
            document.querySelectorAll('.nav .sub-menu ul li').forEach(elem => { elem.classList.remove('active') });
            document.querySelector('.side-menu').classList.remove('mobile-small-hight');
            document.querySelector('.overview').classList.remove('mobile-small-size');
            document.querySelector('#overlay-img-verst-container').style.display = 'none';
           document.querySelectorAll('.button-table').forEach((element) => {
                element.style.display = 'none'
            });
            //site_number.innerText = '8';
        } else if (versteifungs_ids.includes(e.target.id)) {
            szenen.forEach(elem => {
                if (elem.classList.contains('szene-versteifung')) {
                    elem.style.display = 'block';
                } else {
                    elem.style.display = 'none'
                }
            });
            if (document.getElementById('hint-hand-funk')) {
                document.getElementById('hint-hand-funk').style.display = 'none';
            }
            all_buttons_side_menu.forEach((element) => {
                element.style.display = 'none'
            });
            document.querySelectorAll('.nav .sub-menu ul li').forEach(elem => { elem.classList.remove('active') });
            document.getElementById('change-head').innerText = ' Versteifungen';
            document.querySelector('.change-text-head').innerText = 'Arm und Hand';
            document.querySelector('#overlay').style.display = 'none';
            document.getElementById('wert-container').style.display = 'none';
            document.querySelector('.overview').classList.remove('mobile-small-size');
            //site_number.innerText = '14';
        } else if (funktions_ids.includes(e.target.id)) {
            szenen.forEach(elem => {
                if (elem.classList.contains('szene-funktionsstoerung')) {
                    elem.style.display = 'block';
                } else {
                    elem.style.display = 'none'
                }
            });
            if (document.getElementById('hint-hand-funk')) {
                document.getElementById('hint-hand-funk').style.display = 'none';
            }
            all_buttons_side_menu.forEach((element) => {
                element.style.display = 'none'
            });
            document.querySelectorAll('.nav .sub-menu ul li').forEach(elem => { elem.classList.remove('active') });
            document.getElementById('change-head').innerHTML = ' Funktions&shy;beeinträchtigungen';
            document.querySelector('.change-text-head').innerText = 'Arm und Hand';
            document.querySelector('#overlay').style.display = 'none';
            document.getElementById('show-finger').style.display = 'none';
            document.getElementById('show-daumen').style.display = 'none';
            document.getElementById('wert-container').style.display = 'none';
            document.querySelector('#overlay-img-verst-container').style.display = 'none';
            //site_number.innerText = '15';
        } else if (uebersicht_ids.includes(e.target.id)) {
            szenen.forEach(elem => {
                if (elem.classList.contains('szene-start')) {
                    elem.style.display = 'block';
                } else {
                    elem.style.display = 'none'
                }
            });
            if (document.getElementById('hint-hand-funk')) {
                document.getElementById('hint-hand-funk').style.display = 'none';
            }
            all_buttons_side_menu.forEach((element) => {
                element.style.display = 'none'
            });
            document.querySelectorAll('.nav .sub-menu ul li').forEach(elem => { elem.classList.remove('active') });
            document.querySelector('#overlay').style.display = 'none';
            document.getElementById('show-finger').style.display = 'none';
            document.getElementById('show-daumen').style.display = 'none';
            document.querySelector('.side-menu').classList.remove('mobile-small-hight');
            document.querySelector('.overview').classList.remove('mobile-small-size');
            document.getElementById('start-button').click();
            //site_number.innerText = '1';
            document.querySelector('#overlay-img-verst-container').style.display = 'none';
           document.querySelectorAll('.button-table').forEach((element) => {
                element.style.display = 'none'
            });
        } else if (wirbelsaeule_ids.includes(e.target.id)) {
            szenen.forEach(elem => {
                if (elem.classList.contains('szene-wirbelsaeule')) {
                    elem.style.display = 'block';
                } else {
                    elem.style.display = 'none'
                }
            });
            all_buttons_side_menu.forEach((element) => {
                element.style.display = 'none'
            });
            document.querySelectorAll('.nav .sub-menu ul li').forEach(elem => { elem.classList.remove('active') });
            //site_number.innerText = '10';
        } else if (brustkorb_ids.includes(e.target.id)) {
            szenen.forEach(elem => {
                if (elem.classList.contains('szene-brustkorb')) {
                    elem.style.display = 'block';
                } else {
                    elem.style.display = 'none'
                }
            });
            all_buttons_side_menu.forEach((element) => {
                element.style.display = 'none'
            });
            document.querySelectorAll('.nav .sub-menu ul li').forEach(elem => { elem.classList.remove('active') });
            //site_number.innerText = '9';
        } else if (becken_ids.includes(e.target.id)) {
            szenen.forEach(elem => {
                if (elem.classList.contains('szene-becken')) {
                    elem.style.display = 'block';
                } else {
                    elem.style.display = 'none'
                }
            });
            all_buttons_side_menu.forEach((element) => {
                element.style.display = 'none'
            });
            document.querySelectorAll('.nav .sub-menu ul li').forEach(elem => { elem.classList.remove('active') });
            //site_number.innerText = '11';
        } else if (bauchdecke_ids.includes(e.target.id)) {
            szenen.forEach(elem => {
                if (elem.classList.contains('szene-bauchdecke')) {
                    elem.style.display = 'block';
                } else {
                    elem.style.display = 'none'
                }
            });
            all_buttons_side_menu.forEach((element) => {
                element.style.display = 'none'
            });
            document.querySelectorAll('.nav .sub-menu ul li').forEach(elem => { elem.classList.remove('active') });
            //site_number.innerText = '12';
        } else if (integument_ids.includes(e.target.id)) {
            szenen.forEach(elem => {
                if (elem.classList.contains('szene-integument')) {
                    elem.style.display = 'block';
                } else {
                    elem.style.display = 'none'
                }
            });
            all_buttons_side_menu.forEach((element) => {
                element.style.display = 'none'
            });
            document.querySelectorAll('.nav .sub-menu ul li').forEach(elem => { elem.classList.remove('active') });
            //site_number.innerText = '13';
        } else if (laengen_achsen_ids.includes(e.target.id)) {
            szenen.forEach(elem => {
                if (elem.classList.contains('szene-laengen-achsen')) {
                    elem.style.display = 'block';
                } else {
                    elem.style.display = 'none'
                }
            });
            all_buttons_side_menu.forEach((element) => {
                element.style.display = 'none'
            });
            document.querySelectorAll('.nav .sub-menu ul li').forEach(elem => { elem.classList.remove('active') });
            document.querySelector('.side-menu').classList.remove('mobile-small-hight');
            document.querySelector('.overview').classList.remove('mobile-small-size');
            //site_number.innerText = '18';
        } else if (thrombose_ids.includes(e.target.id)) {
            szenen.forEach(elem => {
                if (elem.classList.contains('szene-thrombose')) {
                    elem.style.display = 'block';
                } else {
                    elem.style.display = 'none'
                }
            });
            all_buttons_side_menu.forEach((element) => {
                element.style.display = 'none'
            });
            document.querySelectorAll('.nav .sub-menu ul li').forEach(elem => { elem.classList.remove('active') });
            document.querySelector('.side-menu').classList.remove('mobile-small-hight');
            document.querySelector('.overview').classList.remove('mobile-small-size');
            //site_number.innerText = '19';
        } else if (versteifung_bein_ids.includes(e.target.id)) {
            szenen.forEach(elem => {
                if (elem.classList.contains('szene-versteifung-bein')) {
                    elem.style.display = 'block';
                } else {
                    elem.style.display = 'none'
                }
            });
            all_buttons_side_menu.forEach((element) => {
                element.style.display = 'none'
            });
            document.querySelectorAll('.nav .sub-menu ul li').forEach(elem => { elem.classList.remove('active') });
            document.getElementById('change-head').innerText = ' Versteifungen';
            document.querySelector('.change-text-head').innerText = 'Bein und Fuß';
            document.getElementById('wert-container').style.display = 'none';
            document.querySelector('.side-menu').classList.add('mobile-small-hight');
           document.querySelectorAll('.button-table').forEach((element) => {
                element.style.display = 'none'
            });
            //site_number.innerText = '16';
        } else if (funktions_bein_ids.includes(e.target.id)) {
            szenen.forEach(elem => {
                if (elem.classList.contains('szene-funktionsstoerung-bein')) {
                    elem.style.display = 'block';
                } else {
                    elem.style.display = 'none'
                }
            });
            all_buttons_side_menu.forEach((element) => {
                element.style.display = 'none'
            });
            document.querySelectorAll('.nav .sub-menu ul li').forEach(elem => { elem.classList.remove('active') });
            document.getElementById('change-head').innerHTML = ' Funktions&shy;beeinträchtigungen';
            document.querySelector('.change-text-head').innerText = 'Bein und Fuß';
            document.getElementById('wert-container').style.display = 'none';
            document.querySelector('.side-menu').classList.add('mobile-small-hight');
            document.querySelector('.overview').classList.remove('mobile-small-size');
            document.querySelector('#overlay-img-verst-container').style.display = 'none';
            document.querySelectorAll('.button-table').forEach((element) => {
                element.style.display = 'none'
            });
            //site_number.innerText = '17';
        } else if (e.target.id === '416a01f7-4cbb-4dcd-bda6-3b4430b21291') {
            var show = document.querySelector('.side-menu>ul>li:nth-of-type(1)');
            document.getElementById('verst-1').classList.add('active');
            document.getElementById('verst-2').classList.remove('active');
            document.getElementById('verst-3').classList.remove('active');
            document.getElementById('verst-4').classList.remove('active');
            document.getElementById('verst-5').classList.remove('active');
            all_buttons_side_menu.forEach((element) => {
                if (element === show) {
                    element.style.display = 'block'
                } else {
                    element.style.display = 'none'
                }
            });
            document.getElementById('change-head').innerText = ' Fingergelenke';
            document.querySelector('#overlay-img-verst-container').style.display = 'block';
            document.querySelector('#img-overlay-verst img').src = require('./verst_hand_arm/Fingerversteifung_picswitch.webp');
            var active_buttons = document.querySelectorAll('.side-menu > ul > li:nth-of-type(1) > ul li');
            active_buttons.forEach((element) => element.classList.remove('active'))
            document.querySelector('.side-menu > ul > li:nth-of-type(1) > ul li:first-of-type').classList.add('active');
        } else if (e.target.id === '7c3e50d9-686f-4104-9628-8ff63c833d6c') {
            var show = document.querySelector('.side-menu>ul>li:nth-of-type(2)');
            document.getElementById('verst-2').classList.add('active');
            document.getElementById('verst-1').classList.remove('active');
            document.getElementById('verst-3').classList.remove('active');
            document.getElementById('verst-4').classList.remove('active');
            document.getElementById('verst-5').classList.remove('active');
            all_buttons_side_menu.forEach((element) => {
                if (element === show) {
                    element.style.display = 'block'
                } else {
                    element.style.display = 'none'
                }
            });
            document.getElementById('change-head').innerText = ' Handgelenk';
            armwert_anzeige.style.display = 'block';
            armwert_bez.innerText = 'Handwert';
            armwert.innerText = '5/20';
            document.querySelector('#overlay-img-verst-container').style.display = 'block';
            document.querySelector('#img-overlay-verst img').src = require('./verst_hand_arm/Handgelenk_Seitkantung_picswitch.webp');
            var active_buttons = document.querySelectorAll('.side-menu > ul > li:nth-of-type(2) > ul li');
            active_buttons.forEach((element) => element.classList.remove('active'))
            document.querySelector('.side-menu > ul > li:nth-of-type(2) > ul li:first-of-type').classList.add('active');
        } else if (e.target.id === 'ee0c534c-8502-407e-88ac-9c1677d49053') {
            var show = document.querySelector('.side-menu>ul>li:nth-of-type(4)');
            document.getElementById('verst-3').classList.add('active');
            document.getElementById('verst-2').classList.remove('active');
            document.getElementById('verst-1').classList.remove('active');
            document.getElementById('verst-4').classList.remove('active');
            document.getElementById('verst-5').classList.remove('active');
            all_buttons_side_menu.forEach((element) => {
                if (element === show) {
                    element.style.display = 'block'
                } else {
                    element.style.display = 'none'
                }
            });
            document.getElementById('change-head').innerText = ' Ellenbogengelenk';
            //document.getElementById('verlustwertChange').innerText = '² Aktualisierung der Werte, siehe Hinweise und Updates';
            armwert_anzeige.style.display = 'block';
            armwert_bez.innerText = 'Armwert';
            armwert.innerText = '6/20';
            document.querySelector('#overlay-img-verst-container').style.display = 'block';
            document.querySelector('#img-overlay-verst img').src = require('./verst_hand_arm/Ellenbogen_UNteramdrehungfrei_NEU_picswitch.webp');
            var active_buttons = document.querySelectorAll('.side-menu > ul > li:nth-of-type(4) > ul li');
            active_buttons.forEach((element) => element.classList.remove('active'))
            document.querySelector('.side-menu > ul > li:nth-of-type(4) > ul li:first-of-type').classList.add('active');
        } else if (e.target.id === '8a4bb91c-29ab-45e1-b4bf-3747528a018f') {
            var show = document.querySelector('.side-menu>ul>li:nth-of-type(3)');
            document.getElementById('verst-4').classList.add('active');
            document.getElementById('verst-2').classList.remove('active');
            document.getElementById('verst-3').classList.remove('active');
            document.getElementById('verst-1').classList.remove('active');
            document.getElementById('verst-5').classList.remove('active');
            all_buttons_side_menu.forEach((element) => {
                if (element === show) {
                    element.style.display = 'block'
                } else {
                    element.style.display = 'none'
                }
            });
            document.getElementById('change-head').innerText = ' Schultergelenk';
            armwert_anzeige.style.display = 'block';
            armwert_bez.innerText = 'Armwert';
            armwert.innerText = '8/20';
            document.querySelector('#overlay-img-verst-container').style.display = 'block';
            document.querySelector('#img-overlay-verst img').src = Schulter_Frontal_verst;
            var active_buttons = document.querySelectorAll('.side-menu > ul > li:nth-of-type(3) > ul li');
            active_buttons.forEach((element) => element.classList.remove('active'))
            document.querySelector('.side-menu > ul > li:nth-of-type(3) > ul li:first-of-type').classList.add('active');
        } else if (e.target.id === 'c1dccd1b-9786-4026-99c8-1b51f12f878f') {
            var show = document.querySelector('.side-menu>ul>li:nth-of-type(18)');
            document.getElementById('verst-5').classList.add('active');
            document.getElementById('verst-2').classList.remove('active');
            document.getElementById('verst-3').classList.remove('active');
            document.getElementById('verst-1').classList.remove('active');
            document.getElementById('verst-4').classList.remove('active');
            all_buttons_side_menu.forEach((element) => {
                if (element === show) {
                    element.style.display = 'block'
                } else {
                    element.style.display = 'none'
                }
            });
            document.getElementById('change-head').innerText = ' Unterarmdrehung';
            armwert_anzeige.style.display = 'block';
            armwert_bez.innerText = 'Handwert';
            armwert.innerText = '7/20';
            document.querySelector('#overlay-img-verst-container').style.display = 'block';
            document.querySelector('#img-overlay-verst img').src = require('./verst_hand_arm/Handgelenk_AufhebunginSupination.png');
            var active_buttons = document.querySelectorAll('.side-menu > ul > li:nth-of-type(18) > ul li');
            active_buttons.forEach((element) => element.classList.remove('active'))
            document.querySelector('.side-menu > ul > li:nth-of-type(18) > ul li:first-of-type').classList.add('active');
        } else if (e.target.id === '988881cd-6a1d-453c-b3e4-9ccb06e00ef4') {
            //Versteifung Bein - Zehengelenk
            var show = document.querySelector('.side-menu>ul>li:nth-of-type(13)');
            document.querySelector('.side-menu>ul>li:nth-of-type(13) ul li:first-of-type').classList.add('active');
            document.getElementById('verst-1b').classList.add('active');
            document.getElementById('verst-2b').classList.remove('active');
            document.getElementById('verst-3b').classList.remove('active');
            document.getElementById('verst-4b').classList.remove('active');
            all_buttons_side_menu.forEach((element) => {
                if (element === show) {
                    element.style.display = 'block'
                } else {
                    element.style.display = 'none'
                }
            });
            //document.getElementById('change-head').innerText = 'Fingergelenke';
            document.querySelector('#overlay').style.display = 'none';
            document.querySelector('#overlay-img-verst-container').style.display = 'block';
            document.querySelector('#img-overlay-verst img').src = require('./verst_bein_fuß/Suffizienter_bodenkontakt.webp');
            var active_buttons = document.querySelectorAll('.side-menu > ul > li:nth-of-type(13) > ul li');
            active_buttons.forEach((element) => element.classList.remove('active'))
            document.querySelector('.side-menu > ul > li:nth-of-type(13) > ul li:first-of-type').classList.add('active');
        } else if (e.target.id === '0ee5e110-3b98-4347-8ae2-26329de0623b') {
            //Versteifung Bein - Fußgelenk
            var show = document.querySelector('.side-menu>ul>li:nth-of-type(12)');
            document.querySelector('.side-menu>ul>li:nth-of-type(12) ul li:nth-of-type(2)').classList.add('active');
            document.getElementById('verst-2b').classList.add('active');
            document.getElementById('verst-1b').classList.remove('active');
            document.getElementById('verst-3b').classList.remove('active');
            document.getElementById('verst-4b').classList.remove('active');
            all_buttons_side_menu.forEach((element) => {
                if (element === show) {
                    element.style.display = 'block'
                } else {
                    element.style.display = 'none'
                }
            });
            //document.getElementById('change-head').innerText = 'Handgelenk';
            document.querySelector('#overlay').style.display = 'none';
            document.querySelector('#overlay-img-verst-container').style.display = 'block';
            document.querySelector('#img-overlay-verst img').src = require('./verst_bein_fuß/OberesSprunggelenk_picswitch.webp');
            var active_buttons = document.querySelectorAll('.side-menu > ul > li:nth-of-type(12) > ul li');
            active_buttons.forEach((element) => element.classList.remove('active'))
            document.querySelector('.side-menu > ul > li:nth-of-type(12) > ul li:nth-of-type(2)').classList.add('active');
        } else if (e.target.id === '4c43c141-3dd5-4ae4-9aeb-7248ee9c95db') {
            //Versteifung Bein - Kniegelenk
            var show = document.querySelector('.side-menu>ul>li:nth-of-type(11)');
            document.querySelector('.side-menu>ul>li:nth-of-type(11) ul li:first-of-type').classList.add('active');
            document.getElementById('verst-3b').classList.add('active');
            document.getElementById('verst-2b').classList.remove('active');
            document.getElementById('verst-1b').classList.remove('active');
            document.getElementById('verst-4b').classList.remove('active');
            all_buttons_side_menu.forEach((element) => {
                if (element === show) {
                    element.style.display = 'block'
                } else {
                    element.style.display = 'none'
                }
            });
            //document.getElementById('change-head').innerText = 'Ellenbogengelenk';
            document.querySelector('#overlay').style.display = 'none';
            document.querySelector('#overlay-img-verst-container').style.display = 'block';
            document.querySelector('#img-overlay-verst img').src = require('./verst_bein_fuß/Kniesaggital_picswitch.webp');
            var active_buttons = document.querySelectorAll('.side-menu > ul > li:nth-of-type(11) > ul li');
            active_buttons.forEach((element) => element.classList.remove('active'))
            document.querySelector('.side-menu > ul > li:nth-of-type(11) > ul li:first-of-type').classList.add('active');
        } else if (e.target.id === '9c0c6bf8-e299-4036-92ee-dfe77c5d586f') {
            //Versteifung Bein - Hüftgelenk
            var show = document.querySelector('.side-menu>ul>li:nth-of-type(10)');
            document.querySelector('.side-menu>ul>li:nth-of-type(10) ul li:first-of-type').classList.add('active')
            document.getElementById('verst-4b').classList.add('active');
            document.getElementById('verst-2b').classList.remove('active');
            document.getElementById('verst-3b').classList.remove('active');
            document.getElementById('verst-1b').classList.remove('active');
            all_buttons_side_menu.forEach((element) => {
                if (element === show) {
                    element.style.display = 'block'
                } else {
                    element.style.display = 'none'
                }
            });
            //document.getElementById('change-head').innerText = 'Schultergelenk';
            document.querySelector('#overlay').style.display = 'none';
            document.querySelector('#overlay-img-verst-container').style.display = 'block';
            document.querySelector('#img-overlay-verst img').src = require('./verst_bein_fuß/Hueftesaggital_picswitch.webp');
            var active_buttons = document.querySelectorAll('.side-menu > ul > li:nth-of-type(10) > ul li');
            active_buttons.forEach((element) => element.classList.remove('active'))
            document.querySelector('.side-menu > ul > li:nth-of-type(10) > ul li:first-of-type').classList.add('active');
        } else if (e.target.id === '9a9fe032-3204-4b97-bbed-a23844fcb68d') {
            //Funktionsbeeinträchtigung Bein
            var show = document.querySelector('.side-menu>ul>li:nth-of-type(14)');
            document.getElementById('funk-3b').classList.add('active');
            document.getElementById('funk-2b').classList.remove('active');
            document.getElementById('funk-4b').classList.remove('active');
            document.getElementById('funk-1b').classList.remove('active');
            all_buttons_side_menu.forEach((element) => {
                if (element === show) {
                    element.style.display = 'block'
                } else {
                    element.style.display = 'none'
                }
            });
            //document.getElementById('change-head').innerText = 'Schultergelenk';

        } else if (e.target.id === '8ebf602c-c8bf-49ab-8407-8165fbfa1db0') {
            //Funktionsbeeinträchtigung Bein
            var show = document.querySelector('.side-menu>ul>li:nth-of-type(15)');
            document.getElementById('funk-2b').classList.add('active');
            document.getElementById('funk-3b').classList.remove('active');
            document.getElementById('funk-4b').classList.remove('active');
            document.getElementById('funk-1b').classList.remove('active');

            all_buttons_side_menu.forEach((element) => {
                if (element === show) {
                    element.style.display = 'block'
                } else {
                    element.style.display = 'none'
                }
            });
            //document.getElementById('change-head').innerText = 'Schultergelenk';

        } else if (e.target.id === '1616af6a-4d09-43a3-9710-14a20f7aea06') {
            //Funktionsbeeinträchtigung Bein
            var show = document.querySelector('.side-menu>ul>li:nth-of-type(16)');
            //document.querySelector('.side-menu>ul>li:nth-of-type(16) ul li:first-of-type').classList.add('active');
            document.getElementById('funk-1b').classList.add('active');
            document.getElementById('funk-2b').classList.remove('active');
            document.getElementById('funk-3b').classList.remove('active');
            document.getElementById('funk-4b').classList.remove('active');
            all_buttons_side_menu.forEach((element) => {
                if (element === show) {
                    element.style.display = 'block'
                } else {
                    element.style.display = 'none'
                }
            });
            //document.getElementById('change-head').innerText = 'Schultergelenk';

            //document.getElementById('change-head').innerText = 'Fingergelenke';
            document.querySelector('#overlay').style.display = 'none';
            document.querySelector('#overlay-img-verst-container').style.display = 'block';
            /*document.querySelector('#img-overlay-verst img').src = require('./funkst_bein/KnieInst_Außen.webp');
                      var active_buttons = document.querySelectorAll('.side-menu > ul > li:nth-of-type(16) > ul li');
           active_buttons.forEach((element) => element.classList.remove('active'))
           document.querySelector('.side-menu > ul > li:nth-of-type(16) > ul li:first-of-type').classList.add('active');*/

        } else if (e.target.id === '170385b0-862f-4526-8ec5-8e11251e5f25') {
            //Funktionsbeeinträchtigung Bein
            var show = document.querySelector('.side-menu>ul>li:nth-of-type(17)');
            document.getElementById('funk-4b').classList.add('active');
            document.getElementById('funk-2b').classList.remove('active');
            document.getElementById('funk-3b').classList.remove('active');
            document.getElementById('funk-1b').classList.remove('active');
            all_buttons_side_menu.forEach((element) => {
                if (element === show) {
                    element.style.display = 'block'
                } else {
                    element.style.display = 'none'
                }
            });
            //document.getElementById('change-head').innerText = ' Schultergelenk';

        } else if (e.target.id === '848e79dd-937e-4036-b204-35d2482c69c0') {
            var show = document.querySelector('.side-menu>ul>li:nth-of-type(7)');
            document.getElementById('funk-1').classList.add('active');
            document.getElementById('funk-2').classList.remove('active');
            document.getElementById('funk-3').classList.remove('active');
            document.getElementById('funk-4').classList.remove('active');
            document.getElementById('funk-5').classList.remove('active');
            all_buttons_side_menu.forEach((element) => {
                if (element === show) {
                    element.style.display = 'block'
                } else {
                    element.style.display = 'none'
                }
            });
            var active_buttons = document.querySelectorAll('.side-menu > ul > li:nth-of-type(7) > ul li');
            active_buttons.forEach((element) => element.classList.remove('active'))
            document.getElementById('change-head').innerText = ' Handgelenk';

        } else if (e.target.id === '153c1836-9e05-4b0f-b119-f0e06c3f4759') {
            var show = document.querySelector('.side-menu>ul>li:nth-of-type(8)');
            document.getElementById('funk-2').classList.add('active');
            document.getElementById('funk-1').classList.remove('active');
            document.getElementById('funk-3').classList.remove('active');
            document.getElementById('funk-4').classList.remove('active');
            document.getElementById('funk-5').classList.remove('active');
            //document.querySelector('.overview').classList.add('mobile-small-size');
            all_buttons_side_menu.forEach((element) => {
                if (element === show) {
                    element.style.display = 'block'
                } else {
                    element.style.display = 'none'
                }
            });
            var active_buttons = document.querySelectorAll('.side-menu > ul > li:nth-of-type(8) > ul li');
            active_buttons.forEach((element) => element.classList.remove('active'))
            document.getElementById('change-head').innerText = ' Ellenbogengelenk';

        } else if (e.target.id === '33375321-79b5-476c-af0c-e55a9ac4747b') {
            var show = document.querySelector('.side-menu>ul>li:nth-of-type(9)');
            document.getElementById('funk-3').classList.add('active');
            document.getElementById('funk-1').classList.remove('active');
            document.getElementById('funk-5').classList.remove('active');
            document.getElementById('funk-2').classList.remove('active');
            document.getElementById('funk-4').classList.remove('active');
            all_buttons_side_menu.forEach((element) => {
                if (element === show) {
                    element.style.display = 'block'
                } else {
                    element.style.display = 'none'
                }
            });
            var active_buttons = document.querySelectorAll('.side-menu > ul > li:nth-of-type(9) > ul li');
            active_buttons.forEach((element) => element.classList.remove('active'))
            document.getElementById('change-head').innerText = ' Schultergelenk';

        } else if (e.target.id === '9994e60b-c292-4888-b319-f61a015e5c93') {
            var show = document.querySelector('.side-menu>ul>li:nth-of-type(19)');
            document.getElementById('funk-5').classList.add('active');
            document.getElementById('funk-1').classList.remove('active');
            document.getElementById('funk-3').classList.remove('active');
            document.getElementById('funk-2').classList.remove('active');
            document.getElementById('funk-4').classList.remove('active');
            all_buttons_side_menu.forEach((element) => {
                if (element === show) {
                    element.style.display = 'block'
                } else {
                    element.style.display = 'none'
                }
            });
            var active_buttons = document.querySelectorAll('.side-menu > ul > li:nth-of-type(19) > ul li');
            active_buttons.forEach((element) => element.classList.remove('active'))
            document.getElementById('change-head').innerText = ' Unterarmdrehung';

        } else if (e.target.id === '2433c36c-748d-4e82-8a73-f96b675e78dd') {
            //schulter 15
            /*
            var this_button = document.querySelector('.side-menu>ul>li:nth-of-type(9) ul li:nth-of-type(1)');
            var all_buttons = document.querySelectorAll('.side-menu>ul>li:nth-of-type(9) ul li');
            this_button.classList.add('active');
            all_buttons.forEach((elem => {
                if (elem !== this_button) {
                    elem.classList.remove('active');
                }
            }))*/
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            armwert.innerText = '0/20';

        } else if (e.target.id === '6ab5401f-7031-4eec-8aae-b7d6287edf2e') {
            //schulter 16
            /*
            var this_button = document.querySelector('.side-menu>ul>li:nth-of-type(9) ul li:nth-of-type(2)');
            var all_buttons = document.querySelectorAll('.side-menu>ul>li:nth-of-type(9) ul li');
            this_button.classList.add('active');
            all_buttons.forEach((elem => {
                if (elem !== this_button) {
                    elem.classList.remove('active');
                }
            }))*/
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            armwert.innerText = '1/20';

        } else if (e.target.id === '631a5f66-e10e-4eda-97f8-10f0c52d8c1f') {
            //schulter 17
            /*
            var this_button = document.querySelector('.side-menu>ul>li:nth-of-type(9) ul li:nth-of-type(3)');
            var all_buttons = document.querySelectorAll('.side-menu>ul>li:nth-of-type(9) ul li');
            this_button.classList.add('active');
            all_buttons.forEach((elem => {
                if (elem !== this_button) {
                    elem.classList.remove('active');
                }
            }))*/
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            armwert.innerText = '3/20';

        } else if (e.target.id === 'a0d7b6cc-f7a5-45bb-93ff-4ddd79fbe2db') {
            //schulter 18
            /*
            var this_button = document.querySelector('.side-menu>ul>li:nth-of-type(9) ul li:nth-of-type(4)');
            var all_buttons = document.querySelectorAll('.side-menu>ul>li:nth-of-type(9) ul li');
            this_button.classList.add('active');
            all_buttons.forEach((elem => {
                if (elem !== this_button) {
                    elem.classList.remove('active');
                }
            }))*/
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            armwert.innerText = '5/20';

        } else if (e.target.id === '30a68b27-1e50-44e2-b05c-489d295358ea') {
            //schulter 19
            /*
            var this_button = document.querySelector('.side-menu>ul>li:nth-of-type(9) ul li:nth-of-type(5)');
            var all_buttons = document.querySelectorAll('.side-menu>ul>li:nth-of-type(9) ul li');
            this_button.classList.add('active');
            all_buttons.forEach((elem => {
                if (elem !== this_button) {
                    elem.classList.remove('active');
                }
            }))*/
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            armwert.innerText = '7/20';

        } else if (e.target.id === 'c72d2a85-40e4-414f-b9e1-22562ccd668f') {
            //schulter 20
            /*
            var this_button = document.querySelector('.side-menu>ul>li:nth-of-type(9) ul li:nth-of-type(6)');
            var all_buttons = document.querySelectorAll('.side-menu>ul>li:nth-of-type(9) ul li');
            this_button.classList.add('active');
            all_buttons.forEach((elem => {
                if (elem !== this_button) {
                    elem.classList.remove('active');
                }
            }))*/
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            armwert.innerText = '2/20';

        } else if (e.target.id === 'c5081452-8820-4f5b-af58-291ea069bb95') {
            //schulter 21
            /*
            var this_button = document.querySelector('.side-menu>ul>li:nth-of-type(9) ul li:nth-of-type(6)');
            var all_buttons = document.querySelectorAll('.side-menu>ul>li:nth-of-type(9) ul li');
            this_button.classList.add('active');
            all_buttons.forEach((elem => {
                if (elem !== this_button) {
                    elem.classList.remove('active');
                }
            }))*/
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            armwert.innerText = '2/20';

        } else if (e.target.id === '5d5b5c1c-65ef-4909-8525-5b967a351422') {
            //unterarm 22

            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            armwert.innerText = '2/20';
            armwert_bez.innerText = 'Handwert';

        } else if (e.target.id === '0594183b-982d-4f0f-b2fb-6dad7db93a1c') {
            //unterarm 23

            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            armwert.innerText = '2/20';
            armwert_bez.innerText = 'Handwert';

        } else if (e.target.id === '215875a5-8497-4d6d-92da-38811387ff65') {
            //unterarm 24

            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            armwert.innerText = '1/20';
            armwert_bez.innerText = 'Handwert';

        } else if (e.target.id === '260129c4-ed28-49f8-ab07-f6ea9b5cd45f') {
            //Hüftgelenk fb3

            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            armwert.innerText = '8/20'
            armwert_bez.innerText = 'Beinwert'

        }else if (e.target.id === '0c75ecdf-c732-4012-8a25-d828596e1516') {
            //Hüftgelenk fb4

            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            armwert.innerText = '4/20'
            armwert_bez.innerText = 'Beinwert'

        }else if (e.target.id === '81e13dac-1092-4f82-b211-ca97004ae815') {
            //Hüftgelenk fb5

            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            armwert.innerText = '2/20'
            armwert_bez.innerText = 'Beinwert'

        } else if (e.target.id === 'd9544b06-0665-45ad-b70b-0d7029b681c8') {
            //Hüftgelenk fb6

            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            armwert.innerText = '1/20'
            armwert_bez.innerText = 'Beinwert'

        }

        if (e.target.id === '7bb5d324-c455-48c6-b66a-f9b992073fb6') {
            document.querySelector('.szene-arm #verlust').innerText = '55%';
            document.querySelector('.szene-arm #verlust-zusatz').innerText = 'Für die Verlustwerte der einzelnen Finger, navigieren Sie bitte zu der Hand über die Menuleiste unten';
        } else if (e.target.id === '3299f52a-e44a-44b2-95c1-8f3276f4f0bb') {
            document.querySelector('.szene-arm #verlust').innerText = '-';
            document.querySelector('.szene-arm #verlust-zusatz').innerText = '';
        } else if (e.target.id === 'd16908b6-a45d-4a49-b122-dda63bddce72') {
            document.querySelector('.szene-arm #verlust').innerText = '60%';
            document.querySelector('.szene-arm #verlust-zusatz').innerText = '';
        } else if (e.target.id === '896fe594-fa03-4767-8828-35b7d3fcaab9') {
            document.querySelector('.szene-arm #verlust').innerText = '65%';
            document.querySelector('.szene-arm #verlust-zusatz').innerText = '';
        } else if (e.target.id === 'c1c1343c-c020-4ad8-b7d1-e45f131b000e') {
            document.querySelector('.szene-arm #verlust').innerText = '70%';
            document.querySelector('.szene-arm #verlust-zusatz').innerText = '';
        } else if (hand_bones_1.includes(e.target.id)) {
            //spitzen
            document.querySelector('.szene-hand #verlust').innerText = 'je 8/20 Fingerwert';
            document.querySelector('.szene-hand #verlust-zusatz').innerText = '';
            document.querySelector('.szene-hand #verlust-zusatz2').innerText = '';
        } else if (hand_bones_2.includes(e.target.id)) {
            //mitte
            document.querySelector('.szene-hand #verlust').innerText = 'je 14/20 Fingerwert';
            document.querySelector('.szene-hand #verlust-zusatz').innerText = '';
            document.querySelector('.szene-hand #verlust-zusatz2').innerText = '';
        } else if (hand_bones_3.includes(e.target.id)) {
            //letzte Reihe
            document.querySelector('.szene-hand #verlust').innerText = 'je 20/20 Fingerwert';
            document.querySelector('.szene-hand #verlust-zusatz').innerText = 'Zeigefinger: 10% Invalidität';
            document.querySelector('.szene-hand #verlust-zusatz2').innerText = 'Andere Finger: 5% Invalidität';
        } else if (e.target.id === '1da0796b-5d50-4a9b-a58a-bbe39a232fb5') {
            //daumen spitze
            document.querySelector('.szene-hand #verlust').innerText = '12/20 Daumenwert';
            document.querySelector('.szene-hand #verlust-zusatz').innerText = '';
            document.querySelector('.szene-hand #verlust-zusatz2').innerText = '';
        } else if (e.target.id === '77158525-d293-484e-8247-daaae50ee196') {
            //daumen Mitte
            document.querySelector('.szene-hand #verlust').innerText = '20/20 Daumenwert';
            document.querySelector('.szene-hand #verlust-zusatz').innerText = '';
            document.querySelector('.szene-hand #verlust-zusatz2').innerText = '';
        } else if (e.target.id === 'f9df00f1-c57e-4e5a-b392-dbb1ac393a3d') {
            //daumen letzter knochen
            document.querySelector('.szene-hand #verlust').innerText = '10/20 Handwert';
            document.querySelector('.szene-hand #verlust-zusatz').innerText = '';
            document.querySelector('.szene-hand #verlust-zusatz2').innerText = '';
        } else if (e.target.id === 'a92099b0-83de-4029-a9d1-d84c7be1b5db') {
            //handwurzel
            document.querySelector('.szene-hand #verlust').innerText = '55%';
            document.querySelector('.szene-hand #verlust-zusatz').innerText = '';
            document.querySelector('.szene-hand #verlust-zusatz2').innerText = '';
        } else if (e.target.id === 'dbd5e1ec-52eb-4596-b958-03465a6adcfd') {
            //reset
            document.querySelector('.szene-hand #verlust').innerText = '-';
            document.querySelector('.szene-hand #verlust-zusatz').innerText = '';
            document.querySelector('.szene-hand #verlust-zusatz2').innerText = '';
        } else if (e.target.id === 'd207df03-2077-4dd9-939a-81f0feaf1bd6') {
            //Bein unterschenkel
            document.querySelector('.szene-bein #verlust').innerText = '45%';
        } else if (e.target.id === '63b64117-2444-42d3-babf-9339b2531033') {
            //Bein unterschenkel oben
            document.querySelector('.szene-bein #verlust').innerText = '50%';
        } else if (e.target.id === '95d06b40-e606-4a00-9469-478a55b3c220') {
            //Bein oberschenkel unten
            document.querySelector('.szene-bein #verlust').innerText = '60%';
        } else if (e.target.id === 'fc7de354-425b-40d5-9c7e-028f667f67e9') {
            //Bein oberschenkel oben
            document.querySelector('.szene-bein #verlust').innerText = '70%';
        } else if (e.target.id === '93011185-4b70-4d6e-9620-a8a009d16b4a') {
            //Bein fuß
            document.querySelector('.szene-bein #verlust').innerText = '40%';
        } else if (e.target.id === '0fee726e-b9dd-485b-b53f-ab8e85e79c17') {
            //Bein Wadenkopf
            document.querySelector('.szene-bein #verlust').innerText = '60%';
        } else if (e.target.id === '53f0f5f4-666e-4cae-bd25-79a4f18177f3') {
            //Bein reset
            document.querySelector('.szene-bein #verlust').innerText = '-';
        } else if (e.target.id === '4d4fe5f7-8ea7-48a8-bccc-f901f2518a50') {
            //Fuß Zehen
            document.querySelector('.szene-fuß #verlust').innerHTML = 'je 2%';
        } else if (e.target.id === '84b57cf6-6e59-49ec-a9b9-faef413753a2') {
            //Fuß fußknochenkopf
            document.querySelector('.szene-fuß #verlust').innerHTML = '7/20<br><span>Sharp-Amputation</span>';
        } else if (e.target.id === '8ca22f17-f437-4ebd-98e5-b9261f2c6894') {
            //Fuß Großerzeh
            document.querySelector('.szene-fuß #verlust').innerText = '5%';
        } else if (e.target.id === '61115602-005e-4e96-8f6d-e57c37554d76') {
            //Fuß Sprung und Fersenbein
            document.querySelector('.szene-fuß #verlust').innerHTML = '14/20<br><span>Chopart-Amputation</span>';
        } else if (e.target.id === '3bb5d47e-1c82-49b2-88e4-d9c8c5355298') {
            //Fuß Fußwurzel
            document.querySelector('.szene-fuß #verlust').innerHTML = '10/20<br><span>Lisfranc-Amputation</span>';
        } else if (e.target.id === '5104a323-4c94-4373-a8d2-53447a8fd88a') {
            //Fuß Fuß
            document.querySelector('.szene-fuß #verlust').innerHTML = '40%<br><span>Syme-Amputation</span>';
        } else if (e.target.id === '48c45565-86db-4665-a362-7a7186c01757') {
            //Fuß reset
            document.querySelector('.szene-fuß #verlust').innerText = '-';
        }

    }

    function onMouseHover(e) {
        if (document.getElementById('verlust-zusatz') !== null) {
            if (e.target.id === '7bb5d324-c455-48c6-b66a-f9b992073fb6') {
                document.querySelector('.szene-arm #verlust').innerText = '55%';
                document.querySelector('.szene-arm #verlust-zusatz').innerText = 'Für die Verlustwerte der einzelnen Finger, navigieren Sie bitte zu der Hand über die Menuleiste unten';
            } else if (e.target.id === '3299f52a-e44a-44b2-95c1-8f3276f4f0bb') {
                document.querySelector('.szene-arm #verlust').innerText = '-';
                document.querySelector('.szene-arm #verlust-zusatz').innerText = '';
            } else if (e.target.id === 'd16908b6-a45d-4a49-b122-dda63bddce72') {
                document.querySelector('.szene-arm #verlust').innerText = '60%';
                document.querySelector('.szene-arm #verlust-zusatz').innerText = '';
            } else if (e.target.id === '896fe594-fa03-4767-8828-35b7d3fcaab9') {
                document.querySelector('.szene-arm #verlust').innerText = '65%';
                document.querySelector('.szene-arm #verlust-zusatz').innerText = '';
            } else if (e.target.id === 'c1c1343c-c020-4ad8-b7d1-e45f131b000e') {
                document.querySelector('.szene-arm #verlust').innerText = '70%';
                document.querySelector('.szene-arm #verlust-zusatz').innerText = '';
            } else if (hand_bones_1.includes(e.target.id)) {
                //spitzen
                document.querySelector('.szene-hand #verlust').innerText = 'je 8/20 Fingerwert';
                document.querySelector('.szene-hand #verlust-zusatz').innerText = '';
                document.querySelector('.szene-hand #verlust-zusatz2').innerText = '';
            } else if (hand_bones_2.includes(e.target.id)) {
                //mitte
                document.querySelector('.szene-hand #verlust').innerText = 'je 14/20 Fingerwert';
                document.querySelector('.szene-hand #verlust-zusatz').innerText = '';
                document.querySelector('.szene-hand #verlust-zusatz2').innerText = '';
            } else if (hand_bones_3.includes(e.target.id)) {
                //letzte Reihe
                document.querySelector('.szene-hand #verlust').innerText = 'je 20/20 Fingerwert';
                document.querySelector('.szene-hand #verlust-zusatz').innerText = 'Zeigefinger: 10% Invalidität';
                document.querySelector('.szene-hand #verlust-zusatz2').innerText = 'Andere Finger: 5% Invalidität';
            } else if (e.target.id === '1da0796b-5d50-4a9b-a58a-bbe39a232fb5') {
                //daumen spitze
                document.querySelector('.szene-hand #verlust').innerText = '12/20 Daumenwert';
                document.querySelector('.szene-hand #verlust-zusatz').innerText = '';
                document.querySelector('.szene-hand #verlust-zusatz2').innerText = '';
            } else if (e.target.id === '77158525-d293-484e-8247-daaae50ee196') {
                //daumen Mitte
                document.querySelector('.szene-hand #verlust').innerText = '20/20 Daumenwert';
                document.querySelector('.szene-hand #verlust-zusatz').innerText = '';
                document.querySelector('.szene-hand #verlust-zusatz2').innerText = '';
            } else if (e.target.id === 'f9df00f1-c57e-4e5a-b392-dbb1ac393a3d') {
                //daumen letzter knochen
                document.querySelector('.szene-hand #verlust').innerText = '10/20 Handwert';
                document.querySelector('.szene-hand #verlust-zusatz').innerText = '';
                document.querySelector('.szene-hand #verlust-zusatz2').innerText = '';
            } else if (e.target.id === 'a92099b0-83de-4029-a9d1-d84c7be1b5db') {
                //handwurzel
                document.querySelector('.szene-hand #verlust').innerText = '55%';
                document.querySelector('.szene-hand #verlust-zusatz').innerText = '';
                document.querySelector('.szene-hand #verlust-zusatz2').innerText = '';
            } else if (e.target.id === 'dbd5e1ec-52eb-4596-b958-03465a6adcfd') {
                //reset
                document.querySelector('.szene-hand #verlust').innerText = '-';
                document.querySelector('.szene-hand #verlust-zusatz').innerText = '';
                document.querySelector('.szene-hand #verlust-zusatz2').innerText = '';
            } else if (e.target.id === 'd207df03-2077-4dd9-939a-81f0feaf1bd6') {
                //Bein unterschenkel
                document.querySelector('.szene-bein #verlust').innerText = '45%';
            } else if (e.target.id === '63b64117-2444-42d3-babf-9339b2531033') {
                //Bein unterschenkel oben
                document.querySelector('.szene-bein #verlust').innerText = '50%';
            } else if (e.target.id === '95d06b40-e606-4a00-9469-478a55b3c220') {
                //Bein oberschenkel unten
                document.querySelector('.szene-bein #verlust').innerText = '60%';
            } else if (e.target.id === 'fc7de354-425b-40d5-9c7e-028f667f67e9') {
                //Bein oberschenkel oben
                document.querySelector('.szene-bein #verlust').innerText = '70%';
            } else if (e.target.id === '93011185-4b70-4d6e-9620-a8a009d16b4a') {
                //Bein fuß
                document.querySelector('.szene-bein #verlust').innerText = '40%';
            } else if (e.target.id === '0fee726e-b9dd-485b-b53f-ab8e85e79c17') {
                //Bein Wadenkopf
                document.querySelector('.szene-bein #verlust').innerText = '60%';
            } else if (e.target.id === '53f0f5f4-666e-4cae-bd25-79a4f18177f3') {
                //Bein reset
                document.querySelector('.szene-bein #verlust').innerText = '-';
            } else if (e.target.id === '4d4fe5f7-8ea7-48a8-bccc-f901f2518a50') {
                //Fuß Zehen
                document.querySelector('.szene-fuß #verlust').innerHTML = 'je 2%';
            } else if (e.target.id === '84b57cf6-6e59-49ec-a9b9-faef413753a2') {
                //Fuß fußknochenkopf
                document.querySelector('.szene-fuß #verlust').innerHTML = '7/20<br><span>Sharp-Amputation</span>';
            } else if (e.target.id === '8ca22f17-f437-4ebd-98e5-b9261f2c6894') {
                //Fuß Großerzeh
                document.querySelector('.szene-fuß #verlust').innerText = '5%';
            } else if (e.target.id === '61115602-005e-4e96-8f6d-e57c37554d76') {
                //Fuß Sprung und Fersenbein
                document.querySelector('.szene-fuß #verlust').innerHTML = '14/20<br><span>Chopart-Amputation</span>';
            } else if (e.target.id === '3bb5d47e-1c82-49b2-88e4-d9c8c5355298') {
                //Fuß Fußwurzel
                document.querySelector('.szene-fuß #verlust').innerHTML = '10/20<br><span>Lisfranc-Amputation</span>';
            } else if (e.target.id === '5104a323-4c94-4373-a8d2-53447a8fd88a') {
                //Fuß Fuß
                document.querySelector('.szene-fuß #verlust').innerHTML = '40%<br><span>Syme-Amputation</span>';
            } else if (e.target.id === '48c45565-86db-4665-a362-7a7186c01757') {
                //Fuß reset
                document.querySelector('.szene-fuß #verlust').innerText = '-';
            }
        }
    }

    const trigger_buttons_verst = (event) => {
        let num = event.target.getAttribute('data-arg2');
        var armwert = document.getElementById('armwert');
        var armwert_bez = document.getElementById('armwert-bez');
        var armwert_anzeige = document.getElementById('wert-container');

        var daumen_bilder = document.getElementById('daumen-bilder');
        var finger_bilder = document.getElementById('finger-bilder');
        var daumen_bilder_u = document.getElementById('show-daumen');
        var finger_bilder_u = document.getElementById('show-finger');

        var all_buttons_verst = document.querySelectorAll('.side-menu>ul>li>ul>li');
        let this_elem = event.target;


        if (num === '1') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'none';
            }
            if (finger_bilder !== null) {
                daumen_bilder.style.display = 'none';
                finger_bilder.style.display = 'flex';
                daumen_bilder_u.style.display = 'none';
                finger_bilder_u.style.display = 'flex';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });

            if (screen_small.matches) {
                document.querySelector('#overlay-info-imgs').style.display = 'flex';
                document.getElementById('image-big').src = finger1_big;
                document.querySelector('#overlay-info-imgs .szene-versteifung .change-text').innerText = 'Fingergelenke Versteifung';
            }
            document.querySelector('#img-overlay-verst img').src = require('./verst_hand_arm/Fingerversteifung_picswitch.webp');
            //spline.current.emitEvent('mouseDown', 'f91222ad-4ebf-4846-8fe1-062c780d36d6');
        } else if (num === '2') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'none';
            }
            if (daumen_bilder !== null) {
                finger_bilder.style.display = 'none';
                daumen_bilder.style.display = 'flex';
                finger_bilder_u.style.display = 'none';
                daumen_bilder_u.style.display = 'flex';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });

            if (screen_small.matches) {
                document.querySelector('#overlay-info-imgs').style.display = 'flex';
                document.getElementById('image-big').src = daumen1_big;
                document.querySelector('#overlay-info-imgs .szene-versteifung .change-text').innerText = 'Daumengelenke Versteifung';
            }
            document.querySelector('#img-overlay-verst img').src = require('./verst_hand_arm/Daumenversteifung_picswitch.webp');
            //spline.current.emitEvent('mouseDown', '3b71e511-6357-4f00-bc81-afafeda270d8');
        } else if (num === '3') {
            if (finger_bilder !== null) {
                finger_bilder.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            if (daumen_bilder !== null) {
                daumen_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
            }
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert_bez.innerText = 'Handwert';
            armwert.innerText = '5/20';
            document.querySelector('#img-overlay-verst img').src = require('./verst_hand_arm/Handgelenk_Seitkantung_picswitch.webp');
            //spline.current.emitEvent('mouseDown', '1be7575b-a4c5-4cc1-adc7-744da1ea75dd');
        } else if (num === '4') {
            if (finger_bilder !== null) {
                finger_bilder.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            if (daumen_bilder !== null) {
                daumen_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
            }
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert_bez.innerText = 'Handwert';
            armwert.innerText = '5/20';
            document.querySelector('#img-overlay-verst img').src = require('./verst_hand_arm/Handgelenk_Seitkantungaufwaerts_picswitch.webp');
            //spline.current.emitEvent('mouseDown', '3c156ae9-c47d-4f2b-b76e-fafe398a14c8');
        } else if (num === '5') {
            if (finger_bilder !== null) {
                finger_bilder.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            if (daumen_bilder !== null) {
                daumen_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
            }
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert_bez.innerText = 'Armwert';
            armwert.innerText = '8/20';
            document.querySelector('#img-overlay-verst img').src = Schulter_Frontal_verst;
            //spline.current.emitEvent('mouseDown', '1ba3451b-6a22-460d-a0b4-d861e1d9a332');
        } else if (num === '6') {
            if (finger_bilder !== null) {
                finger_bilder.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            if (daumen_bilder !== null) {
                daumen_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
            }
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert_bez.innerText = 'Armwert';
            armwert.innerText = '8/20';
            document.querySelector('#img-overlay-verst img').src = require('./verst_hand_arm/Schulter_Seite_picswitch.webp');
            //spline.current.emitEvent('mouseDown', '13718ebe-2319-40a8-9d40-3d1828ec36a4');
        } else if (num === '7') {
            if (finger_bilder !== null) {
                finger_bilder.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            if (daumen_bilder !== null) {
                daumen_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
            }
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert_bez.innerText = 'Armwert';
            armwert.innerText = '8/20';
            document.querySelector('#img-overlay-verst img').src = require('./verst_hand_arm/Schulter_transversal_neu_picswitch.webp');
            //spline.current.emitEvent('mouseDown', '36d177c1-c8d8-4214-bd1b-994b47b9a1fb');
        } else if (num === '8') {
            if (finger_bilder !== null) {
                finger_bilder.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            if (daumen_bilder !== null) {
                daumen_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
            }
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert_bez.innerText = 'Armwert';
            armwert.innerText = '6/20';
            document.querySelector('#img-overlay-verst img').src = require('./verst_hand_arm/Ellenbogen_UNteramdrehungfrei_NEU_picswitch.webp');
            //spline.current.emitEvent('mouseDown', '61bf3bfd-9098-4e66-b6a2-84c07305381f');
        } else if (num === '9') {
            if (finger_bilder !== null) {
                finger_bilder.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            if (daumen_bilder !== null) {
                daumen_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
            }
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert_bez.innerText = 'Armwert';
            armwert.innerText = '7/20';
            document.querySelector('#img-overlay-verst img').src = require('./verst_hand_arm/Ellenbogen_Pronation_picswitch.webp');
            //spline.current.emitEvent('mouseDown', 'd89f99cf-6e12-4425-b64a-36d14b932a52');
        } else if (num === '10') {
            if (finger_bilder !== null) {
                finger_bilder.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            if (daumen_bilder !== null) {
                daumen_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
            }
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert_bez.innerText = 'Armwert';
            armwert.innerText = '8/20';
            document.querySelector('#img-overlay-verst img').src = require('./verst_hand_arm/Ellenbogen_Neutral_picswitch.webp');
            //spline.current.emitEvent('mouseDown', '896257b0-2da3-4bd9-a6ed-20ba462d94c0');
        } else if (num === '11') {
            if (finger_bilder !== null) {
                finger_bilder.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            if (daumen_bilder !== null) {
                daumen_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
            }
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert_bez.innerText = 'Armwert';
            armwert.innerText = '12/20';
            document.querySelector('#img-overlay-verst img').src = require('./verst_hand_arm/Ellenbogen_Supination_picswitch.webp');
            //spline.current.emitEvent('mouseDown', 'bc3a6040-e0e4-416f-90e4-886772cea531');
        } else if (num === '12') {
            if (finger_bilder !== null) {
                finger_bilder.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            if (daumen_bilder !== null) {
                daumen_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
            }
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert_bez.innerText = 'Handwert';
            armwert.innerText = '7/20';
            document.querySelector('#img-overlay-verst img').src = require('./verst_hand_arm/Handgelenk_AufhebunginSupination.png');
            //spline.current.emitEvent('mouseDown', '1be7575b-a4c5-4cc1-adc7-744da1ea75dd');
        } else if (num === '13') {
            if (finger_bilder !== null) {
                finger_bilder.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            if (daumen_bilder !== null) {
                daumen_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
            }
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert_bez.innerText = 'Handwert';
            armwert.innerText = '6/20';
            document.querySelector('#img-overlay-verst img').src = require('./verst_hand_arm/Handgelenk_AufhebunginNeutral.png');
            //spline.current.emitEvent('mouseDown', '1be7575b-a4c5-4cc1-adc7-744da1ea75dd');
        } else if (num === '14') {
            if (finger_bilder !== null) {
                finger_bilder.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            if (daumen_bilder !== null) {
                daumen_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
            }
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert_bez.innerText = 'Handwert';
            armwert.innerText = '5/20';
            document.querySelector('#img-overlay-verst img').src = require('./verst_hand_arm/Handgelenk_AufhebunginPronation.png');
            //spline.current.emitEvent('mouseDown', '1be7575b-a4c5-4cc1-adc7-744da1ea75dd');
        } else if (num === '22') {
            if (finger_bilder !== null) {
                finger_bilder.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            if (daumen_bilder !== null) {
                daumen_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
            }
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '8/20';
            armwert_bez.innerText = 'Handwert:';
            document.querySelector('#img-overlay-verst img').src = require('./verst_hand_arm/Handgelenk_AufhebunginPronation_picswitch.webp');
            //spline.current.emitEvent('mouseDown', '53ac6513-8219-4416-a313-c58a10a7dd14');
        } else if (num === '23') {
            if (finger_bilder !== null) {
                finger_bilder.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            if (daumen_bilder !== null) {
                daumen_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
            }
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '9/20';
            armwert_bez.innerText = 'Handwert:';
            document.querySelector('#img-overlay-verst img').src = require('./verst_hand_arm/Handgelenk_AufhebunginNeutral_picswitch.webp');
            //spline.current.emitEvent('mouseDown', 'f86807a5-2289-464a-99e2-c1e2c40f8b4a');
        } else if (num === '24') {
            if (finger_bilder !== null) {
                finger_bilder.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            if (daumen_bilder !== null) {
                daumen_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
            }
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '11/20';
            armwert_bez.innerText = 'Handwert:';
            document.querySelector('#img-overlay-verst img').src = require('./verst_hand_arm/Handgelenk_AufhebunginSupination_picswitch.webp');
            //spline.current.emitEvent('mouseDown', '9dbb63d2-8b25-4180-b51c-2949984e2c85');
        } else if (num === 'vb1') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            if (finger_bilder !== null) {
                daumen_bilder.style.display = 'none';
                finger_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '10/20';
            armwert_bez.innerText = 'Beinwert:';
            document.querySelector('#img-overlay-verst img').src = require('./verst_bein_fuß/Hueftesaggital_picswitch.webp');
        } else if (num === 'vb2') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            if (finger_bilder !== null) {
                daumen_bilder.style.display = 'none';
                finger_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '10/20';
            armwert_bez.innerText = 'Beinwert:';
            document.querySelector('#img-overlay-verst img').src = require('./verst_bein_fuß/Hueftetransversal_picswitch.webp');
            //spline.current.emitEvent('mouseDown', '2e1496b6-b5d4-4220-b3a4-7f8cf6a5c257');
        } else if (num === 'vb3') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            if (finger_bilder !== null) {
                daumen_bilder.style.display = 'none';
                finger_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '8/20';
            armwert_bez.innerText = 'Beinwert:';
            document.querySelector('#img-overlay-verst img').src = require('./verst_bein_fuß/Kniesaggital_picswitch.webp');
            //spline.current.emitEvent('mouseDown', 'e778d29a-4ff7-4ffb-af50-7816377e34f1');
        } else if (num === 'vb4') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            if (finger_bilder !== null) {
                daumen_bilder.style.display = 'none';
                finger_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '8/20';
            armwert_bez.innerText = 'Beinwert:';
            document.querySelector('#img-overlay-verst img').src = require('./verst_bein_fuß/Kniefrontal__picswitch.webp');
            //spline.current.emitEvent('mouseDown', '92d3bd47-12e1-4088-af80-86f73942aebd');
        } else if (num === 'vb5') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            if (finger_bilder !== null) {
                daumen_bilder.style.display = 'none';
                finger_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '6/20';
            armwert_bez.innerText = 'Fußwert:';

            if (screen_small.matches) {
                document.querySelector('#overlay-info-imgs').style.display = 'flex';
                document.getElementById('image-big').src = versteifung_fußgelenk_img;
                // document.getElementById('change-text').innerText = 'Normbewegung Schultergelenk';
            }
            document.querySelector('#img-overlay-verst img').src = require('./verst_bein_fuß/OberesSprunggelenk_picswitch.webp');
            document.querySelector('.side-menu>ul>li:nth-of-type(12) .imgs img').style.visibility = 'visible';
            //spline.current.emitEvent('mouseDown', '79c1e096-8347-4443-a2c8-05e42b304eb1');
        } else if (num === 'vb6') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            if (finger_bilder !== null) {
                daumen_bilder.style.display = 'none';
                finger_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '4/20';
            armwert_bez.innerText = 'Fußwert:';
            document.querySelector('#img-overlay-verst img').src = require('./verst_bein_fuß/UnteresSprunggelenk_picswitch.webp');
            document.querySelector('.side-menu>ul>li:nth-of-type(12) .imgs img').style.visibility = 'hidden';
            //spline.current.emitEvent('mouseDown', '39cdd62c-54e8-4b29-9ae3-e4a82db1ef17');
        } else if (num === 'vb7') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            if (finger_bilder !== null) {
                daumen_bilder.style.display = 'none';
                finger_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '9/20';
            armwert_bez.innerText = 'Fußwert:';
            document.querySelector('#img-overlay-verst img').src = require('./verst_bein_fuß/BeideSprunggelenke_picswitch.webp');
            document.querySelector('.side-menu>ul>li:nth-of-type(12) .imgs img').style.visibility = 'hidden';
            //spline.current.emitEvent('mouseDown', '55e677c7-87fc-41b9-9f03-8bae09de8813');
        } else if (num === 'vb8') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            if (finger_bilder !== null) {
                daumen_bilder.style.display = 'none';
                finger_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '8/20';
            armwert_bez.innerText = 'Großzehenwert:';
            document.querySelector('#img-overlay-verst img').src = require('./verst_bein_fuß/Suffizienter_bodenkontakt.webp');
            //spline.current.emitEvent('mouseDown', '918979ce-333e-4b68-8f52-0cc189848aec');
        } else if (num === 'vb9') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            if (finger_bilder !== null) {
                daumen_bilder.style.display = 'none';
                finger_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '12/20';
            armwert_bez.innerText = 'Großzehenwert:';
            document.querySelector('#img-overlay-verst img').src = require('./verst_bein_fuß/VersteifungGrundundEnd_picswitch.webp');
            //spline.current.emitEvent('mouseDown', 'b88dabb2-abf1-476d-8b42-b29937d1aa68');
        } else if (num === 'vb10') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            if (finger_bilder !== null) {
                daumen_bilder.style.display = 'none';
                finger_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '1/1';
            armwert_bez.innerText = 'Großzehenwert:';
            document.querySelector('#img-overlay-verst img').src = require('./verst_bein_fuß/Groсzehbeuges_picswitch.webp');
            //spline.current.emitEvent('mouseDown', 'c2c7b7c1-0363-4f96-a580-6564f175440a');
        } else if (num === 'vb11') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            if (finger_bilder !== null) {
                daumen_bilder.style.display = 'none';
                finger_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '6/20';
            armwert_bez.innerText = 'Zehenwert:';
            document.querySelector('#img-overlay-verst img').src = require('./verst_bein_fuß/Kleinzehneutral_picswitch.webp');
            //spline.current.emitEvent('mouseDown', '3ac28456-835b-4f4f-aeef-6dcedf7564d5');
        } else if (num === 'vb12') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            if (finger_bilder !== null) {
                daumen_bilder.style.display = 'none';
                finger_bilder.style.display = 'none';
                daumen_bilder_u.style.display = 'none';
                finger_bilder_u.style.display = 'none';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '10/20';
            armwert_bez.innerText = 'Zehenwert:';
            document.querySelector('#img-overlay-verst img').src = require('./verst_bein_fuß/Kleinzehfehlstellung_picswitch.webp');
            //spline.current.emitEvent('mouseDown', '1ca3fdb9-8ef5-4083-9375-6b908f15b1db');
        }
    };

    const trigger_buttons_funk = (event) => {
        let num = event.target.getAttribute('data-arg3');

        var armwert = document.getElementById('armwert');
        var armwert_bez = document.getElementById('armwert-bez');
        var armwert_anzeige = document.getElementById('wert-container');

        var hand_norm_imgs = document.getElementById('hand-norm-img');
        var elle_norm_imgs = document.getElementById('elle-norm-img');
        var schult_norm_imgs = document.getElementById('schult-norm-img');

        var all_buttons_verst = document.querySelectorAll('.side-menu>ul>li>ul>li');
        let this_elem = event.target;

        //if(num !== 'fb1'){document.querySelector('#img-norm-huefte').style.display = 'none';}

        if (num === '5') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }

            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert_bez.innerText = 'Handwert';
            armwert.innerText = '-';
            document.getElementById('hint-hand-funk').style.display = 'block';
            document.getElementById('hint-hand-funk').innerText = 'Nachdem die Normbeweglichkeiten in allen Gelenken eigentlich Konsens darstellen und in den üblichen Messblättern wiedergespiegelt sind, so stellte sich bei der Norm-handgelenksbeweglichkeit Korretkurbedarf heraus und die Normbeweglichkeit ist zu definieren wie folgt.';

            if (screen_small.matches) {
                document.querySelector('#overlay-info-imgs').style.display = 'flex';
                document.getElementById('image-big2').src = hand_norm_img_mobil;
                document.querySelector('#overlay-info-imgs .szene-funktionsstoerung .change-text').innerText = 'Normbewegung Handgelenk';
            }

            spline.current.emitEvent('mouseDown', '1e8dbd7c-23fa-4bde-87fe-90c644e8d73a');
        } else if (num === '6') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }

            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert_bez.innerText = 'Handwert';
            armwert.innerText = '4/20';
            document.getElementById('hint-hand-funk').style.display = 'none';
            spline.current.emitEvent('mouseDown', 'cf33a77c-6658-44cb-859b-821554832474');
        } else if (num === '7') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }

            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert_bez.innerText = 'Handwert';
            armwert.innerText = '3/20';
            document.getElementById('hint-hand-funk').style.display = 'none';
            spline.current.emitEvent('mouseDown', '0f4ea90c-68db-4b40-863f-f3cf71bfee37');
        } else if (num === '8') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }

            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert_bez.innerText = 'Handwert';
            armwert.innerText = '2/20';
            document.getElementById('hint-hand-funk').style.display = 'none';
            spline.current.emitEvent('mouseDown', '07cf1877-8085-43e6-a4e9-3263868627c6');
        } else if (num === '9') {
            //ellenbogen
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }

            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '3/20';
            armwert_bez.innerText = 'Armwert';
            document.getElementById('hint-hand-funk').style.display = 'none';
            spline.current.emitEvent('mouseDown', '102c63c3-f345-4f47-9c6a-75ea54b27187');
        } else if (num === '22') {
            //ellenbogen
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'none';
            }

            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            document.getElementById('hint-hand-funk').style.display = 'none';

            if (screen_small.matches) {
                document.querySelector('#overlay-info-imgs').style.display = 'flex';
                document.getElementById('image-big2').src = elle_norm_img;
                document.querySelector('#overlay-info-imgs .szene-funktionsstoerung .change-text').innerText = 'Normbewegung Ellenbogen';

            }

            spline.current.emitEvent('mouseDown', 'a8880e34-86fd-4b9d-886f-a62d80bd1670');
        } else if (num === '10') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }

            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '5/20';
            armwert_bez.innerText = 'Armwert';
            document.getElementById('hint-hand-funk').style.display = 'none';
            spline.current.emitEvent('mouseDown', 'acf20f9d-b407-4fe2-9361-7f961195b4fd');
        } else if (num === '11') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }

            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '4/20';
            armwert_bez.innerText = 'Armwert';
            document.getElementById('hint-hand-funk').style.display = 'none';
            spline.current.emitEvent('mouseDown', '7a46f167-4b85-4a23-9f15-59d4f53e438d');
        } else if (num === '12') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }

            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '2/20';
            armwert_bez.innerText = 'Armwert';
            document.getElementById('hint-hand-funk').style.display = 'none';
            spline.current.emitEvent('mouseDown', '209a9862-7272-4215-852c-7015afed31d8');
        } else if (num === '13') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }

            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '1/20';
            armwert_bez.innerText = 'Armwert';
            document.getElementById('hint-hand-funk').style.display = 'none';
            spline.current.emitEvent('mouseDown', '9350af50-0f49-4515-ad4a-9952f3b776be');
        } else if (num === '14') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }

            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '1/20';
            armwert_bez.innerText = 'Armwert';
            document.getElementById('hint-hand-funk').style.display = 'none';
            spline.current.emitEvent('mouseDown', 'aefcc5f7-0d3e-4814-95ea-c8a94376cf9c');
        } else if (num === '15') {
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '-';
            armwert_bez.innerText = 'Armwert';

            if (screen_small.matches) {
                document.querySelector('#overlay-info-imgs').style.display = 'flex';
                document.getElementById('image-big2').src = schult_norm_img;
                document.querySelector('#overlay-info-imgs .szene-funktionsstoerung .change-text').innerText = 'Normbewegung Schultergelenk';


            }
            spline.current.emitEvent('mouseDown', '2433c36c-748d-4e82-8a73-f96b675e78dd');
        } else if (num === '16') {
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert_bez.innerText = 'Armwert';
            spline.current.emitEvent('mouseDown', '6ab5401f-7031-4eec-8aae-b7d6287edf2e');
        } else if (num === '17') {
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            spline.current.emitEvent('mouseDown', '631a5f66-e10e-4eda-97f8-10f0c52d8c1f');
        } else if (num === '18') {
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert_bez.innerText = 'Armwert';
            spline.current.emitEvent('mouseDown', 'a0d7b6cc-f7a5-45bb-93ff-4ddd79fbe2db');
        } else if (num === '19') {
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert_bez.innerText = 'Armwert';
            spline.current.emitEvent('mouseDown', '30a68b27-1e50-44e2-b05c-489d295358ea');
        } else if (num === '20') {
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            spline.current.emitEvent('mouseDown', 'c72d2a85-40e4-414f-b9e1-22562ccd668f');

        } else if (num === '21') {
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert_bez.innerText = 'Armwert';
            spline.current.emitEvent('mouseDown', 'c5081452-8820-4f5b-af58-291ea069bb95');

        } else if (num === '25') {
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            spline.current.emitEvent('mouseDown', '5d5b5c1c-65ef-4909-8525-5b967a351422');

        } else if (num === '23') {
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            spline.current.emitEvent('mouseDown', '0594183b-982d-4f0f-b2fb-6dad7db93a1c');

        } else if (num === '24') {
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            spline.current.emitEvent('mouseDown', '215875a5-8497-4d6d-92da-38811387ff65');

        } else if (num === 'fb1') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'none';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });

            if (screen_small.matches) {
                document.querySelector('#overlay-info-imgs').style.display = 'flex';
                document.getElementById('image-big').src = GrafikNormbewegl_Hüfte_img;
                document.querySelector('#overlay-info-imgs .szene-funktionsstoerung-bein .change-text').innerText = 'Normbewegung Hüftgelenk';
            }

            document.querySelector('#img-norm-huefte').style.display = 'block';
            spline.current.emitEvent('mouseDown', '622a8665-9b3d-4b38-9d1e-4a73f2f6c332');

        } else if (num === 'fb2') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '1/20'
            armwert_bez.innerText = 'Beinwert'
            spline.current.emitEvent('mouseDown', 'ab81c691-90c4-4de4-b52c-682f55fb88fd');

        } else if (num === 'fb3') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '8/20'
            armwert_bez.innerText = 'Beinwert'
            spline.current.emitEvent('mouseDown', '260129c4-ed28-49f8-ab07-f6ea9b5cd45f');

        } else if (num === 'fb4') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '4/20'
            armwert_bez.innerText = 'Beinwert'
            spline.current.emitEvent('mouseDown', '0c75ecdf-c732-4012-8a25-d828596e1516');

        } else if (num === 'fb5') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '2/20'
            armwert_bez.innerText = 'Beinwert'
            spline.current.emitEvent('mouseDown', '81e13dac-1092-4f82-b211-ca97004ae815');

        } else if (num === 'fb6') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '1/20'
            armwert_bez.innerText = 'Beinwert'
            spline.current.emitEvent('mouseDown', 'd9544b06-0665-45ad-b70b-0d7029b681c8');

        } else if (num === 'fb7') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '1/20'
            armwert_bez.innerText = 'Beinwert'
            spline.current.emitEvent('mouseDown', '19705d5c-4cef-4af0-817c-b8ecc4c515fe');

        } else if (num === 'fb8') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '2/20'
            armwert_bez.innerText = 'Beinwert'
            spline.current.emitEvent('mouseDown', 'd7e65fdc-522d-4f86-a2b0-670bacabdd5b');

        } else if (num === 'fb9') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '3/20'
            armwert_bez.innerText = 'Beinwert'
            spline.current.emitEvent('mouseDown', '95ff1920-7029-4337-a2d3-d7c6c0146e91');

        } else if (num === 'fb10') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = 'Je nach Ausmaß mindestens 4/20'
            armwert_bez.innerText = 'Beinwert'
            spline.current.emitEvent('mouseDown', 'ed7ef104-f119-4364-90c2-c408b3405435');

        } else if (num === 'fb11') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '1/20'
            armwert_bez.innerText = 'Beinwert'
            spline.current.emitEvent('mouseDown', '6cdb5415-20fb-4d83-9cf2-dcd82842bd4f');

        } else if (num === 'fb12') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '4/20'
            armwert_bez.innerText = 'Beinwert'
            spline.current.emitEvent('mouseDown', '65ea6751-58f4-4650-ae1b-b0f6d043ee4e');

        } else if (num === 'fb13') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '7/20'
            armwert_bez.innerText = 'Beinwert'
            spline.current.emitEvent('mouseDown', '416b1670-8607-45a9-994d-07152b6e1da9');

        } else if (num === 'fb14') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'none';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            document.querySelector('#button-table-1').style.display = 'block';
            document.querySelector('#button-table-2').style.display = 'none';
            spline.current.emitEvent('mouseDown', 'e9e2ea3f-50b8-4ba5-b465-cb60cc1208a3');

        } else if (num === 'fb15') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'none';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });

            document.querySelector('#button-table-2').style.display = 'block';
            document.querySelector('#button-table-1').style.display = 'none';
            spline.current.emitEvent('mouseDown', '0fb76044-fd4e-4005-b936-0f95c3751a71');

        } else if (num === 'fb16') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'none';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });

            document.querySelector('#button-table-2').style.display = 'none';
            document.querySelector('#button-table-1').style.display = 'none';
            spline.current.emitEvent('mouseDown', '002d5368-31e1-4c6f-b7ba-a85c32de9d2a');

        } else if (num === 'knieinst1') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '1/20'
            armwert_bez.innerText = 'Beinwert'
        } else if (num === 'knieinst2') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '3/20'
            armwert_bez.innerText = 'Beinwert'
        } else if (num === 'knieinst3') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '3/20'
            armwert_bez.innerText = 'Beinwert'
        } else if (num === 'knieinst4') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '6/20'
            armwert_bez.innerText = 'Beinwert'
        } else if (num === 'knieinst5') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '5/20'
            armwert_bez.innerText = 'Beinwert'
        } else if (num === 'knieinst6') {
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'block';
            }
            this_elem.classList.add('active');
            all_buttons_verst.forEach(elem => {
                if (elem !== this_elem) {
                    elem.classList.remove('active')
                }
            });
            armwert.innerText = '10/20'
            armwert_bez.innerText = 'Beinwert'
        }
    };

    const show_overlay = (event) => {
        let num = event.target.getAttribute('data-arg10');

        if (num === '2') {
            document.querySelector('#overlay-info').style.display = 'flex';
            document.querySelector('#overlay-info #overlay-start').style.display = 'block';
            document.querySelector('#overlay-info .overlay-text.entstehung').style.display = 'block';
            document.querySelector('#overlay-info .overlay-text.erstpublikation').style.display = 'none';
            document.querySelector('#overlay-info .overlay-text.anleitung').style.display = 'none';
        } else if (num === '1') {
            document.querySelector('#overlay-info').style.display = 'flex';
            document.querySelector('#overlay-info #overlay-start').style.display = 'block';
            document.querySelector('#overlay-info .overlay-text.entstehung').style.display = 'none';
            document.querySelector('#overlay-info .overlay-text.erstpublikation').style.display = 'block';
            document.querySelector('#overlay-info .overlay-text.anleitung').style.display = 'none';
        } else if (num === '3') {
            document.querySelector('#overlay-info').style.display = 'flex';
            document.querySelector('#overlay-info #overlay-start').style.display = 'block';
            document.querySelector('#overlay-info .overlay-text.entstehung').style.display = 'none';
            document.querySelector('#overlay-info .overlay-text.erstpublikation').style.display = 'none';
            document.querySelector('#overlay-info .overlay-text.anleitung').style.display = 'block';
        } else if (num === 'w1') {
            document.querySelector('#overlay-info').style.display = 'flex';

            var text_popups_anleitung = document.querySelectorAll('#overlay-info .overlay-text.anleitung-w');
            var text_popups_indiv = document.querySelectorAll('#overlay-info .overlay-text.info-wirbel');
            var text_popups_site_inval = document.querySelectorAll('#overlay-info .overlay-text.site-inval');
            text_popups_anleitung.forEach((element) => element.style.display = 'block');
            text_popups_indiv.forEach((element) => element.style.display = 'none');
            text_popups_site_inval.forEach((element) => element.style.display = 'none');
        } else if (num === 'w2') {
            document.querySelector('#overlay-info').style.display = 'flex';

            var text_popups_anleitung = document.querySelectorAll('#overlay-info .overlay-text.anleitung-w');
            var text_popups_indiv = document.querySelectorAll('#overlay-info .overlay-text.info-wirbel');
            var text_popups_site_inval = document.querySelectorAll('#overlay-info .overlay-text.site-inval');
            text_popups_anleitung.forEach((element) => element.style.display = 'none');
            text_popups_site_inval.forEach((element) => element.style.display = 'none');
            text_popups_indiv.forEach((element) => element.style.display = 'block');
        } else if (num === 'w3') {
            document.querySelector('#overlay-info-big').style.display = 'flex';

            var text_popups_site_inval = document.querySelectorAll('#overlay-info-big .overlay-text.site-inval');
            var text_popups_site_vid = document.querySelectorAll('#overlay-info-big .overlay-text.site-vid');
            
            text_popups_site_vid.forEach((element) => element.style.display = 'none');
            text_popups_site_inval.forEach((element) => element.style.display = 'block');
        }else if (num === 'w4') {
            document.querySelector('#overlay-info-big').style.display = 'flex';

            var text_popups_site_inval = document.querySelectorAll('#overlay-info-big .overlay-text.site-inval');
            var text_popups_site_vid = document.querySelectorAll('#overlay-info-big .overlay-text.site-vid');
            
            text_popups_site_vid.forEach((element) => element.style.display = 'block');
            text_popups_site_inval.forEach((element) => element.style.display = 'none');
        } else if (num === 'close') {
            document.querySelector('#overlay-info').style.display = 'none';
            document.querySelector('#overlay-info-big').style.display = 'none';
            document.querySelector('#overlay-info #overlay-start').style.display = 'none';
            document.querySelector('#overlay-info-imgs').style.display = 'none';
            document.querySelector('#overlay-erstp').style.display = 'none';
            document.querySelector('#overlay-fussnote').style.display = 'none';
        } else if (num === 'finger1') {
            document.querySelector('#overlay-info-imgs').style.display = 'flex';
            document.getElementById('image-big').src = finger1_big;
            document.querySelector('#overlay-info-imgs .szene-versteifung .change-text').innerText = 'Fingergelenke Versteifung';
        } else if (num === 'finger2') {
            document.querySelector('#overlay-info-imgs').style.display = 'flex';
            document.getElementById('image-big').src = finger2_big;
            document.querySelector('#overlay-info-imgs .szene-versteifung .change-text').innerHTML = 'Fingergelenke Versteifung';
        } else if (num === 'daumen1') {
            document.querySelector('#overlay-info-imgs').style.display = 'flex';
            document.getElementById('image-big').src = daumen1_big;
            document.querySelector('#overlay-info-imgs .szene-versteifung .change-text').innerText = 'Daumengelenke Versteifung';
        } else if (num === 'daumen2') {
            document.querySelector('#overlay-info-imgs').style.display = 'flex';
            document.getElementById('image-big').src = daumen2_big;
            document.querySelector('#overlay-info-imgs .szene-versteifung .change-text').innerText = 'Daumengelenke Versteifung';
        } else if (num === 'hand-norm-img1') {
            document.querySelector('#overlay-info-imgs').style.display = 'flex';
            document.getElementById('image-big2').src = hand_norm_img1;
            document.querySelector('#overlay-info-imgs .szene-funktionsstoerung .change-text').innerText = 'Normbewegung Handgelenk';
        } else if (num === 'hand-norm-img2') {
            document.querySelector('#overlay-info-imgs').style.display = 'flex';
            document.getElementById('image-big2').src = hand_norm_img2;
            document.querySelector('#overlay-info-imgs .szene-funktionsstoerung .change-text').innerText = 'Normbewegung Handgelenk';
        } else if (num === 'elle-norm-img') {
            document.querySelector('#overlay-info-imgs').style.display = 'flex';
            document.getElementById('image-big2').src = elle_norm_img;
            document.querySelector('#overlay-info-imgs .szene-funktionsstoerung .change-text').innerText = 'Normbewegung Ellenbogen';
        } else if (num === 'schult-norm-img') {
            document.querySelector('#overlay-info-imgs').style.display = 'flex';
            document.getElementById('image-big2').src = schult_norm_img;
            document.querySelector('#overlay-info-imgs .szene-funktionsstoerung .change-text').innerText = 'Normbewegung Schultergelenk';
        } else if (num === 'verst-fuß') {
            document.querySelector('#overlay-info-imgs').style.display = 'flex';
            document.getElementById('image-big').src = versteifung_fußgelenk_img;
            document.querySelector('#overlay-info-imgs .szene-versteifung-bein .change-text').innerText = 'Normbewegung Schultergelenk';
        } else if (num === 'funk-huft') {
            document.querySelector('#overlay-info-imgs').style.display = 'flex';
            document.getElementById('image-big-funk-bein').src = GrafikNormbewegl_Hüfte_img;
            document.querySelector('#overlay-info-imgs .szene-funktionsstoerung-bein .change-text').innerText = 'Normbewegung Hüftgelenk';
        } else if (num === 'funk-knieinst1') {
            document.querySelector('#overlay-info-imgs').style.display = 'flex';
            document.getElementById('image-big-funk-bein').src = funkst_bein_knieinst1;
            document.querySelector('#overlay-info-imgs .szene-funktionsstoerung-bein .change-text').innerText = 'Kniegelenk Instabilität Vorne';
        } else if (num === 'funk-knieinst2') {
            document.querySelector('#overlay-info-imgs').style.display = 'flex';
            document.getElementById('image-big-funk-bein').src = funkst_bein_knieinst2;
            document.querySelector('#overlay-info-imgs .szene-funktionsstoerung-bein .change-text').innerText = 'Kniegelenk Instabilität Hinten';
        } else if (num === 'funk-knieinst3') {
            document.querySelector('#overlay-info-imgs').style.display = 'flex';
            document.getElementById('image-big-funk-bein').src = funkst_bein_knieinst3;
            document.querySelector('#overlay-info-imgs .szene-funktionsstoerung-bein .change-text').innerText = 'Kniegelenk Instabilität Innen';
        } else if (num === 'funk-knieinst4') {
            document.querySelector('#overlay-info-imgs').style.display = 'flex';
            document.getElementById('image-big-funk-bein').src = funkst_bein_knieinst4;
            document.querySelector('#overlay-info-imgs .szene-funktionsstoerung-bein .change-text').innerText = 'Kniegelenk Instabilität Außen';
        } else if (num === 'funk-fuß') {
            document.querySelector('#overlay-info-imgs').style.display = 'flex';
            document.getElementById('image-big-funk-bein').src = GrafikNormbewegl_Sprunggelenk;
            document.querySelector('#overlay-info-imgs .szene-funktionsstoerung-bein .change-text').innerText = 'Normbewegung Sprunggelenk';
        } else if (num === 'show-overlay-erstp') {
            document.querySelector('#overlay-erstp').style.display = 'flex';
        } else if (num === 'fussnote') {
            document.querySelector('#overlay-fussnote').style.display = 'flex';
        }
    };

    const show_buttons_funk = (event) => {
        let num = event.target.getAttribute('data-arg4');
        var armwert_anzeige = document.getElementById('wert-container');
        var all_buttons_verst = document.querySelectorAll('.side-menu>ul>li>ul>li');

        document.querySelector('#button-table-1').style.display = 'block';
        document.querySelector('#button-table-2').style.display = 'none';

        if (num === '1') {
            armwert_anzeige.style.display = 'none';
            spline.current.emitEvent('mouseDown', '848e79dd-937e-4036-b204-35d2482c69c0');
            document.querySelector('#overlay').style.display = 'none';
            document.getElementById('hint-hand-funk').style.display = 'none';
            document.querySelector('.overview').classList.remove('mobile-small-size');
        } else if (num === '2') {
            armwert_anzeige.style.display = 'none';
            spline.current.emitEvent('mouseDown', '153c1836-9e05-4b0f-b119-f0e06c3f4759');
            document.querySelector('#overlay').style.display = 'none';
            document.getElementById('hint-hand-funk').style.display = 'none';
        } else if (num === '3') {
            armwert_anzeige.style.display = 'none';
            spline.current.emitEvent('mouseDown', '33375321-79b5-476c-af0c-e55a9ac4747b');
            document.querySelector('#overlay').style.display = 'none';
            document.getElementById('hint-hand-funk').style.display = 'block';
            document.querySelector('.overview').classList.remove('mobile-small-size');
            document.getElementById('hint-hand-funk').firstChild.innerHTML = 'Klicken Sie auf ein Winkelmaß der Funktions&shy;beeinträchtigung, um den visualisierten Wert anzuzeigen.';
        } else if (num === '4') {
            armwert_anzeige.style.display = 'none';
            document.querySelector('#overlay').style.display = 'flex';
            document.getElementById('hint-hand-funk').style.display = 'none';
            document.getElementById('funk-4').classList.add('active');
            document.getElementById('funk-2').classList.remove('active');
            document.getElementById('funk-5').classList.remove('active');
            document.getElementById('funk-1').classList.remove('active');
            document.getElementById('funk-3').classList.remove('active');
            document.querySelectorAll('.side-menu>ul>li').forEach(elem => elem.style.display = 'none');
           document.querySelectorAll('.button-table').forEach((element) => {
                element.style.display = 'none'
            });
            document.getElementById('change-head').innerHTML = ' Funktions&shy;beeinträchtigung';
            document.querySelector('.change-text-head').innerText = 'Weitere';
        } else if (num === '5') {
            armwert_anzeige.style.display = 'none';
            spline.current.emitEvent('mouseDown', '9994e60b-c292-4888-b319-f61a015e5c93');
            document.querySelector('#overlay').style.display = 'none';
            document.getElementById('hint-hand-funk').style.display = 'none';
            document.querySelector('.overview').classList.remove('mobile-small-size');
        } else if (num === '3fb') {
            armwert_anzeige.style.display = 'none';
            all_buttons_verst.forEach(elem => {
                elem.classList.remove('active')
            });
            spline.current.emitEvent('mouseDown', '9a9fe032-3204-4b97-bbed-a23844fcb68d');
        } else if (num === '2fb') {
            armwert_anzeige.style.display = 'none';
            all_buttons_verst.forEach(elem => {
                elem.classList.remove('active')
            });
            spline.current.emitEvent('mouseDown', '8ebf602c-c8bf-49ab-8407-8165fbfa1db0');
        } else if (num === '1fb') {
            armwert_anzeige.style.display = 'none';
            all_buttons_verst.forEach(elem => {
                elem.classList.remove('active')
            });
            spline.current.emitEvent('mouseDown', '1616af6a-4d09-43a3-9710-14a20f7aea06');
        } else if (num === '4fb') {
            armwert_anzeige.style.display = 'none';
            all_buttons_verst.forEach(elem => {
                elem.classList.remove('active')
            });
            spline.current.emitEvent('mouseDown', '170385b0-862f-4526-8ec5-8e11251e5f25');
        }
    };

    const show_buttons = (event) => {
        let num = event.target.getAttribute('data-arg1');

        document.getElementById('show-daumen').style.display = 'none';
        document.getElementById('show-finger').style.display = 'none';

        var armwert = document.getElementById('armwert');
        var armwert_anzeige = document.getElementById('wert-container');

        var armwert_bez = document.getElementById('armwert-bez');
        var daumen_bilder = document.getElementById('daumen-bilder');
        var finger_bilder = document.getElementById('finger-bilder');
        var daumen_bilder_u = document.getElementById('show-daumen');
        var finger_bilder_u = document.getElementById('show-finger');

        if (num === '1') {
            //Fingergelenke
            if (armwert_anzeige !== null) {
                armwert_anzeige.style.display = 'none';
            }
            if (finger_bilder !== null) {
                daumen_bilder.style.display = 'none';
                finger_bilder.style.display = 'flex';
                daumen_bilder_u.style.display = 'none';
                finger_bilder_u.style.display = 'flex';
            }
            if (screen_small.matches) {
                document.querySelector('#overlay-info-imgs').style.display = 'flex';
                document.getElementById('image-big').src = finger1_big;
                document.querySelector('#overlay-info-imgs .szene-versteifung .change-text').innerText = 'Fingergelenke Versteifung';
            }
            spline.current.emitEvent('mouseDown', '416a01f7-4cbb-4dcd-bda6-3b4430b21291');
        } else if (num === '2') {
            //Handgelenk
            spline.current.emitEvent('mouseDown', '7c3e50d9-686f-4104-9628-8ff63c833d6c');
        } else if (num === '3') {
            //Ellenbogengelenk
            spline.current.emitEvent('mouseDown', 'ee0c534c-8502-407e-88ac-9c1677d49053');
        } else if (num === '4') {
            //Schultergelenk
            spline.current.emitEvent('mouseDown', '8a4bb91c-29ab-45e1-b4bf-3747528a018f');
        } else if (num === '5') {
            //Unterarmdrehung
            spline.current.emitEvent('mouseDown', 'c1dccd1b-9786-4026-99c8-1b51f12f878f');
            /* weitere Versteifung
            document.querySelector('#overlay').style.display = 'flex';
            document.querySelector('#overlay-img-verst-container').style.display = 'none';
            document.getElementById('verst-5').classList.add('active');
            document.getElementById('verst-2').classList.remove('active');
            document.getElementById('verst-1').classList.remove('active');
            document.getElementById('verst-4').classList.remove('active');
            document.getElementById('verst-3').classList.remove('active');
            document.querySelectorAll('.side-menu>ul>li').forEach(elem => elem.style.display = 'none');
            document.getElementById('change-head').innerText = ' Versteifung';
            document.querySelector('.change-text-head').innerText = 'Weitere';*/
        } else if (num === '1b') {
            armwert_anzeige.style.display = 'block';
            armwert.innerText = '8/20';
            armwert_bez.innerText = 'Großzehenwert:';
            spline.current.emitEvent('mouseDown', '988881cd-6a1d-453c-b3e4-9ccb06e00ef4');
            document.querySelector('#overlay').style.display = 'none';
            document.querySelector('.overview').classList.add('mobile-small-size');
        } else if (num === '2b') {
            armwert_anzeige.style.display = 'block';
            armwert.innerText = '6/20';
            armwert_bez.innerText = 'Fußwert:';
            spline.current.emitEvent('mouseDown', '0ee5e110-3b98-4347-8ae2-26329de0623b');
            document.querySelector('#overlay').style.display = 'none';
            document.querySelector('.overview').classList.remove('mobile-small-size');
            document.querySelector('.side-menu>ul>li:nth-of-type(12) .imgs img').style.visibility = 'visible';
        } else if (num === '3b') {
            armwert_anzeige.style.display = 'block';
            armwert.innerText = '8/20';
            armwert_bez.innerText = 'Beinwert:';
            spline.current.emitEvent('mouseDown', '4c43c141-3dd5-4ae4-9aeb-7248ee9c95db');
            document.querySelector('#overlay').style.display = 'none';
            document.querySelector('.overview').classList.remove('mobile-small-size');
        } else if (num === '4b') {
            armwert_anzeige.style.display = 'block';
            armwert.innerText = '10/20';
            armwert_bez.innerText = 'Beinwert:';
            spline.current.emitEvent('mouseDown', '9c0c6bf8-e299-4036-92ee-dfe77c5d586f');
            document.querySelector('#overlay').style.display = 'none';
            document.querySelector('.overview').classList.remove('mobile-small-size');
        }
    };

    const changeSceneFuntionsstoerung = (event) => {
        let scene_id = event.target.getAttribute('cs');
        document.querySelector('#change-head').innerText = '';
        document.querySelector('.change-text-head').innerHTML = 'Funktions&shy;beeinträchtigung';

        spline.current.emitEvent('mouseDown', scene_id);
    };

    const changeScene = (event) => {
        let scene_id = event.target.getAttribute('cs');

        spline.current.emitEvent('mouseDown', scene_id);
    };

    const changeSceneVersteifung = (event) => {
        let scene_id = event.target.getAttribute('cs');
        document.querySelector('#change-head').innerText = '';
        document.querySelector('.change-text-head').innerText = 'Versteifung';

        spline.current.emitEvent('mouseDown', scene_id);
    };

    const resetScene = (event) => {
        let scene_id = event.target.getAttribute('cs');

        spline.current.emitEvent('mouseDown', scene_id);


        if (document.getElementById('hint-hand-funk')) {
            document.getElementById('hint-hand-funk').style.display = 'none';
        }
        var side_menu_items = document.querySelectorAll('.side-menu>ul>li');
        if (side_menu_items !== null) {
            side_menu_items.forEach(elem => elem.style.display = 'none');
        }
        if (document.querySelector('.overlay') !== null) {
            document.querySelector('.overlay').style.display = 'none';
        }
        if (document.querySelector('.sub-menu') !== null) {
            document.querySelectorAll('.sub-menu ul li').forEach(elem => elem.classList.remove('active'));
        }
        if (document.getElementById('wert-container') !== null) {
            document.getElementById('wert-container').style.display = 'none';
        }
        if (document.getElementById('overlay-img-verst-container') !== null) {
            document.getElementById('overlay-img-verst-container').style.display = 'none';
        }
        if (document.getElementById('show-finger') !== null) {
            document.getElementById('show-finger').style.display = 'none';
        }
        if (document.getElementById('show-daumen') !== null) {
            document.getElementById('show-daumen').style.display = 'none';
        }
    };


    const returnUebersicht = (event) => {
        let scene_id = event.target.getAttribute('cs');

        spline.current.emitEvent('mouseDown', scene_id);
    };

    const changeSceneInval = (event) => {
        let scene_id = event.target.getAttribute('cs');
        let scene_id_2 = event.target.getAttribute('ct');

        spline.current.emitEvent('mouseDown', scene_id);
        spline.current.emitEvent('mouseDown', scene_id_2);
    };


    useEffect(() => {
        var mobile_overview = document.querySelector('.mobil-head-content');

        var x = window.matchMedia("(max-width: 1660px)")

        var copyright = document.getElementById('copyright');
        var head_container = document.querySelector('.head-banner');
        var nav_container = document.querySelector('.nav-container');


        if (x.matches) {
            if (mobile_overview) {
                document.querySelector('.head-banner div').appendChild(mobile_overview);
                nav_container.appendChild(copyright);
            }
        } else {
            if (mobile_overview) {
                document.querySelector('.overview').prepend(mobile_overview);
                head_container.appendChild(copyright);
            }
        }


    }, [width]);


    /*

    useEffect(() => {
        // Fetch the user email and token from local storage
        const user = JSON.parse(localStorage.getItem('user'))

        // If the token/email does not exist, mark the user as logged out
        if (!user || !user.token) {
            setLoggedIn(false)
            return
        }

        // If the token exists, verify it with the auth server to see if it is valid
        fetch('https://spline-login-backend.azurewebsites.net/verify', {
            method: 'POST',
            headers: {
                'jwt-token': user.token,
            },
        })
            .then((r) => r.json())
            .then((r) => {
                setLoggedIn('success' === r.message)
                setEmail(user.email || '')
            })
    }, [])*/


    return (<div className="App" id="container">
        <div className="head-banner">
            <div></div>
            <div className="logos">
                <img src={logo} alt="" /><img src={logo_fimb} alt="" />
            </div>
        </div>
        <div className="overview">
            <div className="mobil-head-content">
                <div className="szene szene-start">
                    <div>
                        <h2>Startseite</h2>
                        <ul>
                            <li onClick={show_overlay} data-arg10='1'>Hinweise und Updates
                            </li>
                            <li onClick={show_overlay} data-arg10='2'>Entstehung und Konsentierung
                            </li>
                            <li onClick={show_overlay} data-arg10='3'>Seitenaufbau
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="szene szene-invatar">
                    <div>
                        <h2>Invaliditätswerte</h2>
                        <ul>
                            <li onClick={show_overlay} data-arg10='w1'>Seitenaufbau
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="szene szene-athrose szene szene-prothese">
                    <div>
                        <h2 className="szene szene-athrose" style={{ color: '#DFED86' }}>Posttraumatische
                            Arthrosen</h2>
                        <h2 className="szene szene-prothese" style={{ color: '#FF83FF' }}>Posttraumatische
                            Endoprothesen</h2>
                        <ul>
                            <li onClick={show_overlay} data-arg10='show-overlay-erstp'>Seitenaufbau
                            </li>
                            <li onClick={show_overlay} data-arg10='fussnote'>Weiterführende Informationen und Fußnoten
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="szene szene-arm">
                    <div>
                        <div>
                            <h2>Invaliditätswerte</h2>
                            <h3 className="color-white">Verlust<sup>1</sup> Arm</h3>
                        </div>
                        <ul
                        >
                            <li onClick={show_overlay} data-arg10='w1'>Seitenaufbau
                            </li>
                            <li onClick={show_overlay} data-arg10='fussnote'>Weiterführende Informationen und Fußnoten
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="szene szene-hand">
                    <div>
                        <div>
                            <h2>Invaliditätswerte</h2>
                            <h3 className="color-white">Verlust<sup>1</sup> Hand</h3>
                        </div>
                        <ul>
                            <li onClick={show_overlay} data-arg10='w1'>Seitenaufbau
                            </li>
                            <li onClick={show_overlay} data-arg10='fussnote'>Weiterführende Informationen und Fußnoten
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="szene szene-bein">
                    <div>
                        <div>
                            <h2>Invaliditätswerte</h2>
                            <h3 className="color-white">Bein Verlust<sup>1</sup></h3>
                        </div>
                        <ul>
                            <li onClick={show_overlay} data-arg10='w1'>Seitenaufbau
                            </li>
                            <li onClick={show_overlay} data-arg10='fussnote'>Weiterführende Informationen und Fußnoten
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="szene szene-fuß">
                    <div>
                        <div>
                            <h2>Invaliditätswerte</h2>
                            <h3 className="color-white">Fuß Verlust<sup>1</sup></h3>
                        </div>
                        <ul>
                            <li onClick={show_overlay} data-arg10='w1'>Seitenaufbau
                            </li>
                            <li onClick={show_overlay} data-arg10='fussnote'>Weiterführende Informationen und Fußnoten
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="szene szene-wirbelsaeule">
                    <div>
                        <div>
                            <h2>Invaliditätswerte</h2>
                            <h3 className="color-white">Wirbelsäule</h3>
                        </div>
                        <ul>
                            <li onClick={show_overlay} data-arg10='w1'>Seitenaufbau
                            </li>
                            <li onClick={show_overlay} data-arg10="fussnote">Weiterführende Informationen und Fußnoten</li>
                            <li onClick={show_overlay} data-arg10='w2'
                                className={'info-button-organge'}>Informationstext
                            </li>
                            <li onClick={show_overlay} data-arg10='w3'>Alle
                                Informationen
                                zur
                                Invalidität der Wirbelsäule</li>
                            <li onClick={show_overlay} data-arg10='w4'>Videoerläuterung zum Δ GDW</li>
                        </ul>
                    </div>
                </div>
                <div className="szene szene-brustkorb">
                    <div>
                        <div>
                            <h2>Invaliditätswerte</h2>
                            <h3 className="color-white">Brustkorb</h3>
                        </div>
                        <ul>
                            <li onClick={show_overlay} data-arg10='w1'>Seitenaufbau
                            </li>
                            <li onClick={show_overlay} data-arg10='fussnote'>Weiterführende Informationen und Fußnoten
                            </li>
                            <li onClick={show_overlay} data-arg10='w2'
                                className={'info-button-organge'}>Informationstext
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="szene szene-becken">
                    <div>
                        <div>
                            <h2>Invaliditätswerte</h2>
                            <h3 className="color-white">Becken</h3>
                        </div>
                        <ul>
                            <li onClick={show_overlay} data-arg10='w1'>Seitenaufbau
                            </li>
                            <li onClick={show_overlay} data-arg10='fussnote'>Weiterführende Informationen und Fußnoten
                            </li>
                            <li onClick={show_overlay} data-arg10='w2'
                                className={'info-button-organge'}>Informationstext
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="szene szene-bauchdecke">
                    <div>
                        <div>
                            <h2>Invaliditätswerte</h2>
                            <h3 className="color-white">Bauchdecke</h3>
                        </div>
                        <ul>
                            <li onClick={show_overlay} data-arg10='w1'>Seitenaufbau
                            </li>
                            <li onClick={show_overlay} data-arg10='fussnote'>Weiterführende Informationen und Fußnoten
                            </li>
                            <li onClick={show_overlay} data-arg10='w2'
                                className={'info-button-organge'}>Informationstext
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="szene szene-integument">
                    <div>
                        <div>
                            <h2>Invaliditätswerte</h2>
                            <h3 className="color-white">Integument</h3>
                        </div>
                        <ul>
                            <li onClick={show_overlay} data-arg10='w1'>Seitenaufbau
                            </li>
                            <li onClick={show_overlay} data-arg10='fussnote'>Weiterführende Informationen und Fußnoten
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="szene szene-laengen-achsen">
                    <div>
                        <div>
                            <h2>Invaliditätswerte</h2>
                            <h3 className="color-white">Längen- Achsenabweichung</h3>
                        </div>
                        <ul>
                            <li onClick={show_overlay} data-arg10='w1'>Seitenaufbau
                            </li>
                            <li onClick={show_overlay} data-arg10='fussnote'>Weiterführende Informationen und Fußnoten
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="szene szene-thrombose">
                    <div>
                        <div>
                            <h2>Invaliditätswerte</h2>
                            <h3 className="color-white">Thrombosefolgen<br></br> / Lymphödeme</h3>
                        </div>
                        <ul>
                            <li onClick={show_overlay} data-arg10='w1'>Seitenaufbau
                            </li>
                            <li onClick={show_overlay} data-arg10='fussnote'>Weiterführende Informationen und Fußnoten
                            </li>
                        </ul>
                    </div>
                </div>
                <div
                    className="szene szene-versteifung szene-funktionsstoerung szene szene-versteifung-bein szene-funktionsstoerung-bein">
                    <div>
                        <div>
                            <h2>Invaliditätswerte</h2>
                            <h3 className="color-white"><span
                                className="change-text-head">Versteifung</span><span
                                    id="change-head"> Bein & Fuß</span></h3>
                        </div>
                        <ul>
                            <li onClick={show_overlay} data-arg10='w1'>Seitenaufbau
                            </li>
                            <li onClick={show_overlay} data-arg10='fussnote'>Weiterführende Informationen und Fußnoten
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="werte-box">
                <div className="szene szene-invatar">
                    <ul>
                        <li className="hint"><p>Klicken Sie auf einen der Orange gekennzeichneten Bereiche oder
                            nutzen Sie die Navigationsleiste unten</p></li>
                    </ul>
                </div>
                <div className="szene szene-arm">
                    <ul>
                        <li className="verlust">
                            <p>Verlustwert<sup>2</sup>: <span id="verlust">-</span></p>
                            <p id="verlust-zusatz"></p>
                        </li>
                        <li className="hint attach"><p>Fahren Sie über Teile des Armes, um den entsprechenden
                            Verlustwert anzuzeigen oder klicken Sie auf "Weiter zur Hand", um die Verlustwerte
                            der Hand anzeigen zu lassen.</p></li>
                    </ul>
                </div>
                <div className="szene szene-hand">
                    <ul>
                        <li className="verlust">
                            <p>Verlustwert<sup>2</sup>: <span id="verlust">-</span></p>
                            <p id="verlust-zusatz"></p>
                            <p id="verlust-zusatz2"></p>
                        </li>
                        <li className="hint attach"><p>Fahren Sie über Teile der Hand um den entsprechenden
                            Verlustwert
                            anzuzeigen oder klicken Sie auf "Weiter zum Arm", um die Verlustwerte des Armes
                            anzeigen zu lassen.</p></li>
                    </ul>
                </div>
                <div className="szene szene-bein">
                    <ul>
                        <li className="verlust">
                            <p>Verlustwert<sup>2</sup>: <span id="verlust">-</span></p>
                            <p id="verlust-zusatz"></p>
                        </li>
                        <li className="hint attach"><p>Fahren Sie über Teile des Beines um den entsprechenden
                            Verlustwert anzuzeigen; Oder klicken Sie auf "Weiter zum Fuß", um die Verlustwerte
                            des Fußes anzeigen zu lassen</p></li>
                    </ul>
                </div>
                <div className="szene szene-fuß">
                    <ul>
                        <li className="verlust">
                            <p>Verlustwert<sup>2</sup>: <span id="verlust">-</span></p>
                            <p id="verlust-zusatz"></p>
                            <p id="verlust-zusatz2"></p>
                        </li>
                        <li className="hint attach"><p>Fahren Sie über Teile des Fußes um den entsprechenden
                            Verlustwert
                            anzuzeigen; Oder klicken Sie auf "Weiter zum Bein", um die Verlustwerte des Beins
                            anzeigen zu lassen</p></li>
                    </ul>
                </div>
                <div className="szene szene-wirbelsaeule">
                    <ul>
                        <li className="hint"><p>Fahren Sie über die eingefärbten Bereiche um die jeweiligen
                            Informationen einblenden zu lassen. Weiterführende Informationen zur Invalidität der
                            Wirbelsäule finden Sie unter dem Button „Alle Informationen zur Invalidität der
                            Wirbelsäule"</p></li>
                    </ul>
                </div>
                <div
                    className="szene szene-versteifung szene-funktionsstoerung szene-versteifung-bein szene-funktionsstoerung-bein">
                    <ul>
                        <li className="verlust" id="wert-container">
                            <p><span id="armwert-bez">Armwert:</span> <span id="armwert">0/20</span></p>
                        </li>
                        <li className="hint attach" id="hint-hand-funk"><p>Nachdem die Normbeweglichkeiten in allen
                            Gelenken eigentlich Konsens darstellen und in den üblichen Messblättern wiedergespiegelt
                            sind, so stellte sich bei der Norm-handgelenksbeweglichkeit Korretkurbedarf heraus und
                            die
                            Normbeweglichkeit ist zu definieren wie folgt.</p></li>
                        <li id="show-daumen">
                            <img onClick={show_overlay} data-arg10='daumen2' src={daumen2_big} alt="" />
                            <p className='sub-hint'>Klicken zum Vergrößern</p>
                        </li>
                        <li id="show-finger">
                            <img onClick={show_overlay} data-arg10='finger2' src={finger2_big} alt="" />
                            <p className='sub-hint'>Klicken zum Vergrößern</p>
                        </li>
                        <li className="button-table" id="button-table-1">
                            <p>Bewegungseinschränkung Heben/Senken</p>
                            <table>
                                <tr>
                                    <td> 10 / 0 / 30<sup>1</sup></td>
                                    <td>2/20 Fußwert</td>
                                </tr>
                                <tr>
                                    <td> 10 / 0 / 20<sup>1</sup></td>
                                    <td>3/20 Fußwert</td>
                                </tr>
                                <tr>
                                    <td>0 / 0 / 30</td>
                                    <td>3/20 Fußwert</td>
                                </tr>
                                <tr>
                                    <td>0 / 0 / 20</td>
                                    <td>4/20 Fußwert</td>
                                </tr>
                                <tr>
                                    <td>0 / 0 / 10</td>
                                    <td>5/20 Fußwert</td>
                                </tr>
                                <tr>
                                    <td>0 / 10 / x²</td>
                                    <td> 10/20 Fußwert</td>
                                </tr>
                                <tr>
                                    <td>0 / > 10 / x²</td>
                                    <td>12/20 Fußwert</td>
                                </tr>
                            </table>
                        </li>
                        <li className="button-table" id="button-table-2">
                            <p>Bewegungseinschränkung um</p>
                            <table>
                                <tr>
                                    <td>1/3 der Norm</td>
                                    <td>2/20 Fußwert</td>
                                </tr>
                                <tr>
                                    <td>2/3 der Norm</td>
                                    <td>3/20 Fußwert</td>
                                </tr>
                            </table>
                            <p>Kombinierte Funktionsbeeinträchtigung von oberem und unterem Sprunggelenk sind nicht
                                additiv,
                                sondern subsumierend zu bewerten. Als ein Vergleichswert gilt dabei die Versteifung
                                von
                                oberem und unterem Sprunggelenk mit 9/20 Fußwert.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="side-menu">
            <ul>
                <li>
                    <ul>
                        <li onClick={trigger_buttons_verst} data-arg2='1'>Fingerversteifung in
                            funktionsgünstiger Stellung
                        </li>
                        <li onClick={trigger_buttons_verst} data-arg2='2'>Daumenversteifung in
                            funktionsgünstiger Stellung
                        </li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <p>Handgelenksversteifung in funktionsgünstiger Stellung bei freier Unterarmdrehung:</p>
                        <li onClick={trigger_buttons_verst} data-arg2='3'>Seitkantung ellenwärts 0-10°
                        </li>
                        <li onClick={trigger_buttons_verst} data-arg2='4'>nach handrückenwärts 10-15°
                        </li>
                        <p>Handgelenksversteifung in funktionsgünstiger Stellung:</p>
                        <li onClick={trigger_buttons_verst} data-arg2='22'>Bei Aufhebung der Unterarmdrehung in
                            Einwärtsdrehung
                        </li>
                        <li onClick={trigger_buttons_verst} data-arg2='23'>Bei Aufhebung der Unterarmdrehung in
                            Neutral-0-Stellung<sup>1</sup>
                        </li>
                        <li onClick={trigger_buttons_verst} data-arg2='24'>Bei Aufhebung der Unterarmdrehung in
                            Auswärtsdrehung
                        </li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <p>Versteifung in funktionsgünstiger Stellung:</p>
                        <li onClick={trigger_buttons_verst} data-arg2='5'>In Frontalebene 20° seitlich abgehoben
                        </li>
                        <li onClick={trigger_buttons_verst} data-arg2='6'>In Sagittalebene 20°-30° nach vorn
                            abgehoben
                        </li>
                        <li onClick={trigger_buttons_verst} data-arg2='7'>In Transversalebene 40°-45° innenrotiert
                        </li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <p>Ellenbogenversteifung in funktionsgünstiger Stellung:</p>
                        <li onClick={trigger_buttons_verst} data-arg2='8'>bei freier Unterarmdrehung
                        </li>
                        <li onClick={trigger_buttons_verst} data-arg2='9'>Bei Aufhebung der Unterarmdrehung in
                            Einwärtsdrehung¹
                        </li>
                        <li onClick={trigger_buttons_verst} data-arg2='10'>Bei Aufhebung der Unterarmdrehung in
                            Neutral-0-Stellung¹
                        </li>
                        <li onClick={trigger_buttons_verst} data-arg2='11'>Bei Aufhebung der Unterarmdrehung in
                            Auswärtsdrehung
                        </li>
                    </ul>
                </li>
                <li id="finger-bilder">
                    <p>Versteifung in funktionsgünstiger Stellung</p>
                    <img onClick={show_overlay} data-arg10='finger1' src={finger1} alt="" />
                    <p className='sub-hint'>Klicken zum Vergrößern</p>
                </li>
                <li id="daumen-bilder">
                    <p>Versteifung in funktionsgünstiger Stellung</p>
                    <img onClick={show_overlay} data-arg10='daumen1' src={daumen1} alt="" />
                    <p className='sub-hint'>Klicken zum Vergrößern</p>
                </li>
                <li>
                    <ul>
                        <li className="funk-norm-img">
                            <img onClick={show_overlay} data-arg10='hand-norm-img1'
                                src={hand_norm_img1} alt="" />
                            <img onClick={show_overlay} data-arg10='hand-norm-img2'
                                src={hand_norm_img2} alt="" />
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='5'>Normbewegung</li>
                        <li onClick={trigger_buttons_funk} data-arg3='6'>Konzentrische
                            Bewegungseinschränkung um drei Viertel der Norm
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='7'>Konzentrische
                            Bewegungseinschränkung um zwei Viertel der Norm
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='8'>Konzentrische
                            Bewegungseinschränkung um ein Viertel der Norm
                        </li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li className="funk-norm-img">
                            <img onClick={show_overlay} data-arg10='elle-norm-img'
                                src={elle_norm_img} alt="" />
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='22'>Normbeweglichkeit 10 / 0 / 135</li>
                        <li onClick={trigger_buttons_funk} data-arg3='10'>0° / 20-30° / 90°</li>
                        <li onClick={trigger_buttons_funk} data-arg3='11'>0° / 0° / 90°</li>
                        <li onClick={trigger_buttons_funk} data-arg3='12'>0° / 20-30° / 120°</li>
                        <li onClick={trigger_buttons_funk} data-arg3='13'>0° / 0° /120°</li>
                        <li onClick={trigger_buttons_funk} data-arg3='14'>0° / 20-30° / >120°</li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li className="funk-norm-img">
                            <img onClick={show_overlay} data-arg10='schult-norm-img'
                                src={schult_norm_img} alt="" />
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='15'>Normbeweglichkeit in der Sagittalen 40 / 0
                            / 150-170
                        </li>
                        <p>Verlust der Vorhebefähigkeit über:</p>
                        <li onClick={trigger_buttons_funk} data-arg3='16'>120°</li>
                        <li onClick={trigger_buttons_funk} data-arg3='17'>90°</li>
                        <li onClick={trigger_buttons_funk} data-arg3='18'>60°</li>
                        <li onClick={trigger_buttons_funk} data-arg3='19'>30°</li>
                        <li onClick={trigger_buttons_funk} data-arg3='21'>Aufhebung der Rückführfähigkeit des Armes
                            aus der Neutralstellung
                        </li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li onClick={trigger_buttons_verst} data-arg2='vb1' className="active">Sagittalebene
                        </li>
                        <li onClick={trigger_buttons_verst} data-arg2='vb2'>Transversalebene
                        </li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li onClick={trigger_buttons_verst} data-arg2='vb3' className="active">Sagittalebene
                        </li>
                        <li onClick={trigger_buttons_verst} data-arg2='vb4'>Frontallebene
                        </li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li className="imgs"><img onClick={show_overlay} data-arg10='verst-fuß'
                            src={versteifung_fußgelenk_img} alt="" />
                            <p className='sub-hint'>Klicken zum Vergrößern</p></li>
                        <li onClick={trigger_buttons_verst} data-arg2='vb5'>Oberes Sprunggelenk
                        </li>
                        <li onClick={trigger_buttons_verst} data-arg2='vb6'>Unteres Sprunggelenk
                        </li>
                        <li onClick={trigger_buttons_verst} data-arg2='vb7'>oberes und unteres Sprunggelenk
                        </li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <p>Großzehengrundversteifung</p>
                        <li onClick={trigger_buttons_verst} data-arg2='vb8'>funktionsgünstige Versteifung mit suffizientem Bodenkontakt bei gebeugtem Endgelenk
                        </li>
                        <li onClick={trigger_buttons_verst} data-arg2='vb9'>Versteifung in
                            funktionsungünstiger Stellung: Neutral-0-Stellung
                        </li>
                        <li onClick={trigger_buttons_verst} data-arg2='vb10'>Versteifung in
                            funktionsungünstiger Stellung: Beugestellung
                        </li>
                        <p>Versteifung andere Zehe</p>
                        <li onClick={trigger_buttons_verst} data-arg2='vb11'>Versteifung in Neutralstellung
                        </li>
                        <li onClick={trigger_buttons_verst} data-arg2='vb12'>Versteifung in Fehlstellung
                        </li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li className="imgs" id="img-norm-huefte"><img onClick={show_overlay} data-arg10='funk-huft'
                            src={GrafikNormbewegl_Hüfte_img} alt="" />
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='fb1'>Normbeweglichkeit
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='fb2'>Streckdefizit > 5° bis ≤ 10°
                        </li>
                        <p>Hüftgelenk Beugelimitierung bis</p>
                        <li onClick={trigger_buttons_funk} data-arg3='fb6'>120°
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='fb5'>90°
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='fb4'>60°
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='fb3'>30°
                        </li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li onClick={trigger_buttons_funk} data-arg3='fb16'>Normbeweglichkeit</li>
                        <p>Kniegelenk Streckdefizit</p>
                        <li onClick={trigger_buttons_funk} data-arg3='fb7'>>5° - 10°
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='fb8'>> 10° - 15°
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='fb9'>>15° - 20°
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='fb10'>> 20°
                        </li>
                        <p>Kniegelenk Beugdefizit</p>
                        <li onClick={trigger_buttons_funk} data-arg3='fb11'>90°
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='fb12'>60°
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='fb13'>30°
                        </li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <p>Kniegelenk Instabilität<sup>1</sup></p>
                        <li onClick={trigger_buttons_funk} data-arg3='knieinst1'>leichtgradig<sup>2</sup> eindimensional
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='knieinst2'>leichtgradig<sup>2</sup> zweidimensional
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='knieinst3'>mittelgradig<sup>2</sup> eindimensional
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='knieinst4'>mittelgradig<sup>2</sup> zweidimensional
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='knieinst5'>hochgradig<sup>2</sup> eindimensional
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='knieinst6'>hochgradig<sup>2</sup> zweidimensional
                        </li>

                    </ul>
                </li>
                <li>
                    <ul>
                        <li className="imgs"><p>Normbeweglichkeit</p><img onClick={show_overlay}
                            data-arg10='funk-fuß'
                            src={GrafikNormbewegl_Sprunggelenk}
                            alt="" /></li>
                        <li onClick={trigger_buttons_funk} data-arg3='fb14'>oberes Sprunggelenk
                        </li>
                        <li onClick={trigger_buttons_funk} data-arg3='fb15'>unteres Sprunggelenk
                        </li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <p>Isolierte Aufhebung der Unterarmdrehung<sup>1</sup>:</p>
                        <li onClick={trigger_buttons_verst} data-arg2='12'>in Auswärtsdrehung<sup>2</sup></li>
                        <li onClick={trigger_buttons_verst} data-arg2='13'>in Neutral-0-Stellung<sup>2</sup></li>
                        <li onClick={trigger_buttons_verst} data-arg2='14'>in Einwärtsdrehung<sup>2</sup></li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <p>Einschränkung der Unterarmdrehung auswärts/einwärts [1,2]</p>
                        <li onClick={trigger_buttons_funk} data-arg3='25'>45° - 0° - 45°<sup>3</sup></li>
                        <li onClick={trigger_buttons_funk} data-arg3='23'>90° - 0 - 45°<sup>3</sup></li>
                        <li onClick={trigger_buttons_funk} data-arg3='24'>45° - 0° - 90°<sup>3</sup></li>
                    </ul>
                </li>
            </ul>
        </div>
        <div className="spline-scene">
            <Spline scene="30.07..splinecode"
                onStart={startTrigger}
                onLoad={onLoad}
                onMouseHover={onMouseHover} onMouseDown={onMouseDown} />
            <div className="overlay-info" id="overlay-info-loading">
                <div className="overlay">
                    <div className="login-container">
                        <p className="loadin-hint">(Die Seite wird im Hintergrund geladen und ist fertig sobald
                            der
                            "Jetzt Starten" Button
                            orange leuchtet)</p>
                        <button className="start-button" id="start-button" onClick={startScene}
                            value='start'>Jetzt
                            Starten
                        </button>
                        {!loggedIn && <Login setLoggedIn={setLoggedIn} setEmail={setEmail} />}
                    </div>
                    <div className={'scrollContainer'}>
                        <div>
                            <h2>Anpassungen im Vergleich zur Vorgängerversion</h2>
                            <p className='lastChange'>letzte Änderung: 21.08.2024</p>

                            <div className={'updateTable'}>
                                <table className={'borderBox'}>
                                    <tr>
                                        <th></th>
                                        <th>Alte Version</th>
                                        <th>Aktuelle Version (v1.0):</th>
                                    </tr>
                                    <tr>
                                        <th>Neu aufgenommen wurden Werte der Einschränkung der Unterarmdrehung auswärts/einwärts auf:</th>
                                    </tr>
                                    <tr>
                                        <td>45 – 0 – 45</td>
                                        <td>-</td>
                                        <td>Handwert: <br />2/20</td>
                                    </tr>
                                    <tr>
                                        <td> 90 – 0 – 45</td>
                                        <td>-</td>
                                        <td>Handwert: <br />2/20</td>
                                    </tr>
                                    <tr>
                                        <td>45 – 0 – 90</td>
                                        <td>-</td>
                                        <td>Handwert: <br />1/20</td>
                                    </tr>
                                    <tr>
                                        <th>Erhöhung des Wertes, da in der Erstpublikation Ausgleichsbewegungen
                                            eingerechnet wurden:</th>
                                    </tr>
                                    <tr>
                                        <td>Ellenbogenversteifung in Neutral-0-Stellung</td>
                                        <td>Armwert: <br />6/20</td>
                                        <td>Armwert: <br />8/20</td>
                                    </tr>
                                    <tr>
                                        <td>Aufhebung der Unterarmdrehung bei versteiftem Ellenbogen in Neutral-0-Stellung</td>
                                        <td>Armwert: <br />6/20</td>
                                        <td>Armwert: <br />8/20</td>
                                    </tr>
                                    <tr>
                                        <td>Aufhebung der Unterarmdrehfähigkeit bei Handgelenksversteifung in Neutral-0-Stellung</td>
                                        <td>Handwert: <br />8/20</td>
                                        <td>Handwert: <br />9/20</td>
                                    </tr>
                                    <tr>
                                        <th>Invaliditätswert für Versteifung Grund- und Sattelgelenk im Vergleich zur Erstpublikation geändert, da die Funktionsbeeinträchtigung geringer ist als die Daumenamputation aber schlechter als Handgelenksversteifung</th>
                                    </tr>
                                    <tr>
                                        <td>Versteifung Daumensattel- und -grundgelenk</td>
                                        <td>Handwert: <br />8/20</td>
                                        <td>Handwert: <br />6/20</td>
                                    </tr>
                                    <tr>
                                        <th>Wert wurde von ursprünglich 6 auf 4/20 reduziert, da die Versteifung des Handgelenks in funktionsgünstiger Stellung zu größeren Funktions&shy;beeinträchtigungen führt. Die Bemessung erfolgt nach Hand- und nicht Daumenwert, da globale Handfunktionen betroffen sind, die sich allein im Daumenwert nicht abbilden lassen.</th>
                                    </tr>
                                    <tr>
                                        <td>Versteifung Daumensattelgelenk</td>
                                        <td>Handwert: <br />6/20</td>
                                        <td>Handwert: <br />4/20</td>
                                    </tr>
                                    <tr>
                                        <th>Hier kommt es bei subsumierender Betrachtung zu einer Potenzierung des Invaliditätswertes über die reine Addition hinaus, da grundlegende Greiffunktionen beeinträchtigt sind.</th>
                                    </tr>
                                    <tr>
                                        <td>Versteifung Daumengrund- und -endgelenk</td>
                                        <td>-</td>
                                        <td>Daumenwert: <br />12/20</td>
                                    </tr>
                                    <tr>
                                        <th>erhöhende Korrektur gegenüber Erstpublikation um 1/20 wegen geänderter Normbeweglichkeit</th>
                                    </tr>
                                    <tr>
                                        <td>Funktions&shy;beeinträchtigung Oberes Sprungelenk (10 / 0 / 30)</td>
                                        <td>Fußwert: <br />1/20</td>
                                        <td>Fußwert: <br />2/20</td>
                                    </tr>
                                    <tr>
                                        <td>Funktions&shy;beeinträchtigung Oberes Sprungelenk (10 / 0 / 20)</td>
                                        <td>Fußwert: <br />2/20</td>
                                        <td>Fußwert: <br />3/20</td>
                                    </tr>
                                    <tr>
                                        <th>Die nach eigenen Literaturrecherchen definierten Normbeweglichkeiten von Hand- und oberem Sprunggelenk wurden vom Herausgeber der Messblätter (DGUV) zwar als korrekt eingeordnet, wegen fehlender MdE-Relevanz aber nicht in die Messblätter aufgenommen. Das hat zur Folge, dass am Sprunggelenk eine Korrektur der Invaliditätswerte erforderlich war mit Erhöhung um je 1/20 Fuß.</th>
                                    </tr>
                                </table>
                            </div>

                        </div>
                        <div>
                            <h2>Download Konsentierung</h2>
                            <div className={'downloadButton borderBox'}>
                                <p>Die Ergebnisse der Erarbeitung und Konsentierung der Invaliditätswerte in der
                                    privaten Unfallversicherung wurden in „Die Unfallchirurgie“ (vormals „Der
                                    Unfallchirurg“) erstmals publiziert und stehen hier auch zum Download
                                    bereit:</p>
                                <p className="color-orange" style={{ textAlign: 'center' }}>(Download öffnet sich in
                                    einem
                                    separaten Fenster)</p>
                                <a href="https://www.fgimb.de/files/Dokumente/bemessungsempfehlungen/Neue%20Bemessungsempfehlungen%20zur%20Invalidit%C3%A4t%20in%20der%20PUV%20Teil%201.pdf"
                                    className="btn" target="_blank">Download Teil 1</a>
                                <a href="https://www.fgimb.de/files/Dokumente/bemessungsempfehlungen/Neue%20Bemessungsempfehlungen%20zur%20Invalidit%C3%A4t%20in%20der%20PUV%20Teil%202.pdf"
                                    className="btn" target="_blank">Download Teil 2</a>
                                <a href="https://www.fgimb.de/files/Dokumente/bemessungsempfehlungen/Neue%20Bemessungsempfehlungen%20zur%20Invalidit%C3%A4t%20in%20der%20PUV%20Teil%203.pdf"
                                    className="btn" target="_blank">Download Teil 3</a>
                                <a href="https://www.fgimb.de/files/Dokumente/bemessungsempfehlungen/neue-bemessungsempfehlungen-zur-invaliditaet-in-der-puv-teil-4.pdf"
                                    className="btn" target="_blank">Download Teil 4</a>
                            </div>
                        </div>

                        <h2>Anleitung zum Umgang mit der Datenbank<br /> - Erklärung des Seiten Aufbaus -</h2>
                        <div className={'borderBox'}>
                            <img style={{ width: '100%' }} src={erklaerung_img} className="table" alt="" />
                        </div>
                        <h2>Urheberrecht</h2>
                        <p>© Dr. Holm-Torsten Klemm</p>
                    </div>
                    <p id={'version'}>v1.0</p>
                </div>
            </div>
            <div className="overlay-info" id="overlay-info-imgs">
                <div className="overlay szene szene-funktionsstoerung">
                    <p><span>Invaliditätswerte</span> <span
                        className="change-text">??</span></p>
                    <img id="image-big2" src={hand_norm_img1} alt="" />
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-versteifung">
                    <p><span>Invaliditätswerte</span> <span
                        className="change-text">Daumengelenke Versteifung</span></p>
                    <img id="image-big" src={finger1_big} alt="" />
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-funktionsstoerung-bein">
                    <p><span>Invaliditätswerte</span> <span
                        className="change-text">??</span></p>
                    <img id="image-big-funk-bein" src={GrafikNormbewegl_Hüfte_img} alt="" />
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-versteifung-bein">
                    <p><span>Invaliditätswerte</span> <span
                        className="change-text">Daumengelenke Versteifung</span></p>
                    <img id="image-big" src={versteifung_fußgelenk_img} alt="" />
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
            </div>
            <div className="overlay-info" id="overlay-info">
                <div className="overlay szene szene-start" id="overlay-start">
                    <div className="overlay-text entstehung scrollContainer">
                        <h2>Entstehung und Konsentierung der Invaliditätsdatenbank</h2>
                        <div className="two-col">
                            <div>
                                <p>Die Überarbeitung der Bemessungsempfehlungen zur Invalidität in der Privaten
                                    Unfallversicherung wurde erforderlich auf Grund von gesammelten Erkenntnissen
                                    aus
                                    der Anwendung bisheriger Empfehlungen sowie neuer wissenschaftlich-gutachtlicher
                                    Erkenntnisse und unter Berücksichtigung der aktuellen Rechtsprechung. Wie die
                                    Werte
                                    in ihrer Entstehung diskutiert und anhand welcher Argumente und funktionellen
                                    Untersuchungen konsentiert wurden, ist den Erstpublikationen zu entnehmen, die
                                    kostenfrei über den Menüpunkt "Hinweise und Updates" auf Seite 1 zum Download
                                    bereit
                                    stehen.</p>
                            </div>
                            <div>
                                <p>Diese hier vorliegenden Bemessungsempfehlungen werden in Abständen evaluiert und
                                    aktualisiert. Eine Arbeitsgruppe analysiert Hinweise von Anwendern zu
                                    eventuellen
                                    Wertungswidersprüchen und nimmt gegebenenfalls Korrekturen oder Ergänzungen vor
                                    (siehe bitte Menüpunkt "Hinweise und Updates" ).</p>
                            </div>
                        </div>
                        <h2>An der Erarbeitung und Konsentierung beteiligte Fachgesellschaften, Institutionen und
                            Experten</h2>
                        <img src={tabelle} className="table" alt="" />
                    </div>
                    <div className="overlay-text erstpublikation scrollContainer">
                        <div>
                            <h2>Download Konsentierung</h2>
                            <div className={'downloadButton borderBox'}>
                                <p>Die Ergebnisse der Erarbeitung und Konsentierung der Invaliditätswerte in der
                                    privaten Unfallversicherung wurden in „Die Unfallchirurgie“ (vormals „Der
                                    Unfallchirurg“) erstmals publiziert und stehen hier auch zum Download
                                    bereit:</p>
                                <p className="color-orange" style={{ textAlign: 'center' }}>(Download öffnet sich in
                                    einem
                                    separaten Fenster)</p>
                                <a href="https://www.fgimb.de/files/Dokumente/bemessungsempfehlungen/Neue%20Bemessungsempfehlungen%20zur%20Invalidit%C3%A4t%20in%20der%20PUV%20Teil%201.pdf"
                                    className="btn" target="_blank">Download Teil 1</a>
                                <a href="https://www.fgimb.de/files/Dokumente/bemessungsempfehlungen/Neue%20Bemessungsempfehlungen%20zur%20Invalidit%C3%A4t%20in%20der%20PUV%20Teil%202.pdf"
                                    className="btn" target="_blank">Download Teil 2</a>
                                <a href="https://www.fgimb.de/files/Dokumente/bemessungsempfehlungen/Neue%20Bemessungsempfehlungen%20zur%20Invalidit%C3%A4t%20in%20der%20PUV%20Teil%203.pdf"
                                    className="btn" target="_blank">Download Teil 3</a>
                                <a href="https://www.fgimb.de/files/Dokumente/bemessungsempfehlungen/neue-bemessungsempfehlungen-zur-invaliditaet-in-der-puv-teil-4.pdf"
                                    className="btn" target="_blank">Download Teil 4</a>
                            </div>
                        </div>
                        <div>
                            <h2>Anpassungen im Vergleich zur Vorgängerversion</h2>
                            <p className='lastChange'>letzte Änderung: 21.08.2024</p>
                            
                            <div className='updateTable'>
                                <table className={'borderBox'}>
                                    <tr>
                                        <th></th>
                                        <th>Alte Version</th>
                                        <th>Aktuelle Version (v1.0):</th>
                                    </tr>
                                    <tr>
                                        <th>Neu aufgenommen wurden Werte der Einschränkung der Unterarmdrehung auswärts/einwärts auf:</th>
                                    </tr>
                                    <tr>
                                        <td>45 – 0 – 45</td>
                                        <td>-</td>
                                        <td>Handwert: <br />2/20</td>
                                    </tr>
                                    <tr>
                                        <td> 90 – 0 – 45</td>
                                        <td>-</td>
                                        <td>Handwert: <br />2/20</td>
                                    </tr>
                                    <tr>
                                        <td>45 – 0 – 90</td>
                                        <td>-</td>
                                        <td>Handwert: <br />1/20</td>
                                    </tr>
                                    <tr>
                                        <th>Erhöhung des Wertes, da in der Erstpublikation Ausgleichsbewegungen
                                            eingerechnet wurden:</th>
                                    </tr>
                                    <tr>
                                        <td>Ellenbogenversteifung in Neutral-0-Stellung</td>
                                        <td>Armwert: <br />6/20</td>
                                        <td>Armwert: <br />8/20</td>
                                    </tr>
                                    <tr>
                                        <td>Aufhebung der Unterarmdrehung bei versteiftem Ellenbogen in Neutral-0-Stellung</td>
                                        <td>Armwert: <br />6/20</td>
                                        <td>Armwert: <br />8/20</td>
                                    </tr>
                                    <tr>
                                        <td>Aufhebung der Unterarmdrehfähigkeit bei Handgelenksversteifung in Neutral-0-Stellung</td>
                                        <td>Handwert: <br />8/20</td>
                                        <td>Handwert: <br />9/20</td>
                                    </tr>
                                    <tr>
                                        <th>Invaliditätswert für Versteifung Grund- und Sattelgelenk im Vergleich zur Erstpublikation geändert, da die Funktionsbeeinträchtigung geringer ist als die Daumenamputation aber schlechter als Handgelenksversteifung</th>
                                    </tr>
                                    <tr>
                                        <td>Versteifung Daumensattel- und -grundgelenk</td>
                                        <td>Handwert: <br />8/20</td>
                                        <td>Handwert: <br />6/20</td>
                                    </tr>
                                    <tr>
                                        <th>Wert wurde von ursprünglich 6 auf 4/20 reduziert, da die Versteifung des Handgelenks in funktionsgünstiger Stellung zu größeren Funktions&shy;beeinträchtigungen führt. Die Bemessung erfolgt nach Hand- und nicht Daumenwert, da globale Handfunktionen betroffen sind, die sich allein im Daumenwert nicht abbilden lassen.</th>
                                    </tr>
                                    <tr>
                                        <td>Versteifung Daumensattelgelenk</td>
                                        <td>Handwert: <br />6/20</td>
                                        <td>Handwert: <br />4/20</td>
                                    </tr>
                                    <tr>
                                        <th>Hier kommt es bei subsumierender Betrachtung zu einer Potenzierung des Invaliditätswertes über die reine Addition hinaus, da grundlegende Greiffunktionen beeinträchtigt sind.</th>
                                    </tr>
                                    <tr>
                                        <td>Daumensattel- und -endgelenk</td>
                                        <td>-</td>
                                        <td>Handwert: <br />12/20</td>
                                    </tr>
                                    <tr>
                                        <th>erhöhende Korrektur gegenüber Erstpublikation um 1/20 wegen geänderter Normbeweglichkeit</th>
                                    </tr>
                                    <tr>
                                        <td>Funktions&shy;beeinträchtigung Oberes Sprungelenk (10 / 0 / 30)</td>
                                        <td>Fußwert: <br />1/20</td>
                                        <td>Fußwert: <br />2/20</td>
                                    </tr>
                                    <tr>
                                        <td>Funktions&shy;beeinträchtigung Oberes Sprungelenk (10 / 0 / 20)</td>
                                        <td>Fußwert: <br />2/20</td>
                                        <td>Fußwert: <br />3/20</td>
                                    </tr>
                                    <tr>
                                        <th>Die nach eigenen Literaturrecherchen definierten Normbeweglichkeiten von Hand- und oberem Sprunggelenk wurden vom Herausgeber der Messblätter (DGUV) zwar als korrekt eingeordnet, wegen fehlender MdE-Relevanz aber nicht in die Messblätter aufgenommen. Das hat zur Folge, dass am Sprunggelenk eine Korrektur der Invaliditätswerte erforderlich war mit Erhöhung um je 1/20 Fuß.</th>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <h2>Hinweise zur Vergleichbarkeit von Invaliditätswerten</h2>
                        <div>
                            <p>Bei der Plausibilitätsprüfung von Bemessungseckwerten ist der Kliniker bei
                                Gesamtbetrachtung des Individuums gewillt, Funktions&shy;beeinträchtigungen z.B. am
                                Daumen
                                mit
                                der Handfunktion oder vom Fuß mit der Beinfunktion zu vergleichen. Diese
                                Herangehensweise ist aber zum Scheitern verurteilt, da vom Versicherer die
                                Verlustwerte
                                als Bezugspunkt vorgegeben sind. Betrachtet man sich also den Verlust des Armes im
                                Vergleich zum Beinverlust, so zieht der Armverlust wesentlich mehr
                                Funktions&shy;beeinträchtigungen nach sich als der Beinverlust, ist aber nach
                                AUB-Musterbedingungen gleichwertig mit 70% zu bemessen. Auch die Hand ist nur 15%
                                „wertvoller“ als der Fuß! Insofern kann nur eine Vergleichbarkeit von Werten
                                innerhalb
                                der Gliedmaße/des Gliedmaßenteils erfolgen. Beispielhaft ist also eine
                                HandFunktions&shy;beeinträchtigung nur mit einer anderen Handfunktion vergleichbar und
                                eine
                                DaumenFunktions&shy;beeinträchtigung nur mit einer anderen Daumenfunktion.</p>
                        </div>
                    </div>
                    <div className="overlay-text anleitung scrollContainer">
                        <h2 style={{ margin: '50px 0 5px 0' }}>Anleitung zum Umgang mit der Datenbank</h2>
                        <h2>- Erklärung des Seiten Aufbaus -</h2>
                        <div className={'borderBox'}>
                            <img style={{ width: '100%' }} src={erklaerung_img} className="table" alt="" />
                        </div>
                        <h2>Medizinischer Teil für die Anleitung zum Umgang mit der Datenbank</h2>
                        <div className="two-col">
                            <div>
                                <p>Die medizinische Gliederung ist so vorgenommen worden, dass Sie zunächst
                                    3 „Invatare“ auf der Startseite sehen und bei Klick auf die äußeren
                                    Invatare Informationen zur Bewertung von posttraumatischen Arthrosen oder
                                    Endoprothesen erhalten.</p>
                                <p>Das Herzstück ist der in blau gehaltene Invatar zu den
                                    Invaliditätswerten. Klicken Sie diesen Invatar an, um in die
                                    Gliederungsebene der Invaliditätswerte zu gelangen.</p>
                                <p>Sie können nun über die gelben „bubbles“ zu der Körperregion (Arm, Hand,
                                    Bein usw.) gelangen, für die Sie eine Invaliditätsbewertung vornehmen
                                    müssen.</p>
                                <p>In der nächsten Ebene erfolgt betreffend die Gliedmaßen eine strenge
                                    Gliederung zwischen Verlustwerten (hier sind auch Werte benannt, die
                                    nicht in den AUB vorkommen, aber für die Beurteilung und den Vergleich
                                    wertvoll sind), Versteifungen und Funktions&shy;beeinträchtigungen.</p>
                            </div>
                            <div>
                                <p>Sie bekommen dann, wenn Sie z.B. zur richtigen Gliedmaßenregion navigiert
                                    haben, den Versteifungs- oder Funktions&shy;beeinträchtigungswert visualisiert und
                                    können gleichzeitig mit anderen Werten vergleichen.</p>
                                <p>Für die Werte außerhalb der Gliedertaxe erhalten Sie aussagekräftige
                                    Tabellen zum Vergleich mit dem von Ihnen zu beurteilenden Fall bzw.
                                    werden Sie im Bereich der Wirbelsäule zu den einzelnen funktionellen
                                    Regionen sowie zu den Referenzwerten geleitet, wo Sie erkennen können, welche
                                    Parameter für die
                                    Funktionsbeurteilung von Bedeutung sind.</p>
                                <p>Über entsprechende Menübuttons gelangen Sie jeweils eine Ebene zurück
                                    oder zur Ausgangsseite oder können die Szene zurücksetzen.</p>
                                <p style={{ margin: '30px 0' }}>Viel Spaß bei der intuitiven Navigation.</p>
                                <p>Übrigens: Wenn Sie Unstimmigkeiten von Wertepaaren feststellen sollten
                                    oder Anregungen zur Verbesserung des Systems haben, schreiben Sie uns:
                                    <a href="mailto:invatar@fgimb.de"> invatar@fgimb.de</a></p>
                            </div>
                        </div>
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-wirbelsaeule">
                    <div className="overlay-text info-wirbel scrollContainer">
                        <h2>Weiterführende Informationen zu der aktuellen Szene/Invalidität</h2>
                        <div>
                            <div>
                                <p>Die gutachtliche Bemessung von verbliebenen Funktions&shy;beeinträchtigungen am Achssystem
                                    bzw. nicht paarigen Organen des Menschen stellt den ärztlichen Sachverständigen vor
                                    ganz besondere Herausforderungen. Der Sachverständige muss alle Einflussfaktoren auf
                                    das funktionelle Endergebnis kennen, die sich bereits aus Art und Ausmaß der
                                    Erstgesundheitsschädigung aber auch aus den unterschiedlichen
                                    Ausheilungsmöglichkeiten in Abhängigkeit von der betroffenen funktionellen
                                    Bewegungsregion der Wirbelsäule ergeben.</p>
                                <p>Die Invalidität ist nicht punkt-/prozentgenau zu beziffern. Außerdem sind auch nach
                                    langstreckigen Versteifungen im Bereich mehrerer funktioneller Bewegungsregionen
                                    kaum Funktionsbeeinträchtigungen vorstellbar, die eine Invaliditätsbemessung über 30%
                                    rechtfertigen könnten. Dies bedürfte einer individuell sehr plausiblen Erklärung.
                                    Würde man nun einer Systematik der Abstufung der Invalidität in 5er Schritten
                                    folgen, so zeigt die Erfahrung, dass damit die verschiedenen Funktionsdefizite nicht
                                    ausreichend abzubilden sind, also auch Bemessungen z.B. zwischen den Werten 5 und 10
                                    zu diskutieren sind, also 2,5 usw. Das scheint zunächst ein Widerspruch zur
                                    fehlenden Möglichkeit einer punktgenauen Invaliditätsbemessung, bestimmt aber
                                    letztlich nur systematisch einen definierten Zwischenwert.</p>
                                <p>In der hier präsentierten Systematik finden die erheblichen funktionellen
                                    Unterschiede verschiedener Wirbelsäulenabschnitte Beachtung. Unfallbedingt
                                    verbliebene Formverbildungen oder Versteifungen können nicht losgelöst vom
                                    betroffenen Wirbelsäulenabschnitt beurteilt werden. Die Versteifung eines
                                    Bewegungssegments der Halswirbelsäule zieht andere Funktionsbeeinträchtigungen nach sich als
                                    die eines Bewegungssegments der Brustwirbelsäule oder wieder andere bei
                                    Betroffenheit des thorakolumbalen Übergangs (Brust-Lendenwirbelsäulenübergang).
                                    Unabdingbar ist auch die Beantwortung der Frage nach dem Vorliegen einer Störung des
                                    sagittalen Profils mit Abweichung desselben vom ehemals vor dem Unfall bestehenden
                                    Profils der Wirbelsäule (ΔGDW).</p>
                                <p>Aufgrund der erheblichen anatomischen und biomechanischen Unterschiede innerhalb des
                                    Achsenskeletts ist eine Gliederung zielführend, die neben anatomischen und
                                    biomechanischen Gesichtspunkten auch die jeweiligen funktionellen Besonderheiten
                                    berücksichtigt. Auf Grund der differenten Anzahl von Spinalnervsegmenten an der
                                    Halswirbelsäule wird eine Nomenklatur verwendet, die sich an der knöchernen Struktur
                                    orientiert, nämlich dem Wirbel selbst. Auch wird bewusst von einem Wirbel und nicht
                                    einem Wirbelkörper gesprochen, da Letzterer nur ein Teil des Wirbels ist.</p>
                            </div>
                        </div>
                    </div>
                    <div className="overlay-text anleitung-w scrollContainer">
                        <h2 style={{ margin: '50px 0 5px 0' }}>Anleitung zum Umgang mit der Datenbank</h2>
                        <h2>- Erklärung des Seiten Aufbaus -</h2>
                        <img style={{ width: '100%' }} src={erklaerung_img} className="table" alt="" />
                        <h2>Medizinischer Teil für die Anleitung zum Umgang mit der Datenbank</h2>
                        <div className="two-col">
                            <div>
                                <p>Die medizinische Gliederung ist so vorgenommen worden, dass Sie zunächst
                                    3 „Invatare“ auf der Startseite sehen und bei Klick auf die äußeren
                                    Invatare Informationen zur Bewertung von posttraumatischen Arthrosen oder
                                    Endoprothesen erhalten.</p>
                                <p>Das Herzstück ist der in blau gehaltene Invatar zu den
                                    Invaliditätswerten. Klicken Sie diesen Invatar an, um in die
                                    Gliederungsebene der Invaliditätswerte zu gelangen.</p>
                                <p>Sie können nun über die gelben „bubbles“ zu der Körperregion (Arm, Hand,
                                    Bein usw.) gelangen, für die Sie eine Invaliditätsbewertung vornehmen
                                    müssen.</p>
                                <p>In der nächsten Ebene erfolgt betreffend die Gliedmaßen eine strenge
                                    Gliederung zwischen Verlustwerten (hier sind auch Werte benannt, die
                                    nicht in den AUB vorkommen, aber für die Beurteilung und den Vergleich
                                    wertvoll sind), Versteifungen und Funktions&shy;beeinträchtigungen.</p>
                            </div>
                            <div>
                                <p>Sie bekommen dann, wenn Sie z.B. zur richtigen Gliedmaßenregion navigiert
                                    haben, den Versteifungs- oder Funktions&shy;beeinträchtigungswert visualisiert und
                                    können gleichzeitig mit anderen Werten vergleichen.</p>
                                <p>Für die Werte außerhalb der Gliedertaxe erhalten Sie aussagekräftige
                                    Tabellen zum Vergleich mit dem von Ihnen zu beurteilenden Fall bzw.
                                    werden Sie im Bereich der Wirbelsäule zu den einzelnen funktionellen
                                    Regionen sowie zu den Referenzwerten geleitet, wo Sie erkennen können, welche
                                    Parameter für die
                                    Funktionsbeurteilung von Bedeutung sind.</p>
                                <p>Über entsprechende Menübuttons gelangen Sie jeweils eine Ebene zurück
                                    oder zur Ausgangsseite oder können die Szene zurücksetzen.</p>
                                <p style={{ margin: '30px 0' }}>Viel Spaß bei der intuitiven Navigation.</p>
                                <p>Übrigens: Wenn Sie Unstimmigkeiten von Wertepaaren feststellen sollten
                                    oder Anregungen zur Verbesserung des Systems haben, schreiben Sie uns:
                                    <a href="mailto:invatar@fgimb.de"> invatar@fgimb.de</a></p>
                            </div>
                        </div>
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-brustkorb">
                    <div className="overlay-text info-wirbel scrollContainer">
                        <h2>Informationstext Brustkorb</h2>
                        <p>Bei stabil verheilten Brüchen des Brustbeins ohne erkennbare Knochennarbe resultiert
                            regelhaft keine messbare Invalidität. Bei Ausheilung mit Achsenknick je nach Ausprägung
                            der
                            Funktions&shy;beeinträchtigung ist eine Invalidität um 5% zu erwarten. Knöchern ohne jegliche
                            erkennbare
                            Knochennarben oder Fehlstellung ausgeheilte Rippenbrüche lassen regelhaft eine messbare
                            Invalidität nicht begründen. Ist aber röntgenmorphologisch eine funktionell relevante
                            Fehlstellung oder ein Falschgelenk vorhanden oder die Irritation des interkostalen
                            Nerven
                            nachzuweisen, so ist je nach Ausdehnung (eine bis 2 Rippen oder Rippenserienbruch) die
                            Invalidität ≤ 10% zu begründen. Fehl-oder falschgelenkig verheilte Rippenbrüche nach
                            einem
                            Rippenserienbruch mit erkennbarer Deformierung des Brustkorbs sind bei nachgewiesener
                            Störung der Atemmechanik mit 10% zu bemessen bei interpolierender Betrachtung der
                            LungenFunktions&shy;beeinträchtigung. Es ist also in diesen Fällen (und das auch insbesondere bei
                            Schwielen- und Schwartenbildungen) eine fachinternistische Lungenfunktionsdiagnostik/
                            Zusatzbegutachtung erforderlich. Diesbezüglich sind auch Folgen von Blutoder
                            Luftansammlungen zwischen Lunge und Brustkorbwand regelhaft fachinternistisch
                            mitzubeurteilen. Eckwerte der Invaliditätsbemessung sind in .Tab. 2 dargestellt.</p>
                    </div>
                    <div className="overlay-text anleitung-w scrollContainer">
                        <h2 style={{ margin: '50px 0 5px 0' }}>Anleitung zum Umgang mit der Datenbank</h2>
                        <h2>- Erklärung des Seiten Aufbaus -</h2>
                        <img style={{ width: '100%' }} src={erklaerung_img} className="table" alt="" />
                        <h2>Medizinischer Teil für die Anleitung zum Umgang mit der Datenbank</h2>
                        <div className="two-col">
                            <div>
                                <p>Die medizinische Gliederung ist so vorgenommen worden, dass Sie zunächst
                                    3 „Invatare“ auf der Startseite sehen und bei Klick auf die äußeren
                                    Invatare Informationen zur Bewertung von posttraumatischen Arthrosen oder
                                    Endoprothesen erhalten.</p>
                                <p>Das Herzstück ist der in blau gehaltene Invatar zu den
                                    Invaliditätswerten. Klicken Sie diesen Invatar an, um in die
                                    Gliederungsebene der Invaliditätswerte zu gelangen.</p>
                                <p>Sie können nun über die gelben „bubbles“ zu der Körperregion (Arm, Hand,
                                    Bein usw.) gelangen, für die Sie eine Invaliditätsbewertung vornehmen
                                    müssen.</p>
                                <p>In der nächsten Ebene erfolgt betreffend die Gliedmaßen eine strenge
                                    Gliederung zwischen Verlustwerten (hier sind auch Werte benannt, die
                                    nicht in den AUB vorkommen, aber für die Beurteilung und den Vergleich
                                    wertvoll sind), Versteifungen und Funktions&shy;beeinträchtigungen.</p>
                            </div>
                            <div>
                                <p>Sie bekommen dann, wenn Sie z.B. zur richtigen Gliedmaßenregion navigiert
                                    haben, den Versteifungs- oder Funktions&shy;beeinträchtigungswert visualisiert und
                                    können gleichzeitig mit anderen Werten vergleichen.</p>
                                <p>Für die Werte außerhalb der Gliedertaxe erhalten Sie aussagekräftige
                                    Tabellen zum Vergleich mit dem von Ihnen zu beurteilenden Fall bzw.
                                    werden Sie im Bereich der Wirbelsäule zu den einzelnen funktionellen
                                    Regionen sowie zu den Referenzwerten geleitet, wo Sie erkennen können, welche
                                    Parameter für die
                                    Funktionsbeurteilung von Bedeutung sind.</p>
                                <p>Über entsprechende Menübuttons gelangen Sie jeweils eine Ebene zurück
                                    oder zur Ausgangsseite oder können die Szene zurücksetzen.</p>
                                <p style={{ margin: '30px 0' }}>Viel Spaß bei der intuitiven Navigation.</p>
                                <p>Übrigens: Wenn Sie Unstimmigkeiten von Wertepaaren feststellen sollten
                                    oder Anregungen zur Verbesserung des Systems haben, schreiben Sie uns:
                                    <a href="mailto:invatar@fgimb.de"> invatar@fgimb.de</a></p>
                            </div>
                        </div>
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-becken">
                    <div className="overlay-text info-wirbel scrollContainer">
                        <h2>Informationstext Beckenverletzung</h2>
                        <p>Im Bereich des Beckens können nach Verletzungen regelhaft auch Unfallverletzungsfolgen
                            bestehen
                            bleiben, die einer gutachtlichen Untersuchung nur schwer zugänglich sind. Bei
                            gesicherter
                            Erstgesundheitsschädigung sind einerseits die bildgebenden Veränderungen wie z.B.
                            Knochennarben
                            oder MR-tomographische/ szintigraphische Nachweise von Reizzuständen zu beschreiben, und
                            andererseits ist der ärztliche Sachverständige gehalten, die in diesem Zusammenhang
                            nachweisbaren Funktions&shy;beeinträchtigungen möglichst genau zu beschreiben und einer
                            Plausibilitätsprüfung
                            zu unterziehen. Der Gutachter muss also subjektive Beschwerdeangaben an nachweisbaren
                            Funktions&shy;beeinträchtigungen plausibel machen oder bei fehlendem Nachweis von funktionellen
                            Beeinträchtigungen ausschließen:</p>
                        <ul>
                            <li>verkürzte Dauer des erträglichenSitzens,</li>
                            <li>Sitzimbalance mit Angewiesensein auf ein orthopädisches Sitzkissen
                                oder einen orthopädischen Bürostuhl,
                            </li>
                            <li>Verminderung der Gehstrecke,</li>
                            <li>Auftreten einer belastungsabhängigen Gangbildstörung
                                durch
                                zunehmende Schmerzen im Becken,
                            </li>
                            <li>ausstrahlende Schmerzen in die Lendenwirbelsäule und
                                in
                                die
                                Hüften durch eine fehlende Beckenrotation (Versteifung der Kreuz-Darmbeingelenkfugen),
                            </li>
                            <li>Unmöglichkeit
                                des
                                Einnehmens von Zwangshaltungen mit tiefem Hocksitz oder weitem Vornüberbeugen durch
                                dabei
                                auftretende Zugspannungen im aus der Verletzung resultierenden knöchernen und
                                Weichtel-Narbengewebe,
                            </li>

                            <li>Beinlängenunterschied durch Fehlstellung. Diese
                                Funktions&shy;beeinträchtigungen
                                müssen durch möglichst viele bildgebende <span
                                    className={"underline"}>und</span> klinische Befunde gestützt oder widerlegt
                                werden:
                                <ul>
                                    <li>Ausbildung der Gesäßmuskulatur,</li>
                                    <li>Weichteilgrübchen,</li>
                                    <li>Asymmetrie der Beinmuskulatur,</li>
                                    <li>Fußsohlenbeschwielung,</li>
                                    <li>Verknöcherung der Kreuz-Darmbeingelenkfugen bzw.Osteophytenbildungen,</li>
                                    <li>radiologisch
                                        nachweisbare Beckenasymmetrie,
                                    </li>
                                    <li>im MRT Zeichen des chronischen Reizzustandes im Bereich
                                        Schoßfuge oder Kreuz-Darmbeingelenke,
                                    </li>
                                    <li>bildgebende Veränderungen am lumbosakralen Übergang,</li>
                                    <li>Schädigung
                                        des N.
                                        pudendus(Schamnerv),
                                    </li>
                                    <li>Piriformis-Syndrom(Schmerzen und Taubheitsgefühle auf Grund Einengung des
                                        Ischiasnerv durch den Piriformis-Muskel). Auch im Bereich des Beckens gilt, dass
                                        bei
                                        normüberschreitenden
                                        nozizeptiven und/oder neuropathischen Schmerzen eine neurologische
                                        Zusatzbegutachtung
                                        veranlasst
                                        werden sollte.
                                    </li>
                                </ul>
                            </li>
                        </ul>

                    </div>
                    <div className="overlay-text anleitung-w scrollContainer">
                        <h2 style={{ margin: '50px 0 5px 0' }}>Anleitung zum Umgang mit der Datenbank</h2>
                        <h2>- Erklärung des Seiten Aufbaus -</h2>
                        <img style={{ width: '100%' }} src={erklaerung_img} className="table" alt="" />
                        <h2>Medizinischer Teil für die Anleitung zum Umgang mit der Datenbank</h2>
                        <div className="two-col">
                            <div>
                                <p>Die medizinische Gliederung ist so vorgenommen worden, dass Sie zunächst
                                    3 „Invatare“ auf der Startseite sehen und bei Klick auf die äußeren
                                    Invatare Informationen zur Bewertung von posttraumatischen Arthrosen oder
                                    Endoprothesen erhalten.</p>
                                <p>Das Herzstück ist der in blau gehaltene Invatar zu den
                                    Invaliditätswerten. Klicken Sie diesen Invatar an, um in die
                                    Gliederungsebene der Invaliditätswerte zu gelangen.</p>
                                <p>Sie können nun über die gelben „bubbles“ zu der Körperregion (Arm, Hand,
                                    Bein usw.) gelangen, für die Sie eine Invaliditätsbewertung vornehmen
                                    müssen.</p>
                                <p>In der nächsten Ebene erfolgt betreffend die Gliedmaßen eine strenge
                                    Gliederung zwischen Verlustwerten (hier sind auch Werte benannt, die
                                    nicht in den AUB vorkommen, aber für die Beurteilung und den Vergleich
                                    wertvoll sind), Versteifungen und Funktions&shy;beeinträchtigungen.</p>
                            </div>
                            <div>
                                <p>Sie bekommen dann, wenn Sie z.B. zur richtigen Gliedmaßenregion navigiert
                                    haben, den Versteifungs- oder Funktions&shy;beeinträchtigungswert visualisiert und
                                    können gleichzeitig mit anderen Werten vergleichen.</p>
                                <p>Für die Werte außerhalb der Gliedertaxe erhalten Sie aussagekräftige
                                    Tabellen zum Vergleich mit dem von Ihnen zu beurteilenden Fall bzw.
                                    werden Sie im Bereich der Wirbelsäule zu den einzelnen funktionellen
                                    Regionen sowie zu den Referenzwerten geleitet, wo Sie erkennen können, welche
                                    Parameter für die
                                    Funktionsbeurteilung von Bedeutung sind.</p>
                                <p>Über entsprechende Menübuttons gelangen Sie jeweils eine Ebene zurück
                                    oder zur Ausgangsseite oder können die Szene zurücksetzen.</p>
                                <p style={{ margin: '30px 0' }}>Viel Spaß bei der intuitiven Navigation.</p>
                                <p>Übrigens: Wenn Sie Unstimmigkeiten von Wertepaaren feststellen sollten
                                    oder Anregungen zur Verbesserung des Systems haben, schreiben Sie uns:
                                    <a href="mailto:invatar@fgimb.de"> invatar@fgimb.de</a></p>
                            </div>
                        </div>
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-bauchdecke">
                    <div className="overlay-text info-wirbel scrollContainer">
                        <h2>Funktions&shy;beeinträchtigung Bauchdecke</h2>
                        <p>Reizlos und stabil verheilte Bauchwandnarben nach Bauchöffnung führen regelhaft nicht zu
                            funktionellen Beeinträchtigungen.
                            Liegen narbige Umbildungen im Sinne eines Keloids oder auch Verwachsungsbeschwerden vor,
                            so sind bei nachgewiesenen Funktions&shy;beeinträchtigungen (z.B. mit fotografischen und/oder
                            sonografischen/kernspintomografischen Befunden) Invaliditätswerte bis 5% zu
                            rechtfertigen.
                            Bei großen Bauchwandhernien kommt es bereits bei der normalen Bauchpresse zum Austritt
                            von Eingeweiden, so dass regelhaft das Tragen eines Bruchbandes bereits bei normalen
                            Verrichtungen des täglichen Lebens erforderlich ist. Allerdings spielen in der PUV
                            Hilfsmittel mit Ausnahme der Brille/Kontaktlinse keine Rolle, deshalb ist die
                            Größe/Ausdehnung des Bauchwandbruchs invaliditätsrelevant. Andererseits führen
                            Vorwölbungen von Eingeweide bei kleineren Bauchwandhernien eher zu einer Einklemmung.
                            Ein solcher Zustand ist aber regelhaft eine Operationsindikation und wird
                            dementsprechend nicht als Unfallverletzungsfolgezustand zu bewerten sein. Insofern
                            bleibt unter funktionellen Gesichtspunkten lediglich die „funktionelle“ Abstufung anhand
                            der Größe des Bauchwandbruchs.</p>
                    </div>
                    <div className="overlay-text anleitung-w scrollContainer">
                        <h2 style={{ margin: '50px 0 5px 0' }}>Anleitung zum Umgang mit der Datenbank</h2>
                        <h2>- Erklärung des Seiten Aufbaus -</h2>
                        <img style={{ width: '100%' }} src={erklaerung_img} className="table" alt="" />
                        <h2>Medizinischer Teil für die Anleitung zum Umgang mit der Datenbank</h2>
                        <div className="two-col">
                            <div>
                                <p>Die medizinische Gliederung ist so vorgenommen worden, dass Sie zunächst
                                    3 „Invatare“ auf der Startseite sehen und bei Klick auf die äußeren
                                    Invatare Informationen zur Bewertung von posttraumatischen Arthrosen oder
                                    Endoprothesen erhalten.</p>
                                <p>Das Herzstück ist der in blau gehaltene Invatar zu den
                                    Invaliditätswerten. Klicken Sie diesen Invatar an, um in die
                                    Gliederungsebene der Invaliditätswerte zu gelangen.</p>
                                <p>Sie können nun über die gelben „bubbles“ zu der Körperregion (Arm, Hand,
                                    Bein usw.) gelangen, für die Sie eine Invaliditätsbewertung vornehmen
                                    müssen.</p>
                                <p>In der nächsten Ebene erfolgt betreffend die Gliedmaßen eine strenge
                                    Gliederung zwischen Verlustwerten (hier sind auch Werte benannt, die
                                    nicht in den AUB vorkommen, aber für die Beurteilung und den Vergleich
                                    wertvoll sind), Versteifungen und Funktions&shy;beeinträchtigungen.</p>
                            </div>
                            <div>
                                <p>Sie bekommen dann, wenn Sie z.B. zur richtigen Gliedmaßenregion navigiert
                                    haben, den Versteifungs- oder Funktions&shy;beeinträchtigungswert visualisiert und
                                    können gleichzeitig mit anderen Werten vergleichen.</p>
                                <p>Für die Werte außerhalb der Gliedertaxe erhalten Sie aussagekräftige
                                    Tabellen zum Vergleich mit dem von Ihnen zu beurteilenden Fall bzw.
                                    werden Sie im Bereich der Wirbelsäule zu den einzelnen funktionellen
                                    Regionen sowie zu den Referenzwerten geleitet, wo Sie erkennen können, welche
                                    Parameter für die
                                    Funktionsbeurteilung von Bedeutung sind.</p>
                                <p>Über entsprechende Menübuttons gelangen Sie jeweils eine Ebene zurück
                                    oder zur Ausgangsseite oder können die Szene zurücksetzen.</p>
                                <p style={{ margin: '30px 0' }}>Viel Spaß bei der intuitiven Navigation.</p>
                                <p>Übrigens: Wenn Sie Unstimmigkeiten von Wertepaaren feststellen sollten
                                    oder Anregungen zur Verbesserung des Systems haben, schreiben Sie uns:
                                    <a href="mailto:invatar@fgimb.de"> invatar@fgimb.de</a></p>
                            </div>
                        </div>
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div
                    className="overlay szene szene-invatar szene-integument szene-thrombose szene-laengen-achsen szene-arm szene-hand szene-fuß szene-bein szene-versteifung szene-funktionsstoerung szene szene-versteifung-bein szene-funktionsstoerung-bein">
                    <div className="overlay-text anleitung-w scrollContainer">
                        <h2 style={{ margin: '50px 0 5px 0' }}>Anleitung zum Umgang mit der Datenbank</h2>
                        <h2>- Erklärung des Seiten Aufbaus -</h2>
                        <img style={{ width: '100%' }} src={erklaerung_img} className="table" alt="" />
                        <h2>Medizinischer Teil für die Anleitung zum Umgang mit der Datenbank</h2>
                        <div className="two-col">
                            <div>
                                <p>Die medizinische Gliederung ist so vorgenommen worden, dass Sie zunächst
                                    3 „Invatare“ auf der Startseite sehen und bei Klick auf die äußeren
                                    Invatare Informationen zur Bewertung von posttraumatischen Arthrosen oder
                                    Endoprothesen erhalten.</p>
                                <p>Das Herzstück ist der in blau gehaltene Invatar zu den
                                    Invaliditätswerten. Klicken Sie diesen Invatar an, um in die
                                    Gliederungsebene der Invaliditätswerte zu gelangen.</p>
                                <p>Sie können nun über die gelben „bubbles“ zu der Körperregion (Arm, Hand,
                                    Bein usw.) gelangen, für die Sie eine Invaliditätsbewertung vornehmen
                                    müssen.</p>
                                <p>In der nächsten Ebene erfolgt betreffend die Gliedmaßen eine strenge
                                    Gliederung zwischen Verlustwerten (hier sind auch Werte benannt, die
                                    nicht in den AUB vorkommen, aber für die Beurteilung und den Vergleich
                                    wertvoll sind), Versteifungen und Funktions&shy;beeinträchtigungen.</p>
                            </div>
                            <div>
                                <p>Sie bekommen dann, wenn Sie z.B. zur richtigen Gliedmaßenregion navigiert
                                    haben, den Versteifungs- oder Funktions&shy;beeinträchtigungswert visualisiert und
                                    können gleichzeitig mit anderen Werten vergleichen.</p>
                                <p>Für die Werte außerhalb der Gliedertaxe erhalten Sie aussagekräftige
                                    Tabellen zum Vergleich mit dem von Ihnen zu beurteilenden Fall bzw.
                                    werden Sie im Bereich der Wirbelsäule zu den einzelnen funktionellen
                                    Regionen sowie zu den Referenzwerten geleitet, wo Sie erkennen können, welche
                                    Parameter für die
                                    Funktionsbeurteilung von Bedeutung sind.</p>
                                <p>Über entsprechende Menübuttons gelangen Sie jeweils eine Ebene zurück
                                    oder zur Ausgangsseite oder können die Szene zurücksetzen.</p>
                                <p style={{ margin: '30px 0' }}>Viel Spaß bei der intuitiven Navigation.</p>
                                <p>Übrigens: Wenn Sie Unstimmigkeiten von Wertepaaren feststellen sollten
                                    oder Anregungen zur Verbesserung des Systems haben, schreiben Sie uns:
                                    <a href="mailto:invatar@fgimb.de"> invatar@fgimb.de</a></p>
                            </div>
                        </div>
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>

            </div>
            <div className="overlay-info" id="overlay-info-big">
                <div className="overlay szene szene-wirbelsaeule">
                    <div className="overlay-text site-inval">
                        <iframe
                            src={process.env.PUBLIC_URL + '/invaliditaet/index.html'}
                            title="Lokale HTML-Seite"
                            style={{ height: '100%', width: '103%', border: 'none' }}
                        />
                    </div>
                    <div className="overlay-text site-vid">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/8wu8alTzpHA?si=6KYxu0XEWmh6dcvl&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
            </div>
            <div className="overlay-info" id="overlay-fussnote">
                <div className="overlay szene szene-bauchdecke">
                    <div className="scrollContainer">
                        <h2>Bauchdecke</h2>
                        <p>Weiterführende Informationen und Fußnoten</p>
                        <p class="fußnoten-info">ACHTUNG – die Fußnotennummerierung beginnt – technisch bedingt – in jedem Gelenkabschnitt neu. Verwechseln Sie also bitte die Zugehörigkeit zu einem Gelenkbereich nicht.</p>

                        <p className='no-info'>Momentan keine weiterführenden Informationen oder zusätzlichen Anmerkungen/Veränderungen in dieser Szene</p>
                        
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-becken">
                    <div className="scrollContainer">
                        <h2>Becken</h2>
                        <p>Weiterführende Informationen und Fußnoten</p>
                        <p class="fußnoten-info">ACHTUNG – die Fußnotennummerierung beginnt – technisch bedingt – in jedem Gelenkabschnitt neu. Verwechseln Sie also bitte die Zugehörigkeit zu einem Gelenkbereich nicht.</p>
                        <p className='no-info'>Momentan keine weiterführenden Informationen oder zusätzlichen Anmerkungen/Veränderungen in dieser Szene</p>

                        
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-brustkorb">
                    <div className="scrollContainer">
                        <h2>Brustkorb</h2>
                        <p>Weiterführende Informationen und Fußnoten</p>
                        <p class="fußnoten-info">ACHTUNG – die Fußnotennummerierung beginnt – technisch bedingt – in jedem Gelenkabschnitt neu. Verwechseln Sie also bitte die Zugehörigkeit zu einem Gelenkbereich nicht.</p>

                        <p className='no-info'>Momentan keine weiterführenden Informationen oder zusätzlichen Anmerkungen/Veränderungen in dieser Szene</p>

                        
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-wirbelsaeule">
                    <div className="scrollContainer">
                        <h2>Wirbelsäule</h2>
                        <p>Weiterführende Informationen und Fußnoten</p>
                        <p class="fußnoten-info">ACHTUNG – die Fußnotennummerierung beginnt – technisch bedingt – in jedem Gelenkabschnitt neu. Verwechseln Sie also bitte die Zugehörigkeit zu einem Gelenkbereich nicht.</p>

                        <p className='no-info'>Momentan keine weiterführenden Informationen oder zusätzlichen Anmerkungen/Veränderungen in dieser Szene</p>

                        
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>

                <div className="overlay szene szene-arm">
                    <div className="scrollContainer">
                        <h2>Verlust Arm</h2>
                        <p>Weiterführende Informationen und Fußnoten</p>
                        <p class="fußnoten-info">ACHTUNG – die Fußnotennummerierung beginnt – technisch bedingt – in jedem Gelenkabschnitt neu. Verwechseln Sie also bitte die Zugehörigkeit zu einem Gelenkbereich nicht.</p>

                        <ul>
                            <li>
                                <h3>Verlust</h3>
                                <p>bedeutet hier Verlust oder vollständiger Funktionsunfähigkeit</p>
                            </li>
                            <li>
                                <h3>Verlustwert</h3>
                                <p>Invaliditätsgrad bei Verlust oder vollständiger Funktionsunfähigkeit</p>
                            </li>
                        </ul>
                        
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-fuß">
                    <div className="scrollContainer">
                        <h2>Verlust Fuß</h2>
                        <p>Weiterführende Informationen und Fußnoten</p>
                        <p class="fußnoten-info">ACHTUNG – die Fußnotennummerierung beginnt – technisch bedingt – in jedem Gelenkabschnitt neu. Verwechseln Sie also bitte die Zugehörigkeit zu einem Gelenkbereich nicht.</p>

                        <ul>
                            <li>
                                <h3>Verlust</h3>
                                <p>bedeutet hier Verlust oder vollständiger Funktionsunfähigkeit</p>
                            </li>
                            <li>
                                <h3>Verlustwert</h3>
                                <p>Invaliditätsgrad bei Verlust oder vollständiger Funktionsunfähigkeit</p>
                            </li>
                        </ul>
                        
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-hand">
                    <div className="scrollContainer">
                        <h2>Verlust Hand</h2>
                        <p>Weiterführende Informationen und Fußnoten</p>
                        <p class="fußnoten-info">ACHTUNG – die Fußnotennummerierung beginnt – technisch bedingt – in jedem Gelenkabschnitt neu. Verwechseln Sie also bitte die Zugehörigkeit zu einem Gelenkbereich nicht.</p>

                        <ul>
                            <li>
                                <h3>Verlust</h3>
                                <p>bedeutet hier Verlust oder vollständiger Funktionsunfähigkeit</p>
                            </li>
                            <li>
                                <h3>Verlustwert</h3>
                                <p>Invaliditätsgrad bei Verlust oder vollständiger Funktionsunfähigkeit</p>
                            </li>
                        </ul>
                        
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-bein">
                    <div className="scrollContainer">
                        <h2>Verlust Bein</h2>
                        <p>Weiterführende Informationen und Fußnoten</p>
                        <p class="fußnoten-info">ACHTUNG – die Fußnotennummerierung beginnt – technisch bedingt – in jedem Gelenkabschnitt neu. Verwechseln Sie also bitte die Zugehörigkeit zu einem Gelenkbereich nicht.</p>

                        <ul>
                            <li>
                                <h3>Verlust</h3>
                                <p>bedeutet hier Verlust oder vollständiger Funktionsunfähigkeit</p>
                            </li>
                            <li>
                                <h3>Verlustwert</h3>
                                <p>Invaliditätsgrad bei Verlust oder vollständiger Funktionsunfähigkeit</p>
                            </li>
                        </ul>
                        
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-integument">
                    <div className="scrollContainer">
                        <h2>Integument</h2>
                        <p>Weiterführende Informationen und Fußnoten</p>
                        <p class="fußnoten-info">ACHTUNG – die Fußnotennummerierung beginnt – technisch bedingt – in jedem Gelenkabschnitt neu. Verwechseln Sie also bitte die Zugehörigkeit zu einem Gelenkbereich nicht.</p>

                        <p className='no-info'>Momentan keine weiterführenden Informationen oder zusätzlichen Anmerkungen/Veränderungen in dieser Szene</p>


                        
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-laengen-achsen">
                    <div className="scrollContainer">
                        <h2>Längen- Achsenabweichung</h2>
                        <p>Weiterführende Informationen und Fußnoten</p>
                        <p class="fußnoten-info">ACHTUNG – die Fußnotennummerierung beginnt – technisch bedingt – in jedem Gelenkabschnitt neu. Verwechseln Sie also bitte die Zugehörigkeit zu einem Gelenkbereich nicht.</p>

                        <p className='no-info'>Momentan keine weiterführenden Informationen oder zusätzlichen Anmerkungen/Veränderungen in dieser Szene</p>
                        
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-thrombose">
                    <div className="scrollContainer">
                        <h2>Thrombosefolgen
                            / Lymphödeme</h2>
                        <p>Weiterführende Informationen und Fußnoten</p>
                        <p class="fußnoten-info">ACHTUNG – die Fußnotennummerierung beginnt – technisch bedingt – in jedem Gelenkabschnitt neu. Verwechseln Sie also bitte die Zugehörigkeit zu einem Gelenkbereich nicht.</p>

                        <p className='no-info'>Momentan keine weiterführenden Informationen oder zusätzlichen Anmerkungen/Veränderungen in dieser Szene</p>
                        
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-versteifung">
                    <div className="scrollContainer">
                        <h2>Arm und Hand Versteifung</h2>
                        <p>Weiterführende Informationen und Fußnoten</p>
                        <p class="fußnoten-info">ACHTUNG – die Fußnotennummerierung beginnt – technisch bedingt – in jedem Gelenkabschnitt neu. Verwechseln Sie also bitte die Zugehörigkeit zu einem Gelenkbereich nicht.</p>

                        <h3>Finger und Daumengelenk</h3>
                        <ul>
                            <li>
                                <h3>Daumensattel- und -grundgelenk</h3>
                                <p>Invaliditätswert für Versteifung Grund- und Sattelgelenk im Vergleich zur
                                    Erstpublikation geändert, da die Funktionsbeeinträchtigung geringer ist als die
                                    Daumenamputation aber schlechter als Handgelenksversteifung</p>
                            </li>
                            <li>
                                <h3>Daumensattelgelenk</h3>
                                <p>Wert wurde von ursprünglich 6 auf 4/20 reduziert, da die Versteifung des Handgelenks
                                    in funktionsgünstiger Stellung zu größeren Funktions&shy;beeinträchtigungen führt. Die
                                    Bemessung erfolgt nach Hand- und nicht Daumenwert, da globale Handfunktionen
                                    betroffen sind, die sich allein im Daumenwert nicht abbilden lassen.</p>
                            </li>
                            <li>
                                <h3>Daumengrund- und -endgelenk</h3>
                                <p>Hier kommt es bei subsumierender Betrachtung zu einer Potenzierung des
                                    Invaliditätswertes über die reine Addition hinaus, da grundlegende Greiffunktionen
                                    beeinträchtigt sind.</p>
                            </li>
                        </ul>
                        <h3>Handgelenk</h3>
                        <ul>
                            <li>
                                <h3>Unterarmdrehung aufgehoben in Neutral-0-Stellung</h3>
                                <p>Erhöhung des Ivaliditätswertes, da in der Erstpublikation Ausgleichsbewegungen eingerechnet wurden</p>
                            </li>
                        </ul>
                        <h3>Unterarmdrehung</h3>
                        <ul>
                            <li>
                                <h3>Isolierte Aufhebung der Unterarmdrehung</h3>
                                <p>Die Aufhebung der Unterarmdrehung bedingt Funktions&shy;beeinträchtigungen der Handfunktion, so dass die Invalidität nach Handwert zu bemessen ist.</p>
                            </li>
                            <li>
                                <h3>Unterarmdrehung aufgehoben in Einwärtsdrehung, Neutral-0-Stellung, Auswärtsdrehung</h3>
                                <p>Die isolierte Aufhebung oder Einschränkung der Unterarmdrehung ist wegen der aus ihr folgenden Funktions&shy;beeinträchtigung der Handfunktion nach Handwert zu bemessen. Die Aufhebung der Unterarmdrehung tritt praktisch nie auf, wird regelhaft operativ korrigiert. Trotzdem werden Eckwerte benannt, um die z.T. extreme Funktions&shy;beeinträchtigung als Vergleichswert für andere HandFunktions&shy;beeinträchtigungen heranziehen zu können. Diesbezüglich wurde auch beim Vergleich mit der Erstpublikation eine deutliche Erhöhung der Eckwerte vorgenommen, da u.a. Ausgleichsbewegungen mit inkludiert waren.</p>
                            </li>
                        </ul>
                        <h3>Ellenbogengelenk</h3>
                        <ul>
                            <li>
                                <h3>Bei Aufhebung der Unterarmdrehung in</h3>
                                <p>Die isolierte Aufhebung oder Einschränkung der Unterarmdrehung ist wegen der aus ihr folgenden Funktionsbeeinträchtigung der Handfunktion nach Handwert zu bemessen. Die Aufhebung der Unterarmdrehung tritt praktisch nie auf, wird regelhaft operativ korrigiert. Trotzdem werden Eckwerte benannt, um die z.T. extreme Funktionsbeeinträchtigung als Vergleichswert für andere Handfunktionsbeeinträchtigungen heranziehen zu können. Diesbezüglich wurde auch beim Vergleich mit der Erstpublikation eine deutliche Erhöhung der Eckwerte vorgenommen, da u.a. Ausgleichsbewegungen mit inkludiert waren.</p>
                            </li>
                        </ul>
                        
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-versteifung-bein">
                    <div className="scrollContainer">
                        <h2>Bein und Fuß Versteifung</h2>
                        <p>Weiterführende Informationen und Fußnoten</p>
                        <p class="fußnoten-info">ACHTUNG – die Fußnotennummerierung beginnt – technisch bedingt – in jedem Gelenkabschnitt neu. Verwechseln Sie also bitte die Zugehörigkeit zu einem Gelenkbereich nicht.</p>

                        <p className='no-info'>Momentan keine weiterführenden Informationen oder zusätzlichen Anmerkungen/Veränderungen in dieser Szene</p>
                        
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-funktionsstoerung">
                    <div className="scrollContainer">
                        <h2>Arm und Hand Funktions&shy;beeinträchtigungen</h2>
                        <p>Weiterführende Informationen und Fußnoten</p>
                        <p class="fußnoten-info">ACHTUNG – die Fußnotennummerierung beginnt – technisch bedingt – in jedem Gelenkabschnitt neu. Verwechseln Sie also bitte die Zugehörigkeit zu einem Gelenkbereich nicht.</p>

                        <h3>Funktions&shy;beeinträchtigung Unterarmdrehung</h3>
                        <ul>
                            <li>
                                <h3>Einschränkung der Unterarmdrehung auswärts/einwärts</h3>
                                <p>Im Vergleich zur Erstpublikation neu aufgenommen</p>
                            </li>
                            <li>
                                <h3>Isolierte Einschränkung der Unterarmdrehung auswärts/einwärts</h3>
                                <p>Die Einschränkung der Unterarmdrehung bedingt Funktions&shy;beeinträchtigungen der Handfunktion, so dass die Invalidität nach Handwert zu bemessen ist.</p>
                            </li>
                            <li>
                                <h3>45°-0°-45°, 90°-0°-45°, 45°-0-90°</h3>
                                <p>Die isolierte Aufhebung oder Einschränkung der Unterarmdrehung ist wegen der aus ihr folgenden Funktions&shy;beeinträchtigung der Handfunktion nach Handwert zu bemessen. Die Aufhebung der Unterarmdrehung tritt praktisch nie auf, wird regelhaft operativ korrigiert. Trotzdem werden Eckwerte benannt, um die z.T. extreme Funktions&shy;beeinträchtigung als Vergleichswert für andere HandFunktions&shy;beeinträchtigungen heranziehen zu können. Diesbezüglich wurde auch beim Vergleich mit der Erstpublikation eine deutliche Erhöhung der Eckwerte vorgenommen, da u.a. Ausgleichsbewegungen mit inkludiert waren.</p>
                            </li>

                        </ul>
                        <h3>Weitere Funktions&shy;beeinträchtigung</h3>
                        <ul>
                            <li>
                                <h3>Vollständiger Funktionsverlust der langen Bizepssehne mit Kraftminderung bei der Seitwärtshebung des Armes im Schultergelenk, bei der Beugung im Ellenbogengelenk und bei der Auswärtsdrehung des Unterarmes,</h3>
                                <h3>Vollständiger Funktionsverlust der körperfernen Bizepssehne mit Einschränkungen der Ellenbogenbeugung und der Unterarmdrehung</h3>
                                <p>Hier kommt es bei subsumierender Betrachtung zu einer Potenzierung des Invaliditätswertes über die reine Addition hinaus, da grundlegende Greiffunktionen beeinträchtigt sind.</p>
                            </li>
                        </ul>
                        
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-funktionsstoerung-bein">
                    <div className="scrollContainer">
                        <h2>Bein und Fuß Funktions&shy;beeinträchtigung</h2>
                        <p>Weiterführende Informationen und Fußnoten</p>
                        <p class="fußnoten-info">ACHTUNG – die Fußnotennummerierung beginnt – technisch bedingt – in jedem Gelenkabschnitt neu. Verwechseln Sie also bitte die Zugehörigkeit zu einem Gelenkbereich nicht.</p>

                        <h3>Knieinstabilität</h3>
                        <ul>
                            <li>
                                <h3>Knieinstabilität</h3>
                                <p>Im Vergleich zur Erstpublikation neu aufgenommen</p>
                            </li>
                            <li>
                                <h3>Leicht-, mittel- und hochgradig werden nach der klinischen Bandnachgiebigkeit definiert wie folgt:</h3>
                                <p>Leichtgradig: > 3mm bis ≤ 5mm; Mittelgradig: > 5mm bis ≤ 10mm; Hochgradig: > 10mm</p>
                            </li>
                        </ul>
                        <h3>Fußgelenk</h3>
                        <ul>
                            <li>
                                <h3>Oberes Sprungelenk (10 / 0 /30), (10/0/20)</h3>
                                <p>erhöhende Korrektur gegenüber Erstpublikation um 1/20 wegen geänderter Normbeweglichkeit</p>
                            </li>
                            <li>
                                <h3>Oberes Sprungelenk 0 / 10 / x, 0 / > 10 / x</h3>
                                <p>x deshalb, weil die Restbeugefähigkeit bei Spitzfuß relativ unerheblich ist, bei
                                    Erhalt der Restbeugefähigkeit also kein relevanter Funktionsgewinn erzielt wird.</p>
                            </li>
                        </ul>
                        
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-athrose">
                    <div className="scrollContainer">
                        <h2>Athrose</h2>
                        <p>Weiterführende Informationen und Fußnoten</p>
                        <p class="fußnoten-info">ACHTUNG – die Fußnotennummerierung beginnt – technisch bedingt – in jedem Gelenkabschnitt neu. Verwechseln Sie also bitte die Zugehörigkeit zu einem Gelenkbereich nicht.</p>

                        <p className='no-info'>Momentan keine weiterführenden Informationen oder zusätzlichen Anmerkungen/Veränderungen in dieser Szene</p>
                        
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
                <div className="overlay szene szene-prothese">
                    <div className="scrollContainer">
                        <h2>Prothese</h2>
                        <p>Weiterführende Informationen und Fußnoten</p>
                        <p class="fußnoten-info">ACHTUNG – die Fußnotennummerierung beginnt – technisch bedingt – in jedem Gelenkabschnitt neu. Verwechseln Sie also bitte die Zugehörigkeit zu einem Gelenkbereich nicht.</p>

                        <p className='no-info'>Momentan keine weiterführenden Informationen oder zusätzlichen Anmerkungen/Veränderungen in dieser Szene</p>
                        
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
            </div>
            <div className="overlay-info" id="overlay-erstp">
                <div className="overlay">
                    <div className="overlay-text scrollContainer">
                        <h2>Hinweise und Updates</h2>
                        <div>
                            <p>Die Ergebnisse der Erarbeitung und Konsentierung der Invaliditätswerte in der
                                privaten Unfallversicherung wurden in „Die Unfallchirurgie“ (vormals „Der
                                Unfallchirurg“) erstmals publiziert und stehen hier auch zum Download
                                bereit:</p>
                            <p className="color-orange">(Download öffnet sich in einem seperaten Fenster)</p>
                            <a href="https://www.fgimb.de/files/Dokumente/bemessungsempfehlungen/Neue%20Bemessungsempfehlungen%20zur%20Invalidit%C3%A4t%20in%20der%20PUV%20Teil%201.pdf"
                                className="btn" target="_blank">Download Teil 1</a>
                            <a href="https://www.fgimb.de/files/Dokumente/bemessungsempfehlungen/Neue%20Bemessungsempfehlungen%20zur%20Invalidit%C3%A4t%20in%20der%20PUV%20Teil%202.pdf"
                                className="btn" target="_blank">Download Teil 2</a>
                            <a href="https://www.fgimb.de/files/Dokumente/bemessungsempfehlungen/Neue%20Bemessungsempfehlungen%20zur%20Invalidit%C3%A4t%20in%20der%20PUV%20Teil%203.pdf"
                                className="btn" target="_blank">Download Teil 3</a>
                            <a href="https://www.fgimb.de/files/Dokumente/bemessungsempfehlungen/neue-bemessungsempfehlungen-zur-invaliditaet-in-der-puv-teil-4.pdf"
                                className="btn" target="_blank">Download Teil 4</a>
                            <p>Es erfolgt in regelmäßigen Abständen die Kontrolle der Schlüssigkeit der
                                angegebenen Werte bzw. Erweiterung durch bisher noch nicht benannte
                                Funktions&shy;beeinträchtigung je nach Notwendigkeit auf Grund der Rückinformationen der
                                Datenbanknutzer.</p>
                            <p> Nach Erstpublikation der Version 1 finden sich hier die aktuelle Version 2.1</p>
                        </div>
                        <h2>Hinweise zur Vergleichbarkeit von Invaliditätswerten</h2>
                        <div>
                            <p>Bei der Plausibilitätsprüfung von Bemessungseckwerten ist der Kliniker bei
                                Gesamtbetrachtung des Individuums gewillt, Funktions&shy;beeinträchtigungen z.B. am
                                Daumen
                                mit
                                der Handfunktion oder vom Fuß mit der Beinfunktion zu vergleichen. Diese
                                Herangehensweise ist aber zum Scheitern verurteilt, da vom Versicherer die
                                Verlustwerte
                                als Bezugspunkt vorgegeben sind. Betrachtet man sich also den Verlust des Armes im
                                Vergleich zum Beinverlust, so zieht der Armverlust wesentlich mehr
                                Funktions&shy;beeinträchtigungen nach sich als der Beinverlust, ist aber nach
                                AUB-Musterbedingungen gleichwertig mit 70% zu bemessen. Auch die Hand ist nur 15%
                                „wertvoller“ als der Fuß! Insofern kann nur eine Vergleichbarkeit von Werten
                                innerhalb
                                der Gliedmaße/des Gliedmaßenteils erfolgen. Beispielhaft ist also eine
                                HandFunktions&shy;beeinträchtigung nur mit einer anderen Handfunktion vergleichbar und
                                eine
                                DaumenFunktions&shy;beeinträchtigung nur mit einer anderen Daumenfunktion.</p>
                        </div>
                    </div>
                    <button className="close" onClick={show_overlay} data-arg10='close'>Close</button>
                </div>
            </div>
            <div className="overlay" id="overlay">
                <div className="szene szene-funktionsstoerung">
                    <ul>
                        <li>
                            <p>Instabilität des Daumengrundgelenks nach „Skidaumen“</p>
                            <p className="verlust">Daumenwert: <span>2/20</span></p>
                        </li>
                        <li>
                            <p>Instabilität des Daumengrundgelenk mit Einschränkung der Gegenüberstellfähigkeit des
                                Daumens nach „Skidaumen“</p>
                            <p className="verlust">Daumenwert: <span>4/20</span></p>
                        </li>
                        <li>
                            <p>Fehlende aktive Streckbarkeit am Endgelenk eines Langfingers bei z.B. Insuffizienz
                                oder Defekt der Strecksehne</p>
                            <p className="verlust">Fingerwert: <span>2/20</span></p>
                        </li>
                        <li>
                            <p>Persistierende Schultereckgelenkinstabilität Rockwood 2
                                oder höher, je nach individuellem Funktionsdefizit im Vergleich zu anderen Eckwerten von Schulterfunktions&shy;beeinträchtigungen</p>
                            <p className="verlust">Armwert: <span>1-2/20</span></p>
                        </li>
                        <li>
                            <p>Verformung/Subluxation im Schlüsselbein-/Brustbeingelenk mit klinischer
                                Symptomatik</p>
                            <p className="verlust">Armwert: <span>1/20</span></p>
                        </li>
                        <li>
                            <p>Vollständiger Funktionsverlust der langen Bizepssehne mit Kraftminderung bei der
                                Seitwärtshebung des Armes im Schultergelenk, bei der Beugung im Ellenbogengelenk
                                und bei der Auswärtsdrehung des Unterarmes<sup>1</sup></p>
                            <p className="verlust">Armwert: <span>1/20</span></p>
                        </li>
                        <li>
                            <p>Vollständiger Funktionsverlust der körperfernen Bizepssehne mit Einschränkungen
                                der Ellenbogenbeugung und der Unterarmdrehung<sup>1</sup></p>
                            <p className="verlust">Armwert: <span>2/20</span></p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="overlay" id="overlay-img-verst-container">
                <div className="szene szene-versteifung szene-versteifung-bein" id="img-overlay-verst">
                    <img src="Schulter_Frontal_verst" alt="" />
                </div>
                <div className="szene szene-funktionsstoerung">
                    <ul>
                        <li>
                            <p>Instabilität des Daumengrundgelenks nach „Skidaumen“</p>
                            <p className="verlust">Daumenwert: <span>2/20</span></p>
                        </li>
                        <li>
                            <p>Instabilität des Daumengrundgelenk mit Einschränkung der Gegenüberstellfähigkeit des
                                Daumens nach „Skidaumen“</p>
                            <p className="verlust">Daumenwert: <span>4/20</span></p>
                        </li>
                        <li>
                            <p>Fehlende aktive Streckbarkeit am Endgelenk eines Langfingers bei z.B. Insuffizient
                                oder Defekt der Strecksehne</p>
                            <p className="verlust">Fingerwert: <span>2/20</span></p>
                        </li>
                        <li>
                            <p>Persistierende Schultereckgelekinstabilität Rockwood 2 oder höher je nach
                                individuellem Funktionsdefizit im vergleich zu anderen Eckwerten von
                                SchulterFunktions&shy;beeinträchtigungen</p>
                            <p className="verlust">Armwert: <span>1-2/20</span></p>
                        </li>
                        <li>
                            <p>Verformung/Subluxation im Schlüsselbein-/Brustbeingelenk mit klinischer
                                Symptomatik</p>
                            <p className="verlust">Armwert: <span>1/20</span></p>
                        </li>
                        <li>
                            <p>Vollständiger Funktionsverlust der langen Bizepssehne mit Kraftminderung bei der
                                Seitwärtshebung des Armes im Schultergelenk, bei der Beugung im Ellenbogengelenk
                                und bei der Auswärtsdrehung des Unterarmes</p>
                            <p className="verlust">Armwert: <span>1/20</span></p>
                        </li>
                        <li>
                            <p>Vollständiger Funktionsverlust der körperfernen Bizepssehne mit Einschränkungen
                                der Ellenbogenbeugung und der Unterarmdrehung</p>
                            <p className="verlust">Armwert: <span>2/20</span></p>
                        </li>
                    </ul>
                </div>
                <div className="szene szene-funktionsstoerung-bein" id="img-overlay-funk">
                    <h3>Kniegelenk Instabilität Dimensionen</h3>
                    <div>
                        <div>
                            <p>Vorne</p>
                            <img onClick={show_overlay} data-arg10='funk-knieinst1' src={funkst_bein_knieinst1} alt="" />
                        </div>
                        <div>
                            <p>Hinten</p>
                            <img onClick={show_overlay} data-arg10='funk-knieinst2' src={funkst_bein_knieinst2} alt="" />
                        </div>
                        <div>
                            <p>Innen</p>
                            <img onClick={show_overlay} data-arg10='funk-knieinst3' src={funkst_bein_knieinst3} alt="" />
                        </div>
                        <div>
                            <p>Außen</p>
                            <img onClick={show_overlay} data-arg10='funk-knieinst4' src={funkst_bein_knieinst4} alt="" />
                        </div>




                    </div>
                </div>
            </div>
            <div className="text-container szene szene-athrose">
                <h2>Unfallbedingte Arthrosen</h2>
                <p>Für die Beurteilung von Funktions&shy;beeinträchtigungen durch eine unfallbedingte Arthrose ist - wie
                    grundsätzlich - der Zeitpunkt der Erstbemessung (12 bzw. 15 Monate nach Unfall) maßgeblich. Auf
                    diesen Zeitpunkt müssen der Ist-Zustand und dessen Prognose bezogen werden. Kommen zu diesem
                    Zeitpunkt umformende Gelenkveränderungen bildgebend nicht zur Darstellung, kann die Möglichkeit
                    negativer Veränderungen einer Prognose nicht zugrunde gelegt werden. Kommen sie aber bildgebend
                    zur
                    Darstellung, ist die Frage der Relevanz in Bezug auf die Prognose zu stellen, denn selbst
                    bildgebend
                    gesicherte Arthrosen müssen nicht zwangsläufig auch mit einer invaliditätsrelevanten
                    Verschlechterung der Gelenkfunktion verknüpft sein.</p><p> Nur wenn also zum Zeitpunkt der
                        Erstbemessung
                        eine unfallbedingte Arthrose bildgebend gesichert ist und daraus resultierende
                        Funktionseinschränkungen vorliegen, ist deren weitere Prognose zu beachten. Liegen demgegenüber
                        zum
                        Zeitpunkt der Erstbemessung funktionell nicht relevante unfallbedingte Arthrosezeichen vor, so
                        ist
                        eine Neubemessung kurz vor Ablauf des vereinbarten Regulierungszeitpunkts zu veranlassen
                        (regelhaft
                        3 Jahre). </p>
            </div>
            <div className="text-container szene szene-prothese">
                <p><span style={{ color: "#f0b76a" }}>Pauschalierte</span> altersabhängige Invaliditätszuschläge bei
                    posttraumatischer
                    Gelenkprothesenimplantation
                    sind nicht zu rechtfertigen. Nach Kenntnis eigener Literaturrecherchen zu den
                    Prothesenstandzeiten
                    (<span className={"titleHover"} title={"Literaturauswahl:\n" +
                        "Pauschalzuschläge zur Invalidität in der privaten Unfallversicherung nicht zu rechtfertigen; " +
                        "Klemm HT, Wich M: MedSach 117 1/2021: 28-31 Endoprothetik der großen Gelenke. Prinzipien, Grundlagen, Einflußfaktoren des Behandlungsergebnisses, " +
                        "Prothesenstandzeiten und ihre gutachtliche Bewertung: Grothe T, Postler A, Nowotny J, Lützner J, " +
                        "Günther KP, Klemm HT; Trauma Berufskrankh (2019): DOI: 10.1007/s10039-019-0423-1"}>Literaturauswahl</span>)
                    wird
                    festgestellt, dass ein lebensaltersabhängiger pauschalierter
                    Prothesenzuschlag
                    allein für die Möglichkeit einer Minderbelastbarkeit/Lockerungsgefahr mit zu erwartendem
                    Prothesenwechsel nicht zu rechtfertigen ist. Wissenschaftlich ist nicht zu belegen, dass selbst
                    bei
                    Unterstellung eines zukünftig notwendig werdenden Prothesenwechsels damit zwangsläufig eine
                    invaliditätsrelevant schlechtere Funktion vergesellschaftet sein wird. Somit ist ein
                    ausschließlich
                    an das Lebensalter geknüpfter pauschaler Prothesenzuschlag nicht zu rechtfertigen. Ergänzend
                    wird
                    aber auch festgestellt, dass die Funktion eines prothetisch ersetzten Gelenks nicht nur anhand
                    von
                    Bewegungsmessungen der Freiheitsgrade beurteilt werden kann,</p><p> denn die Beweglichkeit von
                        qualitativ
                        immer weiter verbesserten Prothesen nähert sich mehr und mehr dem Physiologischen. Trotzdem ist
                        der
                        Betroffene allein durch die einliegende Prothese funktionslimitiert. Der ärztliche
                        Sachverständige
                        muss also dazu Stellung nehmen, inwieweit prothesen-/material-, zugangs- und/oder
                        instrumentierungsassoziierte Folgen neben z.B. den Störungen der Propriozeption vorhanden sind
                        oder
                        allein durch das Vorhandensein von Prothesen bestimmte Funktionen vermieden werden müssen (zum
                        Beispiel dauerhaftes Heben und Tragen von schweren Gegenständen, tiefer Hocksitz und Knien bei
                        Hüft-
                        und Knieendoprothesen, Gewichtsbelastung von über 5-10 kg bei Ellbogen-Endoprothesen u.ä.).
                        Diese
                        Faktoren werden in der Regel eine Invalidität von mindestens 1/20 Extremitätenwert
                        rechtfertigen.</p>
            </div>
            <div className="text-container szene szene-laengen-achsen">
                <p>Längen- und Achsabweichungen treten selten isoliert auf und sind regelhaft subsumierend in der
                    „Gesamt“-Invalidität zu berücksichtigen. Bei isolierter Betrachtung einer Längendifferenz kommen
                    folgende Werte zum Tragen:</p>
                <ul className="color-orange">
                    <li> >1cm bis ≤ 2 cm: 1/20-Beinwert,</li>
                    <li> >2cm bis ≤ 3 cm: 2/20-Beinwert,</li>
                    <li> >3cm bis ≤ 5 cm: 3/20-Beinwert.</li>
                </ul>
                <p>Unfallbedingte Längendifferenzen >5 cm bedürfen einer ganz individuellen Betrachtung, da
                    regelhaft
                    andere Verletzungsfolgen im Vordergrund stehen dürften. Bei isolierter Achsabweichung wird diese
                    erst invaliditätsrelevant über 5°. Es wurden dazu folgende Werte konsentiert:</p>
                <ul className="color-orange">
                    <li> > 5° bis ≤ 10°: 1/20-Beinwert,</li>
                    <li> > 10° bis ≤ 20°: 2/20-Beinwert.</li>
                </ul>
                <p>Achsabweichungen über 20° bedürfen wieder einer individuellen Betrachtung, da sie regelhaft nicht
                    isoliert auftreten und somit subsumierend beurteilt werden müssen.</p>
            </div>
            <div className="text-container szene szene-thrombose">
                <p>Thrombosefolgen und posttraumatische Lymphödeme sind regelhaft durch einen
                    internistischen/angiologischen Gutachter unter Beachtung der Leitlinien zu Diagnostik und
                    Therapie
                    der Venenthrombose und der Lungenembolie zu beurteilen. Insbesondere bei einem
                    postthrombotischen
                    Syndrom geht es v. a. um den doppler-/duplexsonographischen Befund und die Einschätzung eines
                    begleitenden Ödems, und es geht weiter um die Frage einer ggf. notwendigen blutverdünnenden
                    Therapie
                    und deren Dauer. Derartige Unfallverletzungsfolgen sind also in enger Abstimmung mit dem
                    internistisch/ angiologischen Zusatzgutachter zu beurteilen. Anhaltspunkte seitens der
                    Ausprägung
                    einer evtl. Umfangsvermehrung sagen noch nichts aus über die (wiedererlangte) Durchgängigkeit
                    des
                    Gefäßsystems und können nicht alleinig Grundlage einer Invaliditätsbemessung sein. Ist eine
                    Durchgängigkeit der Venen nach einer Thrombose sonographisch belegt und keine
                    Klappeninsuffizienz
                    vorliegend sowie die Umfangsdifferenz &lt; 2cm, kann auf eine Zusatzdiagnostik/
                    Zusatzbegutachtung
                    verzichtet werden, da eine Invaliditätsrelevanz nicht erkannt werden kann. Über allgemeine
                    Kausalitätsfragen einer Thrombose/eines Lymphödems hinaus ist aber bei Notwendigkeit
                    entstauender
                    Maßnahmen (Kompressionsbehandlung, Lymphdrainage) mit Auswirkungen auf Mobilität oder
                    Feinmotorik
                    (Letzteres insbesondere an den oberen Extremitäten) sicherlich immer ein interdisziplinärer
                    Ansatz
                    mit Beteiligung der operativen und konservativen Medizin zu befürworten.
                </p>
            </div>
            <div className="text-container szene szene-brustkorb  style-table">
                <table>
                    <tr>
                        <th>Funktions&shy;beeinträchtigung im Bereich des Brustkorbs/Brustbeins/der Rippen²</th>
                        <th>Invaliditätseckwert (%)</th>
                    </tr>
                    <tr>
                        <td>Mit Achsenknick verheilter Brustbeinbruch je nach Funktions&shy;beeinträchtigung</td>
                        <td>Um 5</td>
                    </tr>
                    <tr>
                        <td>In Fehlstellung oder falschgelenkig verheilter Rippenbruch je nach
                            Funktions&shy;beeinträchtigung
                            (1
                            - 2 Rippen oder Rippenserienbruch)
                        </td>
                        <td>0 – ≤ 10</td>
                    </tr>
                    <tr>
                        <td>Fehl- oder falschgelenkig verheilte Rippenbrüche nach Serienbruch mit erkennbarer
                            Deformierung des Brustkorbs bei nachgewiesener Störung der Atemmechanik
                        </td>
                        <td>10</td>
                    </tr>
                </table>
                <p>² Ggf. mit fachinternistischer Lungenfunktionsdiagnostik</p>
            </div>
            <div className="text-container szene szene-becken style-table">
                <table>
                    <tr>
                        <th>Morphologische Unfallfolgen</th>
                        <th>Invalidität je nach Ausprägung der Funktions&shy;beeinträchtigung (%)</th>
                    </tr>
                    <tr>
                        <td>Beckenasymmetrie (umfassende radiologische Diagnostik notwendig)</td>
                        <td> 10 –20</td>
                    </tr>
                    <tr>
                        <td>Verknöcherung oder Reizzustand der Schoßfuge oder der Kreuzdarmbeingelenke</td>
                        <td>0 – ≤ 10</td>
                    </tr>
                    <tr>
                        <td>Symphysale Diastase > 15mm</td>
                        <td>5 – 10</td>
                    </tr>
                    <tr>
                        <td>Atrophes/instabiles Falschgelenk im Bereich des Scham- oder Sitzbeins</td>
                        <td>0 – 5</td>
                    </tr>
                    <tr>
                        <td>Atrophes/instabiles Falschgelenk vorderer Beckenring einseitig</td>
                        <td>Um 15</td>
                    </tr>
                    <tr>
                        <td>Atrophes/instabiles Falschgelenk vorderer Beckenring beidseitig</td>
                        <td>Um 20</td>
                    </tr>
                </table>
                <p>Unfallfolgen wie ein Piriformis-Syndrom oder eine Schädigung des N. pudendus sind Einzelfälle,
                    sie
                    sind zusammen mit einem neurologischen und ggf. urologischen Zusatzgutachter zu beurteilen.</p>
            </div>
            <div className="text-container szene szene-bauchdecke style-table">
                <table>
                    <tr>
                        <th>Funktions&shy;beeinträchtigungen im Bereich Bauchdecke</th>
                        <th>Invalidität (%)</th>
                    </tr>
                    <tr>
                        <td>Narbige Umwandlungen eines Teils der Bauchwandmuskulatur</td>
                        <td>≤ 5</td>
                    </tr>
                    <tr>
                        <td>Reponible Bauchwandhernie (bis Tischtennisballgröße)</td>
                        <td>≤ 10</td>
                    </tr>
                    <tr>
                        <td>Reponible Bauchwandhernie (bis Faustgröße)</td>
                        <td>≤ 15</td>
                    </tr>
                    <tr>
                        <td>Reponible Bauchwandhernie (über Faustgröße)</td>
                        <td>≤ 20</td>
                    </tr>
                </table>
            </div>
            <div className="text-container szene szene-integument no-border">
                <h2>Verbrennungs-Verbrühungsnarben</h2>
                <p>Zu diesem Komplex können keine allgemein gültigen Eckwerte einer Invaliditätsbemessung angegeben
                    werden, da die Folgen von Verbrennungen und/oder Verbrühungen weit gestreut sind. Es müssen aber
                    Kenntnisse aus den ärztlichen Behandlungsdokumentationen über den Schweregrad und die Ausdehnung
                    der
                    Primärverletzung vorliegen. Erst dann ist es auch möglich, die Haut als Organ des Körpers mit
                    ihrer
                    sowohl äußeren Schutzfunktion als auch ihrer Mitbeteiligung an der Regulation des Temperatur-,
                    Flüssigkeits- und Elektrolythaushalts zu begreifen. Kann sich also der Betroffene noch
                    Temperaturschwankungen aussetzen oder ist ihm das aufgrund der Temperaturregulationsstörung
                    verwehrt? Auch müssen ggf. vorliegende taktile Funktions&shy;beeinträchtigungen oder über die der
                    originären
                    Hautfunktion hinausgehende Folgen z. B. durch Narbenstränge mit Störungen der
                    Gelenkbeweglichkeit
                    Berücksichtigung finden.</p>
                <p>Zur Beschreibung der Narben muss man Aussagen treffen über:</p>
                <ul>
                    <li>– die Pigmentierung des Narbenareals,</li>
                    <li>– die Höhe der Narbe über Hautniveau,</li>
                    <li>– ihre Textur,Stabilität und Dehnbarkeit.</li>
                </ul>
                <p>Weiter sind Informationen notwendig über:</p>
                <ul>
                    <li>– die Durchblutung,</li>
                    <li>– die Plausibilität eines Juckreizes oder der</li>
                    <li>– Störung der Schweißsekretion.</li>
                </ul>
            </div>
        </div>
        <div className="nav-container">
            <div className="sub-nav">
                <div className="szene szene-start">
                    <div>
                        <button type="button" className="return-szene" onClick={resetScene}
                            cs="df867b41-2223-4203-8bb1-e5a44cbf5d01">Szene zurücksetzten
                        </button>
                    </div>
                </div>
                <div className="szene szene-invatar">
                    <div>
                        <button type="button" className="return-overview" onClick={returnUebersicht}
                            cs="01bf34a6-e59c-49ef-a822-257dd7a871f9">Zurück zur Startseite
                        </button>
                        <button className="dummy">dummy</button>
                        <button type="button" className="return-szene" onClick={resetScene}
                            cs="3f23a9c9-b3b1-40cf-8a04-aa0308c3c148">Szene zurücksetzten
                        </button>
                    </div>
                </div>
                <div className="szene szene-arm">
                    <div>
                        <button type="button" className="return-overview" onClick={changeSceneInval}
                            cs="aeb25376-e7d4-48ac-be19-1e2514644583">Zurück zum
                            Invatar
                        </button>
                        <button type="button" className="return-overview" onClick={returnUebersicht}
                            cs="deb4ea88-8e73-4dd2-8882-b27e775e2e90">Zurück zur Startseite
                        </button>
                        <button className="dummy">dummy</button>
                        <button type="button" className="return-szene" onClick={resetScene}
                            cs="cb262a0d-ebe0-41d9-a39e-44e644558d9d">Szene zurücksetzten
                        </button>
                        <button type="button" className="go-ahead" onClick={changeScene}
                            cs="e7e2e1d6-6b70-4710-83d9-9d07494a6e32">Weiter zur Hand
                        </button>
                    </div>
                </div>
                <div className="szene szene-hand">
                    <div>
                        <button type="button" className="return-overview" onClick={changeSceneInval}
                            cs="28010de6-5c34-446b-a6a2-4afec09472f5">Zurück zum
                            Invatar
                        </button>
                        <button type="button" className="return-overview" onClick={returnUebersicht}
                            cs="33d7e16b-6d09-46fa-9bb4-fb9ffdae731b">Zurück zur Startseite
                        </button>
                        <button className="dummy">dummy</button>
                        <button type="button" className="return-szene" onClick={resetScene}
                            cs="7cd7b807-d672-4544-93c4-534a7f4145ce">Szene zurücksetzten
                        </button>
                        <button type="button" className="go-ahead" onClick={changeScene}
                            cs="4c3aaafd-2c11-4dea-91ac-8d5fea750e40">Weiter zum Arm
                        </button>
                    </div>
                </div>
                <div className="szene szene-fuß">
                    <div>
                        <button type="button" className="return-overview" onClick={changeSceneInval}
                            cs="5a4b60ff-d692-44f4-b756-d7107677165f">Zurück zum
                            Invatar
                        </button>
                        <button type="button" className="return-overview" onClick={returnUebersicht}
                            cs="7d2e7b71-dd0a-4941-9add-5e9d0b6bd059">Zurück zur Startseite
                        </button>
                        <button className="dummy">dummy</button>
                        <button type="button" className="return-szene" onClick={resetScene}
                            cs="6d169b10-6177-4441-a009-10eeef4423d7">Szene zurücksetzten
                        </button>
                        <button type="button" className="go-ahead" onClick={changeScene}
                            cs="2207563d-97dd-46cd-8c61-f336e988568e">Weiter zum Bein
                        </button>
                    </div>
                </div>
                <div className="szene szene-bein">
                    <div>
                        <button type="button" className="return-overview" onClick={changeSceneInval}
                            cs="bfddc80d-2168-4940-a09e-3b7d6f7c3b0a">Zurück zum
                            Invatar
                        </button>
                        <button type="button" className="return-overview" onClick={returnUebersicht}
                            cs="85966f2f-282d-47d2-a502-bc8fff558a5d">Zurück zur Startseite
                        </button>
                        <button className="dummy">dummy</button>
                        <button type="button" className="return-szene" onClick={resetScene}
                            cs="3bd21724-a552-4575-8016-d9314c35fde2">Szene zurücksetzten
                        </button>
                        <button type="button" className="go-ahead" onClick={changeScene}
                            cs="9ccbed35-d7dd-4e42-bf7f-f3b7c102c694">Weiter zum Fuß
                        </button>
                    </div>
                </div>
                <div className="szene szene-brustkorb">
                    <div>
                        <button type="button" className="return-overview" onClick={changeSceneInval}
                            cs="dfaed98e-cefd-449f-aab2-6964ec52dc8d">Zurück zum
                            Invatar
                        </button>
                        <button type="button" className="return-overview" onClick={returnUebersicht}
                            cs="a99ebc46-26a5-4ca0-a32b-bc1782a8b3f6">Zurück zur Startseite
                        </button>
                        <button className="dummy">dummy</button>
                        <button type="button" className="return-szene" onClick={resetScene}
                            cs="1968b7e4-0861-45ed-ae12-c63e2a5649c6">Szene zurücksetzten
                        </button>
                        <button type="button" className="go-ahead" onClick={changeScene}
                            cs="d2296eea-bb01-42d0-8c44-365c1b231b01">Weiter zur Wirbelsäule
                        </button>
                    </div>
                </div>
                <div className="szene szene-becken">
                    <div>
                        <button type="button" className="return-overview" onClick={changeSceneInval}
                            cs="7fcd812e-f3f8-406f-899f-59a4a94aeb9e">Zurück zum
                            Invatar
                        </button>
                        <button type="button" className="return-overview" onClick={returnUebersicht}
                            cs="f8fdbff1-a874-47ef-aee9-cea9fcb2af38">Zurück zur Startseite
                        </button>
                        <button className="dummy">dummy</button>
                        <button type="button" className="return-szene" onClick={resetScene}
                            cs="4264c61b-c89a-45e1-ac94-23ace5c6b63f">Szene zurücksetzten
                        </button>
                        <button type="button" className="go-ahead" onClick={changeScene}
                            cs="c9935a20-ba88-45a0-8323-f701b0430879">Weiter zur Bauchdecke
                        </button>
                    </div>
                </div>
                <div className="szene szene-bauchdecke">
                    <div>
                        <button type="button" className="return-overview" onClick={changeSceneInval}
                            cs="110a3581-8bf8-4a20-8b48-a2849b8d7569">Zurück zum
                            Invatar
                        </button>
                        <button type="button" className="return-overview" onClick={returnUebersicht}
                            cs="341089c4-dcc6-4c2a-a8f4-206d452de7e4">Zurück zur Startseite
                        </button>
                        <button className="dummy">dummy</button>
                        <button type="button" className="return-szene" onClick={resetScene}
                            cs="ba0f5b30-00f2-4359-b795-3bf54e07700b">Szene zurücksetzten
                        </button>
                        <button type="button" className="go-ahead" onClick={changeScene}
                            cs="170b1543-ce39-4a47-a7a5-7ab032853b6e">Weiter zum Integument
                        </button>
                    </div>
                </div>
                <div className="szene szene-integument">
                    <div>
                        <button type="button" className="return-overview" onClick={changeSceneInval}
                            cs="2f5399b6-a927-4171-b80f-eb3b12fc6543">Zurück zum
                            Invatar
                        </button>
                        <button type="button" className="return-overview" onClick={returnUebersicht}
                            cs="ba5b4e5b-aa1f-426c-9550-213d83789413">Zurück zur Startseite
                        </button>
                        <button className="dummy">dummy</button>
                        <button type="button" className="return-szene" onClick={resetScene}
                            cs="a6b6ea2b-29de-4b7d-ab8f-49e0bfe30e42">Szene zurücksetzten
                        </button>
                    </div>
                </div>
                <div className="szene szene-laengen-achsen">
                    <div>
                        <button type="button" className="return-overview" onClick={changeSceneInval}
                            cs="85a91acf-9468-4baf-8673-a3a3a73e655f">Zurück zum
                            Invatar
                        </button>
                        <button type="button" className="return-overview" onClick={returnUebersicht}
                            cs="bddc9a69-ffec-45a8-8c7d-25274ba5d32a">Zurück zur Startseite
                        </button>
                        <button className="dummy">dummy</button>
                        <button type="button" className="return-szene" onClick={resetScene}
                            cs="f2e08b85-bcff-4504-b7cf-b21a961dedd5">Szene zurücksetzten
                        </button>
                    </div>
                </div>
                <div className="szene szene-thrombose">
                    <div>
                        <button type="button" className="return-overview" onClick={changeSceneInval}
                            cs="e59c877f-5232-4453-a40c-ce3e7b8e6250">Zurück zum
                            Invatar
                        </button>
                        <button type="button" className="return-overview" onClick={returnUebersicht}
                            cs="bc063765-110e-498e-8543-76090447fe05">Zurück zur Startseite
                        </button>
                        <button className="dummy">dummy</button>
                        <button type="button" className="return-szene" onClick={resetScene}
                            cs="91d1a6a7-d614-4b9c-890d-cd9e03a44703">Szene zurücksetzten
                        </button>
                    </div>
                </div>
                <div className="szene szene-wirbelsaeule">
                    <div>
                        <button type="button" className="return-overview" onClick={changeSceneInval}
                            cs="f3b95c9e-6146-42a6-9405-bef23d429f4b">Zurück zum
                            Invatar
                        </button>
                        <button type="button" className="return-overview" onClick={returnUebersicht}
                            cs="80a5285c-98a5-44e0-874d-0a3f0578bfdc">Zurück zur Startseite
                        </button>
                        <button className="dummy">dummy</button>
                        <button type="button" className="return-szene" onClick={resetScene}
                            cs="1f3a50e1-0df1-4a9a-9b4c-7d558b67c00a">Szene zurücksetzten
                        </button>
                        <button type="button" className="go-ahead" onClick={changeScene}
                            cs="1316074b-b3cf-4f7a-9c96-875f6e3ee412">Weiter zum Becken
                        </button>
                    </div>
                </div>
                <div className="szene szene-versteifung">
                    <div>
                        <button type="button" className="return-overview" onClick={changeSceneInval}
                            cs="32bb13b0-e8d9-4dca-b14f-2de30da912ae">Zurück zum
                            Invatar
                        </button>
                        <button type="button" className="return-overview" onClick={returnUebersicht}
                            cs="174c3d41-8638-46ac-8fe3-d62a76dfe83d">Zurück zur Startseite
                        </button>
                        <button className="dummy">dummy</button>
                        <button type="button" className="return-szene" onClick={resetScene}
                            cs="7a7b63ea-41f9-49b3-ae92-ec0e64708d8f">Szene zurücksetzten
                        </button>
                    </div>
                </div>
                <div className="szene szene-versteifung-bein">
                    <div>
                        <button type="button" className="return-overview" onClick={changeSceneInval}
                            cs="a659aeb7-21fd-418b-9e76-ab33e15d1248">Zurück zum
                            Invatar
                        </button>
                        <button type="button" className="return-overview" onClick={returnUebersicht}
                            cs="995ad621-bb59-4354-bb47-3cc85f521aac">Zurück zur Startseite
                        </button>
                        <button className="dummy">dummy</button>
                        <button type="button" className="return-szene" onClick={resetScene}
                            cs="99179bb6-4e18-42d9-b29e-e0765aef8902">Szene zurücksetzten
                        </button>
                    </div>
                </div>
                <div className="szene szene-funktionsstoerung">
                    <div>
                        <button type="button" className="return-overview" onClick={changeSceneInval}
                            cs="b32e1299-2f4a-4cd1-a96c-1d3cfb96b893">Zurück zum
                            Invatar
                        </button>
                        <button type="button" className="return-overview" onClick={returnUebersicht}
                            cs="4c4cf6be-02bd-4973-b56e-b497bb21ee39">Zurück zur Startseite
                        </button>
                        <button className="dummy">dummy</button>
                        <button type="button" className="return-szene" onClick={resetScene}
                            cs="e3f9e8fb-1ac7-4ff9-b530-b4594ce9dd06">Szene zurücksetzten
                        </button>
                    </div>
                </div>
                <div className="szene szene-funktionsstoerung-bein">
                    <div>
                        <button type="button" className="return-overview" onClick={changeSceneInval}
                            cs="aa6b609e-aa3b-42d6-9782-86648c60a0f4">Zurück zum
                            Invatar
                        </button>
                        <button type="button" className="return-overview" onClick={returnUebersicht}
                            cs="e067628d-e2d4-4b92-91f3-02d6b281329d">Zurück zur Startseite
                        </button>
                        <button className="dummy">dummy</button>
                        <button type="button" className="return-szene" onClick={resetScene}
                            cs="6deecca6-1d01-4add-be59-5de43dae5326">Szene zurücksetzten
                        </button>
                    </div>
                </div>
                <div className="szene szene-athrose">
                    <div>
                        <button type="button" className="return-overview" onClick={changeSceneInval}
                            cs="f089b6eb-1e3d-48cf-b22c-fc1d44245df0">zum
                            Invatar
                        </button>
                        <button type="button" className="return-overview" onClick={returnUebersicht}
                            cs="500b489d-332b-453c-a0bb-8a11b69d78f6">Zurück zur Startseite
                        </button>
                        <button className="dummy">dummy</button>
                        <button type="button" className="return-overview" onClick={changeSceneInval}
                            cs="21004c51-067e-48a7-b1d2-b08faa10fa7d">zur Endoprothesen Ansicht
                        </button>
                        <button type="button" className="return-szene" onClick={resetScene}
                            cs="45456261-ea21-48d2-a3d5-de616a4efb86">Szene zurücksetzten
                        </button>
                    </div>
                </div>
                <div className="szene szene-prothese">
                    <div>
                        <button type="button" className="return-overview" onClick={changeSceneInval}
                            cs="cf89ce55-0de2-4b30-ba05-e198e812d1dc">zum
                            Invatar
                        </button>
                        <button type="button" className="return-overview" onClick={returnUebersicht}
                            cs="7f396309-f8d2-4224-ae52-4d5a7330b947">Zurück zur Startseite
                        </button>
                        <button className="dummy">dummy</button>
                        <button type="button" className="return-overview" onClick={changeSceneInval}
                            cs="bee5b19b-c469-44a5-a72d-63eae5a0913c">zur Arthrosenansicht
                        </button>
                        <button type="button" className="return-szene" onClick={resetScene}
                            cs="82e1b117-5250-4702-9a1e-b878102d7ef2">Szene zurücksetzten
                        </button>
                    </div>
                </div>
            </div>
            <div className="szene szene-start">
                <ul className="nav ">
                    <li className="button">
                        <button type="button" style={{ color: '#BBFD6B' }} onClick={changeSceneInval}
                            ct="a64124b7-6bd6-44d3-8cd4-e422bd7c97ae"
                            cs="2bab3741-4676-4f96-a370-bbc5ce660734">Arthrose
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" style={{ color: '#6BADFD' }}
                            onClick={changeSceneInval}
                            ct="d16dd982-afc1-4962-8a11-c8655feec428"
                            cs="5b360e47-d4a4-4b2f-b5c5-af93d1d51555">Invaliditätswerte
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" style={{ color: '#FF85A2' }}
                            onClick={changeSceneInval}
                            ct="e14e183c-67cd-42c2-bfdc-297e9ac0b3f1"
                            cs="b34a3cc5-0425-4e06-84d8-a9c1d1873c2f">Endoprothesen
                        </button>
                    </li>
                </ul>
                <div className="fussnoten-container">
                    <p className="fussnote hidden">fußnote</p>
                </div>
            </div>
            <div className="szene szene-invatar">
                <ul className="nav ">
                    <li className="button">
                        <button type="button" onClick={changeScene}
                            cs="74de5b1f-4383-473e-8297-6ac012d225f7">Arm
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" onClick={changeScene}
                            cs="464db81c-fbee-4393-85d3-836c6a4f944a">Hand
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" onClick={changeScene}
                            cs="a046332d-9b5f-476b-b9a8-c51abc98042f">Bein
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" onClick={changeScene}
                            cs="b4b8f359-edb2-47d9-abd5-07159f11f303">Fuß
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" onClick={changeScene}
                            cs="74a10933-ee30-4b02-829b-38f0a8d1aa26">Brustkorb
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" onClick={changeScene}
                            cs="44267008-fe96-46d7-bbc7-7c029d6f3f56">Wirbelsäule
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" onClick={changeScene}
                            cs="b46355d1-dc1c-4c29-8008-f498dd00e4de">Becken
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" onClick={changeScene}
                            cs="e65d920e-fea4-4261-8da0-0b09de944907">Bauchdecke
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" onClick={changeScene}
                            cs="dab8095f-d4ac-468b-96c4-88df6ab01561">Integument
                        </button>
                    </li>
                </ul>
                <div className="fussnoten-container">
                    <p className="fussnote hidden">fußnote</p>
                </div>
            </div>
            <div className="szene szene-arm">
                <ul className="nav ">
                    <li className="button">
                        <button type="button">Verlust
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white"
                            onClick={changeSceneVersteifung}
                            cs="bc59bfbb-1cd4-4dce-b392-3b0c1d5da34c">Versteifung
                        </button>
                    </li>
                    <li className="button szene szene-arm">
                        <button type="button" className="color-white"
                            onClick={changeSceneFuntionsstoerung}
                            cs="131a0085-f4d6-48a5-98ca-9a6c36c8e1d2">Funktions&shy;beeinträchtigung
                        </button>
                    </li>
                </ul>
                <div className="fussnoten-container">
                    <p className="fussnote hidden">fußnote</p>
                </div>
            </div>
            <div className="szene szene-hand">
                <ul className="nav ">
                    <li className="button">
                        <button type="button">Verlust
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white"
                            onClick={changeSceneVersteifung}
                            cs="5b6fc9ed-a859-4687-831b-cdcb76fdd7f7">Versteifung
                        </button>
                    </li>
                    <li className="button szene szene-hand">
                        <button type="button" className="color-white"
                            onClick={changeSceneFuntionsstoerung}
                            cs="a7704ea1-721f-4a7b-a6a0-2f89c3bf3c13">Funktions&shy;beeinträchtigung
                        </button>
                    </li>
                </ul>
                <div className="fussnoten-container">
                    <p className="fussnote hidden">fußnote</p>
                </div>
            </div>
            <div className="szene szene-fuß">
                <ul className="nav ">
                    <li className="button">
                        <button type="button">Verlust
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white"
                            onClick={changeSceneVersteifung}
                            cs="79b19441-190c-461b-8f4f-b5b2017b2829">Versteifung
                        </button>
                    </li>
                    <li className="button szene szene-fuß">
                        <button type="button" className="color-white"
                            onClick={changeSceneFuntionsstoerung}
                            cs="1feab3d4-a57d-445e-a7a5-3b7947ccc904">Funktions&shy;beeinträchtigung
                        </button>
                    </li>
                    <li className="button szene szene-fuß">
                        <button type="button" className="color-white"
                            onClick={changeScene}
                            cs="1654543e-e546-4ec2-be7d-82031da1c24f">Längen- und Achsenabweichung
                        </button>
                    </li>
                    <li className="button szene szene-fuß">
                        <button type="button" className="color-white"
                            onClick={changeScene}
                            cs="9963da67-4a32-4829-8364-ee0f9e5e1669">Thrombosefolgen / Lymphödeme
                        </button>
                    </li>
                </ul>
                <div className="fussnoten-container">
                    <p className="fussnote hidden">fußnote</p>
                </div>
            </div>
            <div className="szene szene-bein">
                <ul className="nav ">
                    <li className="button">
                        <button type="button">Verlust
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white"
                            onClick={changeSceneVersteifung}
                            cs="bc9bbdfd-fb8c-4414-9ad5-1404f200e769">Versteifung
                        </button>
                    </li>
                    <li className="button szene szene-bein">
                        <button type="button" className="color-white"
                            onClick={changeSceneFuntionsstoerung}
                            cs="e7ccc904-d473-40e4-b69b-173bba8db102">Funktions&shy;beeinträchtigung
                        </button>
                    </li>
                    <li className="button szene szene-bein">
                        <button type="button" className="color-white"
                            onClick={changeScene}
                            cs="4e8d3aff-39bc-40b8-be83-c8f805222742">Längen- und Achsenabweichung
                        </button>
                    </li>
                    <li className="button szene szene-bein">
                        <button type="button" className="color-white"
                            onClick={changeScene}
                            cs="adf3296c-6527-4e9c-aa63-b5651bc23ef7">Thrombosefolgen / Lymphödeme
                        </button>
                    </li>
                </ul>
                <div className="fussnoten-container">
                    <p className="fussnote hidden">fußnote</p>
                </div>
            </div>
            <div className="szene szene-brustkorb">
                <ul className="nav ">
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="e62c4f2e-3fef-4f8f-8d3b-2226c922a3cd">Arm
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="04b2584d-8466-4db3-89cd-933a4b885eac">Hand
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="e87d5f62-59c7-4962-971a-a32cc6a5e1ad">Bein
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="5f8f3644-b62a-41d2-8ad4-96f33921a4bc">Fuß
                        </button>
                    </li>
                    <li className="button active">
                        <button type="button">Brustkorb
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="3fd28953-f323-41d0-8829-1167846b6b75">Wirbelsäule
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="d03c0cbc-0fef-4513-ad88-b099c1e7306a">Becken
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="dc1e329d-8ce2-41b1-9633-71e763b791e4">Bauchdecke
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="98734752-8721-4e4f-91b9-79f46b60538d">Integument
                        </button>
                    </li>
                </ul>
                <div className="fussnoten-container">
                    <p className="fussnote hidden">fußnote</p>
                </div>
            </div>
            <div className="szene szene-wirbelsaeule">
                <ul className="nav ">
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="22bdcd43-4f63-46c5-891b-004285c7cc35">Arm
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="18757df4-2b56-44d3-9423-7bcd55de7634">Hand
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="faef8d04-93aa-427a-acd7-a2e88ceccfd4">Bein
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="57a691ba-edfa-48f8-9664-2b490135bd31">Fuß
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="1c267f84-06d9-4719-bf35-2e02b8f54ec7">Brustkorb
                        </button>
                    </li>
                    <li className="button active">
                        <button type="button">Wirbelsäule</button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="c36dfe24-6d95-4055-b470-c80b703708e6">Becken
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="91ad2125-f0b0-4bbb-903b-e156f186c395">Bauchdecke
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="597411db-0770-421a-aa18-d2942151da1e">Integument
                        </button>
                    </li>
                </ul>
                <div className="fussnoten-container">
                    <p className="fussnote hidden">fußnote</p>
                </div>
            </div>
            <div className="szene szene-becken">
                <ul className="nav ">
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="555eeba7-062a-4567-8e4b-c36798714ccc">Arm
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="2b1992d6-2eee-48ab-b390-3c6468f6c2fb">Hand
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="a1a7554f-927a-4afd-b776-e7c90539bbb7">Bein
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="3226e8b2-fc40-4a00-b7a1-c12986a6a36a">Fuß
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="dd06d456-0d1f-43b3-95c6-d3ce7d2e0443">Brustkorb
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="f1207c00-87e1-41ba-a729-fc4e6eeec9c3">Wirbelsäule
                        </button>
                    </li>
                    <li className="button active">
                        <button type="button">Becken
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="c9935a20-ba88-45a0-8323-f701b0430879">Bauchdecke
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="a8d0e188-9d27-4298-9594-295b41f9f762">Integument
                        </button>
                    </li>
                </ul>
                <div className="fussnoten-container">
                    <p className="fussnote hidden">fußnote</p>
                </div>
            </div>
            <div className="szene szene-bauchdecke">
                <ul className="nav ">
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="2a1ddd21-ac4c-415c-8ee5-949a725c80ff">Arm
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="980e2e8e-46a4-4d95-ac95-05cd39979cc5">Hand
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="8c560a4e-8d4f-444d-b02b-b1fbea605cc3">Bein
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="0cde4d63-91b4-49d5-9792-90fa2e9c0038">Fuß
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="f350ca77-5615-46ff-b433-9632a50ce39e">Brustkorb
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="7d27431e-c4b7-4213-a4b5-c29f4fcd602b">Wirbelsäule
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="44055c0e-7bce-45ae-a85a-36b4aad0e84f">Becken
                        </button>
                    </li>
                    <li className="button active">
                        <button type="button">Bauchdecke
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="af2d1fd1-78a0-4c46-b371-542d90684c23">Integument
                        </button>
                    </li>
                </ul>
                <div className="fussnoten-container">
                    <p className="fussnote hidden">fußnote</p>
                </div>
            </div>
            <div className="szene szene-integument">
                <ul className="nav ">
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="2405c351-e63b-44e6-9e08-3df88564ec5c">Arm
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="4f57dc90-71f9-4190-a0c5-6bf05aa26b4d">Hand
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="20c27cad-5d8b-474e-8881-cb67f8ba5955">Bein
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="d11e8acd-bbfc-4bcf-87f9-2d81ee27d3b9">Fuß
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="62f1bae1-222e-447f-8b95-3f5b25878db6">Brustkorb
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="a145901c-da9e-4fa0-b6fc-8387c6367044">Wirbelsäule
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="0ba9956b-b6b9-4206-809d-2fed6aa2b0de">Becken
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="421eca12-6996-45f9-ad05-7c1d69b85cd1">Bauchdecke
                        </button>
                    </li>
                    <li className="button active">
                        <button type="button">Integument
                        </button>
                    </li>
                </ul>
                <div className="fussnoten-container">
                    <p className="fussnote hidden">fußnote</p>
                </div>
            </div>
            <div className="szene szene-laengen-achsen">
                <ul className="nav ">
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="b3bf9d3e-464d-42bc-a588-8191dbf48343">Verlust
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="35da6516-55a8-4f4d-8d6d-da1a3f147048">Versteifung
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white"
                            onClick={changeSceneFuntionsstoerung}
                            cs="02a306d3-c50b-4678-94e0-f8e131fa9480">Funktions&shy;beeinträchtigung
                        </button>
                    </li>
                    <li className="button">
                        <button type="button">
                            Längen- und Achsenabweichung
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white"
                            onClick={changeScene}
                            cs="3939a759-7f73-4341-b3e9-e9c199a872cd">Thrombosefolgen / Lymphödeme
                        </button>
                    </li>
                </ul>
                <div className="fussnoten-container">
                    <p className="fussnote hidden">fußnote</p>
                </div>
            </div>
            <div className="szene szene-thrombose">
                <ul className="nav ">
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="0f59f87f-07e7-47e8-9818-fedb6e1749aa">Verlust
                        </button>
                    </li>
                    <li className="button sub-menu">
                        <button type="button" className="color-white"
                            onClick={changeScene}
                            cs="8c11fdc0-160d-4793-b634-6d68d5313f11">Versteifung
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white"
                            onClick={changeSceneFuntionsstoerung}
                            cs="aea8252e-70e8-4407-84eb-a4eb50d07bc4">Funktions&shy;beeinträchtigung
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white"
                            onClick={changeScene}
                            cs="708ef84f-d193-4dd4-b703-38605b3b8419">Längen- und Achsenabweichung
                        </button>
                    </li>
                    <li className="button">
                        <button type="button">
                            Thrombosefolgen / Lymphödeme
                        </button>
                    </li>
                </ul>
                <div className="fussnoten-container">
                    <p className="fussnote hidden">fußnote</p>
                </div>
            </div>
            <div className="szene szene-versteifung">
                <ul className="nav ">
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="3c6b6d39-b605-49ab-a900-3da705738b8e">Verlust
                        </button>
                    </li>
                    <li className="button sub-menu">
                        <button type="button">Versteifung
                        </button>
                        <ul>
                            <li onClick={show_buttons} data-arg1='1' id="verst-1">Fingergelenke</li>
                            <li onClick={show_buttons} data-arg1='2' id="verst-2">Handgelenk</li>
                            <li onClick={show_buttons} data-arg1='5' id="verst-5">Unterarmdrehung</li>
                            <li onClick={show_buttons} data-arg1='3' id="verst-3">Ellenbogengelenk</li>
                            <li onClick={show_buttons} data-arg1='4' id="verst-4">Schultergelenk</li>
                        </ul>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white"
                            onClick={changeSceneFuntionsstoerung}
                            cs="4ed0b330-9975-4cc0-beee-6baf2ef9c3f8">Funktions&shy;beeinträchtigung
                        </button>
                    </li>
                </ul>
                <div className="fussnoten-container">
                    <p className="fussnote hidden">fußnote</p>
                </div>
                <div className="fussnoten-container">
                    <p className="fussnote" id={'verlustwertChange'}></p>
                </div>
            </div>
            <div className="szene szene-versteifung-bein">
                <ul className="nav ">
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="2159a54b-6fb4-49fa-9b69-e4836b72cdf1">Verlust
                        </button>
                    </li>
                    <li className="button sub-menu">
                        <button type="button">Versteifung
                        </button>
                        <ul>
                            <li onClick={show_buttons} data-arg1='4b' id="verst-4b">Hüftgelenk</li>
                            <li onClick={show_buttons} data-arg1='3b' id="verst-3b">Kniegelenk</li>
                            <li onClick={show_buttons} data-arg1='2b' id="verst-2b">Fußgelenk</li>
                            <li onClick={show_buttons} data-arg1='1b' id="verst-1b">Zehengelenke</li>
                        </ul>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white"
                            onClick={changeSceneFuntionsstoerung}
                            cs="339b2213-b5a0-4354-834b-0cc5710a07a8">Funktions&shy;beeinträchtigung
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white"
                            onClick={changeScene}
                            cs="925db792-bb44-4ac0-a684-fa21e523f012">Längen- und Achsenabweichung
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white"
                            onClick={changeScene}
                            cs="4c7dba95-c188-44e6-969b-77f87f934dde">Thrombosefolgen / Lymphödeme
                        </button>
                    </li>
                </ul>
                <div className="fussnoten-container">
                    <p className="fussnote hidden">fußnote</p>
                </div>
            </div>
            <div className="szene szene-funktionsstoerung">
                <ul className="nav ">
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="b932d0e8-7589-4936-822d-411f0f3a312e">Verlust
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white"
                            onClick={changeSceneVersteifung}
                            cs="2b952eb0-dc21-47d3-9852-760f50c2d6f4">Versteifung
                        </button>
                    </li>
                    <li className="button sub-menu">
                        <button type="button">Funktions&shy;beeinträchtigung
                        </button>
                        <ul>
                            <li onClick={show_buttons_funk} data-arg4='3' id="funk-3">Schultergelenk</li>
                            <li onClick={show_buttons_funk} data-arg4='2' id="funk-2">Ellenbogengelenk</li>
                            <li onClick={show_buttons_funk} data-arg4='5' id="funk-5">Unterarmdrehung</li>
                            <li onClick={show_buttons_funk} data-arg4='1' id="funk-1">Handgelenk</li>
                            <li onClick={show_buttons_funk} data-arg4='4' id="funk-4">Weitere Beeinträchtigungen</li>
                        </ul>
                    </li>
                </ul>
                <div className="fussnoten-container">
                    <p className="fussnote hidden">fußnote</p>
                </div>
            </div>
            <div className="szene szene-funktionsstoerung-bein">
                <ul className="nav ">
                    <li className="button">
                        <button type="button" className="color-white" onClick={changeScene}
                            cs="0461c35b-9af7-4cb9-8e19-9f25281c3988">Verlust
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white"
                            onClick={changeSceneVersteifung}
                            cs="c302cf84-25ff-4f8b-bac7-31d2375dbbc5">Versteifung
                        </button>
                    </li>
                    <li className="button sub-menu">
                        <button type="button">Funktions&shy;beeinträchtigung
                        </button>
                        <ul>
                            <li onClick={show_buttons_funk} data-arg4='3fb' id="funk-3b">Hüftgelenk</li>
                            <li onClick={show_buttons_funk} data-arg4='2fb' id="funk-2b">Kniegelenk</li>
                            <li onClick={show_buttons_funk} data-arg4='1fb' id="funk-1b">Knieinstabilität</li>
                            <li onClick={show_buttons_funk} data-arg4='4fb' id="funk-4b">Sprungelenke</li>
                        </ul>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white"
                            onClick={changeScene}
                            cs="0a1d9440-1d2d-4e45-9fc7-b4f54d2b2d16">Längen- und Achsenabweichung
                        </button>
                    </li>
                    <li className="button">
                        <button type="button" className="color-white"
                            onClick={changeScene}
                            cs="fa8db11e-afa5-44df-8529-4623888d6438">Thrombosefolgen / Lymphödeme
                        </button>
                    </li>
                </ul>
                <div className="fussnoten-container">
                    <p className="fussnote hidden">fußnote</p>
                </div>
            </div>
            <p className="fussnote" id="copyright">v1.0 © 2024 Dr. Torsten Klemm</p>
        </div>
    </div>)
}


export default App;
