import React, {useState} from 'react'
//import { useNavigate } from 'react-router-dom'

const Login = (props) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')

    //const navigate = useNavigate()

    const onButtonClick = () => {
        // Set initial error values to empty
        setEmailError('')
        setPasswordError('')

        // Check if the user has entered both fields correctly
        if ('' === email) {
            setEmailError('Please enter your email')
            return
        }

        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            setEmailError('Please enter a valid email')
            return
        }

        if ('' === password) {
            setPasswordError('Please enter a password')
            return
        }

        if (password.length < 7) {
            setPasswordError('The password must be 8 characters or longer')
            return
        }

        // Authentication calls will be made here...

        // Check if email has an account associated with it
        checkAccountExists((accountExists) => {
            // If yes, log in
            if (accountExists) logIn()
            // Else, ask user if they want to create a new account and if yes, then log in
            else {
                setEmailError('Email ist falsch')
            }
        })
    }

    // Call the server API to check if the given email ID already exists
    const checkAccountExists = (callback) => {
        fetch('https://login.invaliditaet-online.de/check-account', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({email}),
        })
            .then((r) => r.json())
            .then((r) => {
                callback(r?.userExists)
            })
    }

// Log in a user using email and password
    const logIn = () => {
        fetch('https://login.invaliditaet-online.de/auth', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({email, password}),
        })
            .then((r) => r.json())
            .then((r) => {
                if ('success' === r.message) {
                    localStorage.setItem('user', JSON.stringify({email, token: r.token}))
                    props.setLoggedIn(true)
                    props.setEmail(email)
                } else {
                    setPasswordError('Passwort ist falsch')
                }
            })
    }

    function showPwFogotten() {
        console.log('test forgotten pw');
        document.querySelector('.forgotpw-hint').style.display = 'block';
        document.querySelector('.mainContainer').classList.add('more-margin');
    }

    return (
        <div className={'mainContainer'}>
            <div className={'titleContainer'}>
                <div>Anmeldung</div>
            </div>
            <br/>
            <div className={'inputContainer'}>
                <input
                    value={email}
                    placeholder="Email"
                    onChange={(ev) => setEmail(ev.target.value)}
                    className={'inputBox'}
                />
                <label className="errorLabel">{emailError}</label>
            </div>
            <div className={'inputContainer'}>
                <input
                    value={password}
                    placeholder="Passwort"
                    onChange={(ev) => setPassword(ev.target.value)}
                    className={'inputBox'}
                />
                <label className="errorLabel">{passwordError}</label>
            </div>
            <div className={'forgotpw'}>
                <p onClick={showPwFogotten}>Registrieren oder Passwort vergessen</p>
            </div>
            <div className={'inputContainer'}>
                <input className={'inputButton'} type="button" onClick={onButtonClick} value={'Anmelden'}/>
            </div>
            <div className={'forgotpw-hint'}>
                <p>Der Registrierungsprozess und das Zurücksetzen des Passwortes sind momentan noch nicht automatisiert.
                    Für beiderlei Anliegen wenden Sie sich bitte an: </p>
                <a href="mailto:mail@fgimb.de">mail@fgimb.de</a>
            </div>
        </div>
    )
}

export default Login